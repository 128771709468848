import React, { ReactNode } from "react";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { InfoIcon } from "@components/common";
import { styled } from "@mui/system";

export const HintTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ),
  {
    shouldForwardProp: (prop) => prop !== "fullWidth",
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    top: "-10px",
    backgroundColor: "#000",
    fontSize: "11.7px",
    padding: "1.54em 1em",

    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
    },
  },
}));

export interface HintProps {
  title: string | ReactNode;
}

const Hint = ({ title }: HintProps): JSX.Element => {
  return (
    <HintTooltip title={title}>
      <IconButton
        edge="start"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          ":hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <InfoIcon
          sx={{
            fontSize: {
              xs: "0.8125rem",
              xl: "1rem",
            },
          }}
        />
      </IconButton>
    </HintTooltip>
  );
};

export default Hint;
