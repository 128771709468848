import React, { Fragment } from "react";
import { usePoolsStore } from "@lib/providers";
import Callbacks from "./callbacks";

const PoolsFetcher = (): JSX.Element => {
  const pools = usePoolsStore((state) => state.pools);

  return (
    <Fragment>
      {Object.values(pools).map((pool) => {
        return <Callbacks key={pool.id} pool={pool} />;
      })}
    </Fragment>
  );
};

export default PoolsFetcher;
