import { useEffect } from "react";
import { useMultiCall } from "@lib/hooks";

import { useBlockNumber, usePoolsStore } from "@lib/providers";
import { BigNumber } from "ethers";
import { PoolI } from "@lib/types";

import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import IPriceFeedAbi from "@coraprotocol/core/abis/IPriceFeed.json";

const getConfig = ({
  poolId,
  priceFeedAddress,
  debtTokenAddress,
  collateralTokenAddress,
}: {
  poolId: string;
  priceFeedAddress: string;
  debtTokenAddress: string;
  collateralTokenAddress: string;
}) => [
  {
    target: poolId,
    reference: "getAvailableLiquidity",
    method: "getAvailableLiquidity",
    abi: LendingPoolAbi,
  },
  {
    target: poolId,
    reference: "poolParams",
    method: "poolParams",
    abi: LendingPoolAbi,
  },
  {
    target: poolId,
    reference: "getCurrentRoundIndex",
    method: "getCurrentRoundIndex",
    abi: LendingPoolAbi,
  },
  {
    target: priceFeedAddress,
    reference: "getAssetPriceDebtToken",
    method: "getAssetPrice",
    abi: IPriceFeedAbi,
    args: [debtTokenAddress],
  },
  {
    target: priceFeedAddress,
    reference: "getAssetPriceCollateralToken",
    method: "getAssetPrice",
    abi: IPriceFeedAbi,
    args: [collateralTokenAddress],
  },
];

const currentRoundIndexConfig = ({
  poolId,
  currentRoundIndex,
}: {
  poolId: string;
  currentRoundIndex: BigNumber;
}) => [
  {
    target: poolId,
    reference: "rounds",
    method: "rounds",
    abi: LendingPoolAbi,
    args: [currentRoundIndex],
  },
];

interface Return {
  getAvailableLiquidity: BigNumber;
  poolParams: {
    minAmountToBorrow: BigNumber;
    minAmountToDeposit: BigNumber;
    maxCap: BigNumber;
    loanToValue: BigNumber;
  };
  rounds: { totalBorrowed: BigNumber; totalStablecoin: BigNumber };
  getCurrentRoundIndex: BigNumber;
  getAssetPriceDebtToken: BigNumber;
  getAssetPriceCollateralToken: BigNumber;
}

const PoolFetcherCallbacks = ({
  pool,
}: {
  pool: Pick<PoolI, "id" | "debtToken" | "collateralToken" | "priceFeed">;
}): null => {
  const { data, call } = useMultiCall<Return>();

  const blockNumber = useBlockNumber();
  const updatePool = usePoolsStore((state) => state.updatePool);

  useEffect(() => {
    if (!blockNumber) return;
    if (!pool) return;

    data.getCurrentRoundIndex
      ? call([
          ...getConfig({
            poolId: pool.id,
            priceFeedAddress: pool.priceFeed,
            debtTokenAddress: pool.debtToken.id,
            collateralTokenAddress: pool.collateralToken.id,
          }),
          ...currentRoundIndexConfig({
            poolId: pool.id,
            currentRoundIndex: data.getCurrentRoundIndex,
          }),
        ])
      : call(
          getConfig({
            poolId: pool.id,
            priceFeedAddress: pool.priceFeed,
            debtTokenAddress: pool.debtToken.id,
            collateralTokenAddress: pool.collateralToken.id,
          })
        );
  }, [blockNumber, JSON.stringify(data?.getCurrentRoundIndex)]);

  useEffect(() => {
    if (!data) return;
    if (!data.poolParams) return;

    updatePool({
      id: pool.id,
      minAmountToBorrow: data.poolParams?.minAmountToBorrow,
      minAmountToDeposit: data.poolParams?.minAmountToDeposit,
      availableLiquidity: data.getAvailableLiquidity,
      ltv: data.poolParams?.loanToValue,
      maxCap: data.poolParams?.maxCap,
      currentRoundIndex: parseInt(data.getCurrentRoundIndex?.toString()),
      totalBorrowed: data.rounds?.totalBorrowed,
      totalStableCoin: data.rounds?.totalStablecoin,
      debtTokenPrice: data.getAssetPriceDebtToken,
      collateralTokenPrice: data.getAssetPriceCollateralToken,
    });
  }, [JSON.stringify(data)]);

  return null;
};

export default PoolFetcherCallbacks;
