import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import { BaseFlex, NFTCard } from "@components/common";
import { NFTCoin } from "@components/common/Icons";
import {
  WithdrawCollateralModal,
  CancelPendingWithdrawalModal,
  CancelPendingDepositModal,
  SignalWithdrawModal,
  SupplyBasicModal,
  WithdrawAvailableModal,
} from "@components/ui";
import { ManageLiquidityModalType, useModalManager } from "./modalManager/ModalManager";
import SubDetailsUI from "./SubDetails";
import MainDetailsUI from "./MainDetails";
import { useLPPositionsStore } from "@lib/providers/";

const ManageLiquidityUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const { open, setOpen } = useModalManager();
  const position = useLPPositionsStore((state) => state.positions[poolId]);
  const { loading } = useLPPositionsStore((state) => state.status);

  // TODO: add loading state
  if (loading) return <div>Loading...</div>;

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: "1.5em",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "minmax(33%, 23.75rem)" }}>
          <NFTCard
            name={position.poolDetails.name}
            link="#"
            loading={false}
            nftIcon={<NFTCoin />}
          />
        </Box>
        <BaseFlex sx={{ flexDirection: "column", gap: "1.5em", width: "100%" }}>
          <MainDetailsUI poolId={poolId} />
          <SubDetailsUI poolId={poolId} />
        </BaseFlex>
      </Box>

      <SupplyBasicModal
        open={open === ManageLiquidityModalType.SUPPLY}
        onClose={() => setOpen(null)}
        poolAddress={poolId}
      />
      <SignalWithdrawModal
        open={open === ManageLiquidityModalType.SIGNAL_WITHDRAW}
        onClose={() => setOpen(null)}
        poolAddress={poolId}
      />
      <WithdrawCollateralModal
        poolAddress={poolId}
        open={open === ManageLiquidityModalType.WITHDRAW_COLLATERAL}
        onClose={() => setOpen(null)}
      />
      <CancelPendingWithdrawalModal
        open={open === ManageLiquidityModalType.CANCEL_PENDING_WITHDRAWAL}
        onClose={() => setOpen(null)}
        poolAddress={poolId}
      />
      <CancelPendingDepositModal
        poolAddress={poolId}
        open={open === ManageLiquidityModalType.CANCEL_PENDING_DEPOSIT}
        onClose={() => setOpen(null)}
      />
      <WithdrawAvailableModal
        poolAddress={poolId}
        open={open === ManageLiquidityModalType.WITHDRAW_AVAILABLE}
        onClose={() => setOpen(null)}
      />
    </Fragment>
  );
};

export default ManageLiquidityUI;
