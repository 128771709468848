import React, { Fragment } from "react";
import {
  ConfirmMsg,
  ErrorMsg,
  SuccessMsg,
  SubmittedMsg,
} from "@components/common/TransactionStatusMessage";
import { TransactionStatus, TransactionType } from "@lib/types";
import { TransactionStatusMessageBaseProps } from "@components/common/TransactionStatusMessage";
import { getExplorerLink, HashType } from "@lib/utils";
import { ChainId } from "@lib/constants";
import { useTransactionStore } from "@lib/providers";

interface TransactionStatusMessageProps extends Omit<TransactionStatusMessageBaseProps, "message"> {
  status: TransactionStatus;
  transactionHash: string;
  errorMessage?: string;
  confirmMessage: string;
  chainId: ChainId;
  transactionType: TransactionType;
  onBackToForm: () => void;
}

const TransactionStatusMessage = ({
  status,
  transactionHash,
  confirmMessage,
  onClose,
  errorMessage,
  chainId,
  onBackToForm,
}: TransactionStatusMessageProps): JSX.Element => {
  const transaction = useTransactionStore((state) => state.transactions[chainId][transactionHash]);

  return (
    <Fragment>
      <ConfirmMsg message={confirmMessage} show={status === "confirm"} />
      <SubmittedMsg
        message={confirmMessage}
        transactionURL={getExplorerLink(chainId, transactionHash, HashType.TRANSACTION)}
        onClose={onClose}
        show={(status === "submitted" || status === "success") && !transaction?.isMined}
      />
      <SuccessMsg
        transactionURL={getExplorerLink(chainId, transactionHash, HashType.TRANSACTION)}
        message={transaction?.description}
        onClose={onClose}
        show={transaction?.isMined}
      />
      <ErrorMsg message={errorMessage} onClose={onBackToForm} show={status === "error"} />
    </Fragment>
  );
};

export default TransactionStatusMessage;
