import { DepositTransactionInfo, TransactionDetailBase } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingDepositTransactions = (): Array<
  TransactionDetailBase<DepositTransactionInfo>
> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.Deposit)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (deposit): deposit is TransactionDetailBase<DepositTransactionInfo> => !deposit.isMined
    );
};

export const usePendingDepositTransactionsByPool = (
  poolId: string
): TransactionDetailBase<DepositTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.Deposit)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter((deposit): deposit is TransactionDetailBase<DepositTransactionInfo> => !deposit.isMined)
    .filter((deposit) => deposit.transactionInfo.lendingPoolId === poolId)[0];
};
