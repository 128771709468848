import React, { Fragment } from "react";
import { styled } from "@mui/system";
import MuiLink from "@mui/material/Link";
import Image from "next/image";
import Box from "@mui/material/Box";
import { CellData, CellLoader, LogoWrapper, RowActionButton } from "../TableComponents";
import { BaseFlex, ExternalLinkIcon, HintTooltip, Spinner } from "@components/common";
import { RepayModal } from "@components/ui";
import { useToggle } from "@lib/providers";
import {
  commaSeparateNumbers,
  convertTime,
  decimalToPercentage,
  getExplorerLink,
  HashType,
  hexToRgba,
  truncateDecimal,
} from "@lib/utils";
import { useWeb3, useGetRepayTransactionByLoanId } from "@lib/hooks";
import { UserLoan } from "@lib/types";

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1em",
  fontWeight: 500,
  color: "#FFF",
  marginBottom: "1.25em",

  "& .total": {
    fontSize: "23.4px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "26px",
    },
  },

  "& .label": {
    fontSize: "14.4px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  },
}));

export const Wrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    minWidth: "34.375rem",
    maxWidth: "50rem",
  },
}));

export const LineGrid = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "0.6fr 0.8fr 1fr 1fr 1fr 0.8fr 0.6fr",
  gridTemplateAreas: `'collateral locked borrowed borrowingFee expiration collateralRatio action'`,
  fontSize: "11.7px",
  padding: "1.54em 0",
});

export const Message = ({ message }: { message: string }): JSX.Element => {
  return (
    <LineGrid
      className="lineGrid"
      sx={(theme) => ({
        display: "flex",
        justifyContent: {
          xs: "flex-start",
          sm: "center",
        },
        color: hexToRgba(theme.palette.common.white, 0.6),
      })}
    >
      {message}
    </LineGrid>
  );
};

export const BorrowedListItem = ({
  index,
  borrowed,
}: {
  index: number;
  borrowed: UserLoan;
}): JSX.Element => {
  const {
    id,
    pool,
    collateral,
    netDebt,
    borrowingFee,
    expirationTime,
    paid,
    isExpired,
    collateralRatio,
  } = borrowed;
  const { open: showModal, setOpen: setShowModal } = useToggle();

  const { chainId } = useWeb3();

  const repayTransaction = useGetRepayTransactionByLoanId(id);

  return (
    <Fragment>
      <LineGrid data-test={`loanItem-${index}`} className="lineGrid" key={id}>
        <CellData data-test="collateral" sx={{ gridArea: "collateral", justifyContent: "start" }}>
          <BaseFlex>
            <LogoWrapper>
              {pool.collateralTokenLogoUrl ? (
                <Image src={pool.collateralTokenLogoUrl} height={18} width={18} />
              ) : (
                <Image src="/assets/tokenImages/Ethereum-ETH-icon.png" height={18} width={18} />
              )}
            </LogoWrapper>
            {pool.collateralToken.name}
          </BaseFlex>
        </CellData>

        <CellData data-test="locked" sx={{ gridArea: "locked" }}>
          {truncateDecimal(collateral, 4)} {pool.collateralToken.symbol}
        </CellData>

        <CellData data-test="borrowed" sx={{ gridArea: "borrowed" }}>
          {commaSeparateNumbers(truncateDecimal(netDebt))} {pool.debtToken.symbol}
        </CellData>

        <CellData data-test="borrowingFee" sx={{ gridArea: "borrowingFee" }}>
          {commaSeparateNumbers(truncateDecimal(borrowingFee))} {pool.debtToken.symbol}
        </CellData>

        {!repayTransaction || paid ? (
          <Fragment>
            <CellData data-test="expiration" sx={{ gridArea: "expiration" }}>
              <HintTooltip title={convertTime(expirationTime).full} disableFocusListener>
                <span>{convertTime(expirationTime).monthDayTime}</span>
              </HintTooltip>
            </CellData>

            <CellData sx={{ gridArea: "collateralRatio" }}>
              {collateralRatio ? (
                decimalToPercentage(collateralRatio)
              ) : (
                <CellLoader variant="text" width="30%" />
              )}
            </CellData>

            <CellData data-test="action" sx={{ gridArea: "action", justifyContent: "flex-end" }}>
              <RowActionButton
                name={paid ? "Repaid" : isExpired ? "Expired" : "Repay"}
                handleClick={() => setShowModal(true)}
                disabled={paid || isExpired}
                dataTestId="repay-action-button"
              />
            </CellData>
          </Fragment>
        ) : (
          <CellData
            sx={{
              gridArea: "1 / expiration /  1 / action",
              justifyContent: "flex-end",
            }}
          >
            <BaseFlex>
              <Spinner />
              Repaying loan
            </BaseFlex>
            <Box sx={{ marginLeft: "2rem" }}>
              <MuiLink
                href={getExplorerLink(
                  chainId as number,
                  repayTransaction.hash,
                  HashType.TRANSACTION
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BaseFlex>
                  <Box
                    sx={{
                      height: "17px",
                      width: "17px",
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    <ExternalLinkIcon />
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      opacity: 0.7,

                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    View on explorer
                  </Box>
                </BaseFlex>
              </MuiLink>
            </Box>
          </CellData>
        )}
      </LineGrid>

      <RepayModal open={showModal} onClose={() => setShowModal(false)} userLoan={borrowed} />
    </Fragment>
  );
};
