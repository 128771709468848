import { createTheme } from "@mui/material/styles";
import { blue, colorBG, dark, green, mint, red, white, yellow } from "./color";

export interface BaseComponentProps {
  /**
   * @dev
   * primary scheme is mint/green
   *
   * secondary scheme is pink/red
   */
  scheme?: "primary" | "secondary";
}

declare module "@mui/material/styles" {
  interface ColorRange {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorRange {}

  interface Palette {
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    dark: PaletteOptions["primary"];
  }

  interface TypographyVariants {
    headline1: React.CSSProperties;
    headline2: React.CSSProperties;
    headline3: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    numbers1: React.CSSProperties;
    numbers2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    headline1: React.CSSProperties;
    headline2: React.CSSProperties;
    headline3: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    numbers1: React.CSSProperties;
    numbers2: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    headline1: true;
    headline2: true;
    headline3: true;
    body1: true;
    body2: true;
    buttonL: true;
    buttonM: true;
    caption1: true;
    caption2: true;
    numbers1: true;
    numbers2: true;
  }
}

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      "Roc-Grotesk",
      "Poppins",
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none",
    },
    headline1: {
      fontFamily: "Roc-Grotesk",
      fontWeight: 500,
      fontSize: "1.375rem",
      lineHeight: 1.5,
    },
    headline2: {
      fontFamily: "Roc-Grotesk",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.7,
    },
    headline3: {
      fontFamily: "Roc-Grotesk",
      fontWeight: 700,
      fontSize: "0.75rem",
      lineHeight: 1.3,
    },
    body1: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.7,
    },
    body2: {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: "0.875rem",
      lineHeight: 1.7,
    },
    buttonL: {
      fontFamily: "Roc-Grotesk",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: 1,
    },
    buttonM: {
      fontFamily: "Roc-Grotesk",
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: 1.7,
    },
    caption1: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: 1.3,
    },
    caption2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.3,
    },
    numbers1: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "2rem",
      lineHeight: 1.25,
    },
    numbers2: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
    },
  },
  palette: {
    background: {
      default: colorBG,
    },
    primary: blue,
    secondary: mint,
    warning: yellow,
    error: red,
    success: green,
    dark: {
      main: dark,
    },
    common: {
      white: white,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(1,14,48,0.7)",

          ["@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)"]: {
            WebkitBackdropFilter: "blur(4px)",
            backdropFilter: "blur(4px)",
          },

          /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
          ["@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))"]: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        },
      },
    },
  },
});

export default theme;
