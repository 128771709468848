import { useWeb3 } from "@lib/hooks";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const BlockNumberContext = createContext<{
  value?: number;
}>({ value: undefined });

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext);
  return blockNumber;
}

/** Requires that BlockUpdater be installed in the DOM tree. */
export function useBlockNumber(): number | undefined {
  return useBlockNumberContext().value;
}

const BlockNumberProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { chainId: activeChainId, library } = useWeb3();
  const [{ chainId, block }, setChainBlock] = useState<{ chainId?: number; block?: number }>({
    chainId: activeChainId,
  });

  const onBlock = useCallback(
    (block: number) => {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId === activeChainId) {
          if (!chainBlock.block || chainBlock.block < block) {
            return { chainId: activeChainId, block };
          }
        }
        return chainBlock;
      });
    },
    [activeChainId, setChainBlock]
  );

  useEffect(() => {
    let stale = false;

    if (!library || !activeChainId) return;

    setChainBlock((chainBlock) =>
      chainBlock.chainId === activeChainId ? chainBlock : { chainId: activeChainId }
    );

    library
      .getBlockNumber()
      .then((block) => {
        if (!stale) onBlock(block);
      })
      .catch((error) => {
        console.error(error);
      });

    library.on("block", onBlock);

    return () => {
      stale = true;
      library.removeListener("block", onBlock);
    };
  }, [activeChainId, library, onBlock, setChainBlock]);

  const value = useMemo(
    () => ({
      value: chainId === activeChainId ? block : undefined,
    }),
    [activeChainId, block, chainId]
  );
  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>;
};

export default BlockNumberProvider;
