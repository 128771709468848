import { useCallback, useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { formatUnits, isAddress } from "ethers/lib/utils";
import { useBlockNumber, useTokenAllowanceStore } from "@lib/providers";
import { useWeb3 } from "@lib/hooks";

import { ERC20 } from "@coraprotocol/core/typechain";
import ERC20ABI from "@coraprotocol/core/abis/IERC20.json";

interface UseAllowanceProps {
  tokenAddress: string;
  spender: string;
  decimals?: number;
  refetch?: boolean;
}

interface FetchState {
  allowance?: {
    value: BigNumber;
    formatted: string;
  };
  loading: boolean;
  error?: {
    name: string;
    message: string;
  };
}

/**
 *  hook for fetching user allowance
 */
const useAllowance = ({
  tokenAddress,
  spender,
  decimals,
  refetch = true,
}: UseAllowanceProps): FetchState => {
  const { library, account } = useWeb3();
  const blockNumber = useBlockNumber();
  const [fetchState, setFetchState] = useState<FetchState>({ loading: true });
  const updateTokenAllowanceDetail = useTokenAllowanceStore(
    (state) => state.updateTokenAllowanceDetail
  );
  const tokenAllowanceDetail = useTokenAllowanceStore(
    (state) => state.tokenAllowanceDetails[`${tokenAddress}-${spender}`]
  );

  const fetchAllowance = useCallback(
    async ({
      tokenAddress,
      spender,
      account,
      formatDecimal = 18,
    }: {
      tokenAddress: string;
      spender: string;
      account: string;
      formatDecimal?: number;
    }) => {
      try {
        if (!isAddress(tokenAddress)) throw new Error("Invalid token address");
        if (!isAddress(spender)) throw new Error("Invalid spender address");

        setFetchState((state) => ({
          ...state,
          loading: !state.allowance?.value,
          error: undefined,
        }));

        updateTokenAllowanceDetail({
          id: tokenAddress,
          spender,
          isFetchingAllowance: true,
          lastUpdateOnBlock: blockNumber,
        });

        const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, library) as ERC20;
        const value = await tokenContract.allowance(account, spender);

        setFetchState((state) => ({
          ...state,
          allowance: {
            value,
            formatted: formatUnits(value, formatDecimal),
          },
          loading: false,
        }));

        updateTokenAllowanceDetail({
          id: tokenAddress,
          spender,
          isFetchingAllowance: false,
          allowance: {
            value,
            formatted: formatUnits(value, formatDecimal),
          },
          lastUpdateOnBlock: blockNumber,
        });
      } catch (error: any) {
        setFetchState({
          allowance: undefined,
          loading: false,
          error: {
            name: error?.name || "useAllowance error",
            message: error?.message || "Error while fetching allowance",
          },
        });

        updateTokenAllowanceDetail({
          id: tokenAddress,
          spender,
          isFetchingAllowance: false,
          allowance: undefined,
          lastUpdateOnBlock: blockNumber,
        });
      }
    },
    [tokenAddress, library, account]
  );

  useEffect(() => {
    if (!tokenAddress) return;
    if (!spender) return;
    if (!library) return;
    if (!account) return;

    let cancelled = false;

    if (cancelled) return;

    fetchAllowance({ tokenAddress, spender, account, formatDecimal: decimals });

    return () => {
      cancelled = true;
    };
  }, [account, tokenAddress, tokenAllowanceDetail?.isApproving]);

  useEffect(() => {
    if (!refetch) return;
    if (!tokenAddress) return;
    if (!spender) return;
    if (!account) return;
    if (!library) return;
    if (!blockNumber) return;

    fetchAllowance({ tokenAddress, spender, account, formatDecimal: decimals });
  }, [blockNumber, tokenAllowanceDetail?.isApproving]);

  return fetchState;
};

export default useAllowance;
