import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useWeb3 } from "@lib/hooks";
import { BaseFlex } from "@components/common";
import { TableContainer } from "@components/common/Table/TableComponents";
import {
  Header,
  Wrapper,
  Message,
  UserLiquidityHeader,
} from "@components/common/Table/UserLiquidity/UserLiquidityComponents";
import { convertToCurrency } from "@lib/utils";
import UserLiquidityItemsUI from "./UserLiquidityItems";
import { useLPPositionsStore } from "@lib/providers";
import { formatUnits } from "ethers/lib/utils";

const UserLiquidity = ({ withLabel }: { withLabel: boolean }): JSX.Element => {
  const { account } = useWeb3();
  const positions = useLPPositionsStore((state) => state.positions);
  const [totalSupplied, setTotalSupplied] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!account) return;

    const total = Object.values(positions).reduce((acc, curr) => {
      if (!curr.activeLiquidity.amount || !curr.pendingDeposit.amount) return acc;

      const totalPerPool = curr.activeLiquidity.amount.add(curr.pendingDeposit.amount);

      return (
        acc +
        parseFloat(formatUnits(totalPerPool)) *
          parseFloat(curr.poolDetails.debtToken.priceInUSD || "1")
      );
    }, 0);

    setTotalSupplied(total);
  }, [positions]);

  return (
    <TableContainer sx={{ paddingBottom: "0.5rem" }}>
      {withLabel ? (
        <Header sx={{ flexDirection: "column", alignItems: "flex-start" }}>
          <BaseFlex
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <BaseFlex className="apy">Your Provided Liquidity</BaseFlex>
          </BaseFlex>
          <Typography
            variant="numbers1"
            data-test="user-total-supplied"
            sx={{ color: (theme) => theme.palette.common.white }}
          >
            {totalSupplied ? convertToCurrency(totalSupplied) : "$0.00"}
          </Typography>
        </Header>
      ) : (
        <Header>
          <Typography
            variant="numbers1"
            data-test="user-total-supplied"
            sx={{ color: (theme) => theme.palette.common.white }}
          >
            {totalSupplied ? convertToCurrency(totalSupplied) : "$0.00"}
          </Typography>
        </Header>
      )}

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <UserLiquidityHeader />

          {account ? (
            <UserLiquidityItemsUI />
          ) : (
            <div>
              <Message message="Your wallet is not connected" />
            </div>
          )}
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default UserLiquidity;
