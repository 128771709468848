import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex, Search } from "@components/common";

import { ColumnHeader, TableContainer } from "@components/common/Table/TableComponents";
import { LineGrid, Wrapper, Message } from "@components/common/Table/Pool/PoolComponents";

const PoolTable = (): JSX.Element => {
  return (
    <TableContainer sx={{ backgroundColor: "transparent", padding: 0 }}>
      <LineGrid
        sx={{
          marginBottom: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          paddingBottom: "1em",
        }}
      >
        <BaseFlex sx={{ gridColumn: "debtToken / action" }}>
          <Search inputPlaceholder="Search pool" inputValue={""} onInputChange={() => undefined} />
        </BaseFlex>
      </LineGrid>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid className="lineGrid">
            <ColumnHeader
              sx={{ padding: "1.6875em 0", gridArea: "debtToken", justifyContent: "start" }}
            >
              Asset to supply
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "collateralToken" }}>
              Asset backing loan
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "totalDeposits" }}>
              Total Deposits
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "apy" }}>APY</ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "utilization" }}>
              Utilization
            </ColumnHeader>

            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "maxCap" }}>Max Cap</ColumnHeader>

            <ColumnHeader
              sx={{
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          <Message message="An error occured" />
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default PoolTable;
