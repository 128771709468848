import React, { useCallback, useEffect } from "react";
import { Slider } from "@components/common";
import { Controller, useFormContext, Validate } from "react-hook-form";
import { AmountInput } from "@components/common";
import { AmountInputProps } from "../AmountInput/AmountInput";
import { BaseComponentProps } from "styles/theme";
import { CustomSliderProps } from "../Slider";
import { Box } from "@mui/material";

type AmountInputWithControllerProps = Pick<
  AmountInputProps,
  "logo" | "conversionRate" | "name" | "onMaxClick" | "disabled"
> & {
  max: string;
  inputChange: (v: string) => void;
  moreValidationRules?: Record<string, Validate<any, any>>;
};

const AmountInputWithController = ({
  name,
  logo,
  conversionRate,
  max,
  onMaxClick,
  inputChange,
  moreValidationRules,
  disabled,
}: AmountInputWithControllerProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name as string}
      control={control}
      rules={{
        validate: {
          max: (value) => {
            if (parseFloat(value) > parseFloat(max)) {
              return "Insufficient funds.";
            }
          },
          isNotBlank: (value) => {
            if (value === "") {
              return "This is required.";
            }
          },
          isNotZero: (value) => {
            if (parseFloat(value) === 0) {
              return "This is required.";
            }
          },
          ...moreValidationRules,
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <AmountInput
            name={field.name}
            aria-label={field.name}
            logo={logo}
            conversionRate={conversionRate}
            customOnChange={(v) => {
              field.onChange(v);
              inputChange(v);
            }}
            value={field.value || ""}
            onMaxClick={onMaxClick}
            error={{
              message: error?.message !== "This is required." ? error?.message : undefined,
              gentleFeedback: error?.type === "min",
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

interface SliderWithControllerProps extends CustomSliderProps {
  sliderChange: (v: number) => void;
}

const SliderWithController = ({ name, sliderChange, disabled }: SliderWithControllerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name as string}
      control={control}
      render={({ field }) => {
        return (
          <Slider
            name={field.name}
            aria-label={field.name}
            onChange={(e, v) => {
              field.onChange(e);
              sliderChange(v as number);
            }}
            value={field.value}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export interface InputSliderPairProps
  extends BaseComponentProps,
    Pick<AmountInputWithControllerProps, "logo" | "moreValidationRules"> {
  inputName: string;
  sliderName: string;
  disabled?: boolean;
  max: string;
  /** when the max value depends on another variable
   * i.e. the borrow input that depends on the collateral input
   */
  variableMax?: boolean;
  conversionRate: string | undefined;
}

const InputSliderPair = ({
  inputName,
  sliderName,
  max,
  variableMax = false,
  conversionRate,
  disabled,
  logo,
  moreValidationRules,
}: InputSliderPairProps): JSX.Element => {
  const { setValue, getValues, trigger } = useFormContext();

  const inputChange = useCallback(
    (rawValue: string) => {
      const value = parseFloat(rawValue);
      const newSliderValue =
        isNaN(value) || value === 0 || max === "0" ? 0 : Math.min(value / parseFloat(max), 1); // the slider value should be between 0 and 1

      setValue(sliderName, newSliderValue);
    },
    [setValue, max]
  );

  const sliderChange = useCallback(
    (v: number) => {
      if (v === 1) {
        setValue(inputName, max, { shouldValidate: true, shouldDirty: true });
      } else {
        setValue(inputName, (v * parseFloat(max)).toString(), {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [setValue, max]
  );

  const onMaxClick = useCallback(() => {
    if (max === "0") return;

    setValue(inputName, max.toString(), { shouldValidate: true, shouldDirty: true });
    setValue(sliderName, 1, { shouldDirty: true });
  }, [setValue, max]);

  useEffect(
    function onMaxChange() {
      if (!variableMax) return;

      if (max === "0") {
        setValue(sliderName, 0);
        setValue(inputName, "");
      } else {
        // the slider value should always be dependent on the input value
        const inputVal = parseFloat(getValues(inputName));
        const newSliderValue =
          isNaN(inputVal) || inputVal === 0 || max === "0"
            ? 0
            : Math.min(inputVal / parseFloat(max), 1); // the slider value should be between 0 and 1

        setValue(sliderName, newSliderValue);
        trigger(inputName);
      }
    },
    [max]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <AmountInputWithController
        name={inputName}
        logo={logo}
        conversionRate={conversionRate}
        onMaxClick={onMaxClick}
        max={max}
        inputChange={inputChange}
        moreValidationRules={moreValidationRules}
        disabled={disabled}
      />
      <SliderWithController
        name={sliderName}
        // max={max}
        sliderChange={sliderChange}
        disabled={max === "0" || max === "" || disabled}
      />
    </Box>
  );
};

export default InputSliderPair;
