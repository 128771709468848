import React from "react";
import Image from "next/image";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { BaseFlex, ExclamationIcon } from "@components/common";
import { MaxButton, Wrapper, CustomInput } from "./AmountInputComponents";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { LogoWrapper } from "../Table/TableComponents";

export interface AmountInputProps extends Omit<NumberFormatProps, "onValueChange"> {
  customOnChange: (value: string) => void;
  logo?: string;
  onMaxClick: () => void;
  conversionRate?: string;
  error?: {
    message?: string;
    gentleFeedback?: boolean;
  };
}

const AmountInput = ({
  customOnChange,
  logo,
  onMaxClick,
  conversionRate,
  error,
  ...inputProps
}: AmountInputProps): JSX.Element => {
  return (
    <Wrapper>
      <BaseFlex
        sx={{
          padding: "0.5rem 1rem",
          borderRadius: "0.5em",
          border: (theme) =>
            error?.message
              ? error?.gentleFeedback
                ? `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.error[300]}`
              : `1px solid ${theme.palette.primary.main}`,

          backgroundColor: (theme) =>
            error?.message
              ? error?.gentleFeedback
                ? theme.palette.primary[700]
                : "rgba(255, 57, 114, 0.2)"
              : theme.palette.primary[700],
          transition: "border-color 0.2s ease-in-out, background-color 0.2s ease-in-out",
        }}
      >
        {logo && (
          <BaseFlex sx={{ minWidth: "1.25rem" }}>
            <LogoWrapper>
              <Image src={logo} height={24} width={24} />
            </LogoWrapper>
          </BaseFlex>
        )}
        <NumberFormat
          thousandSeparator
          customInput={CustomInput}
          onValueChange={(values) => {
            customOnChange(values.value);
          }}
          placeholder="0.00"
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
          decimalScale={18}
          {...inputProps}
        />
        <BaseFlex sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <BaseFlex sx={{ gap: "1em", margin: "1em 0.5em" }}>
            <Box className="conversion" sx={{ whiteSpace: "nowrap", fontWeight: 500 }}>
              {parseFloat(inputProps.value as string)
                ? conversionRate &&
                  `≈ ${commaSeparateNumbers(
                    truncateDecimal(
                      (
                        parseFloat(inputProps.value as string) * parseFloat(conversionRate)
                      ).toString()
                    )
                  )} USD`
                : "≈ 0.00 USD"}
            </Box>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ margin: 0, borderColor: (theme) => theme.palette.primary[600] }}
            />

            <MaxButton onClick={onMaxClick} disabled={inputProps.disabled} />
          </BaseFlex>
        </BaseFlex>
      </BaseFlex>

      <BaseFlex
        role="alert"
        sx={(theme) => ({
          ...theme.typography.caption1,
          visibility: error?.message ? "visible" : "hidden",
          color: error?.gentleFeedback ? theme.palette.secondary.main : theme.palette.error[300],
          padding: "2px 0 0",
          fontSize: "11px",
          fontWeight: 500,
          justifyContent: "flex-start",
          textDecoration: "italic",
          gap: 0,
        })}
      >
        <ExclamationIcon sx={{ color: "inherit" }} />
        {error?.message}
      </BaseFlex>
    </Wrapper>
  );
};

export default AmountInput;
