import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BaseComponentProps } from "styles/theme";

interface ActionButtonProps extends BaseComponentProps, ButtonProps {
  pendingAction?: boolean;
}

const ActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "scheme" && prop !== "pendingAction",
})<ActionButtonProps>(({ theme, pendingAction = false }) => ({
  ...theme.typography.buttonL,
  width: "100%",
  maxWidth: "400px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  gap: "0.5rem",
  padding: "0.74em",
  borderRadius: "0.5rem",
  color: theme.palette.dark.main,
  backgroundColor: theme.palette.secondary.main,
  transition: "opacity 0.2s ease-in-out",

  ":hover": {
    opacity: 0.9,
    backgroundColor: theme.palette.secondary.main,
  },

  ":disabled": {
    opacity: pendingAction ? 0.6 : 0.2,
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
  },
}));

export default ActionButton;
