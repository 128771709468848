export const convertToCurrency = (value: number): string =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);

export const commaSeparateNumbers = (value: number | string, maximumFractionDigits = 2): string => {
  let number: number;
  if (typeof value === "string") {
    number = parseFloat(value);
  } else {
    number = value;
  }
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits,
    minimumFractionDigits: 2,
  }).format(number);
};
