import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { BaseFlex, Hint, HintTooltip } from "@components/common";
import { Detail, DetailsWrapper } from "../TransactionCardBase";
import { commaSeparateNumbers, convertTime } from "@lib/utils";
import { TokenI } from "@lib/types";
import { externalLinks } from "@lib/constants";

export interface PoolDetailsProps {
  loading: boolean;
  poolName: string;
  poolTotalDeposits: number;
  poolUtilization: number;
  poolAPY: number;
  poolLTV: number;
  minimumLockupPeriod: string;
  nextRoundTimestamp: number;
  nextRoundTimestampRelative: string;
  poolDebtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
}

const PoolDetails = ({
  loading,
  poolName,
  poolTotalDeposits,
  poolUtilization,
  //   poolAPY,
  poolLTV,
  minimumLockupPeriod,
  nextRoundTimestamp,
  poolDebtToken,
}: PoolDetailsProps): JSX.Element => {
  return (
    <BaseFlex
      sx={{
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.5em",
      }}
    >
      <Typography
        variant="headline2"
        sx={{
          color: (theme) => theme.palette.common.white,
        }}
      >
        Pool Details
      </Typography>

      <DetailsWrapper>
        <Detail label="Pool Name" detail={poolName} loading={loading} />
        <Detail
          label="Total Deposits"
          detail={
            poolTotalDeposits &&
            `${commaSeparateNumbers(poolTotalDeposits.toFixed(2))} ${poolDebtToken.symbol}`
          }
          loading={loading}
        />
        <Detail
          label={
            <BaseFlex sx={{ gap: "1em" }}>
              Utilization
              <Hint title="The percentage of the pool that is currently being utilized / lent." />
            </BaseFlex>
          }
          detail={`${poolUtilization && (poolUtilization * 100)?.toFixed(2)}%`}
          loading={loading}
        />
        {/* <Detail
        label={<BaseFlex>Projected APY</BaseFlex>}
        detail={`${poolAPY && (poolAPY * 100)?.toFixed(2)}%`}
        loading={loading}
      /> */}

        <Detail
          label={
            <BaseFlex sx={{ gap: "1em" }}>
              LTV
              <Hint title="LTV (Loan To Value) is the maximum percentage of the collateral's spot price that this Lending Pool will allow borrowers to borrow." />
            </BaseFlex>
          }
          detail={`${poolLTV && (poolLTV * 100)?.toFixed(2)}%`}
          loading={loading}
        />

        <Detail
          label={
            <BaseFlex sx={{ gap: "1em" }}>
              Pricer Model
              <Hint
                title={
                  <div>
                    <span>
                      <MuiLink
                        href={externalLinks.PRICING_MODELS}
                        rel="noopener noreferrer"
                        target="_blank"
                        sx={{ color: "white", textDecoration: "underline" }}
                      >
                        Click here{" "}
                      </MuiLink>
                    </span>
                    to learn more about what pricing models Cora uses.
                  </div>
                }
              />
            </BaseFlex>
          }
          detail={
            <MuiLink
              href={externalLinks.PRICING_MODELS}
              rel="noopener noreferrer"
              target="_blank"
              sx={{ color: "white", textDecoration: "underline" }}
            >
              Black-Scholes
            </MuiLink>
          }
          loading={loading}
        />

        <Detail
          label={
            <BaseFlex sx={{ gap: "1em" }}>
              Minimum Lockup Period
              <Hint
                title={
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    <span>This is the minimum amount of time your liquidity will be locked.</span>

                    <MuiLink
                      sx={{ color: "inherit" }}
                      href="https://docs.cora.money/protocol-concepts/lending-pools/rounds"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Box component="span" sx={{ textDecoration: "underline" }}>
                        Learn more.
                      </Box>
                    </MuiLink>
                  </Box>
                }
              />
            </BaseFlex>
          }
          detail={minimumLockupPeriod}
          loading={loading}
        />

        <Detail
          label="Next Round"
          detail={
            <HintTooltip title={convertTime(nextRoundTimestamp).full} disableFocusListener>
              <span>{convertTime(nextRoundTimestamp).monthDayTime}</span>
            </HintTooltip>
          }
          loading={loading}
        />
      </DetailsWrapper>
    </BaseFlex>
  );
};

export default PoolDetails;
