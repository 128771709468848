import MuiSlider, { sliderClasses, SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DownCaret, BaseFlex } from "@components/common";
import { BaseComponentProps } from "styles/theme";
import { gradient4 } from "styles/color";
import { hexToRgba } from "@lib/utils";

export type CustomSliderProps = SliderProps & BaseComponentProps;

const Slider = styled(
  (props: CustomSliderProps) => (
    <Box sx={{ padding: "0 6px", width: "100%", display: "flex", alignItems: "center" }}>
      <MuiSlider step={1} marks {...props} />
    </Box>
  ),
  {
    shouldForwardProp: (prop) => prop !== "color",
  }
)(({ theme }) => ({
  [`& .${sliderClasses.thumb}`]: {
    background: gradient4,
    border: `2px solid ${hexToRgba(theme.palette.common.white, 0.3)}`,
    width: "11px",
    height: "11px",
    boxShadow: "none",

    ":hover": {
      boxShadow: "none",
    },
  },
  [`& .${sliderClasses.track}`]: {
    color: hexToRgba(theme.palette.common.white, 0.2),
    height: "1px",
    border: "none",
  },
  [`& .${sliderClasses.rail}`]: {
    color: hexToRgba(theme.palette.common.white, 0.2),
    height: "1px",
  },
  [`& .${sliderClasses.mark}`]: {
    width: "5px",
    height: "5px",
    borderRadius: "999px",
    backgroundColor: "white",
    transform: "translate(-3.5px, -50%)",
    opacity: 1,
  },
  [`& .${sliderClasses.markActive}`]: {
    width: "5px",
    height: "5px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "999px",
  },
}));

const CaretButtons = styled(IconButton)(({ theme }) => ({
  padding: 0,
  borderRadius: "0.5rem",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  transition: "background-color 0.2s ease-in-out",

  "&:hover": {
    backgroundColor: theme.palette.primary[400],
  },

  "&:disabled": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
}));

interface StepperProps {
  max: number;
  activeIndex: number;
  setIndex: (index: number) => void;
  increment?: () => void;
  decrement?: () => void;
}

const Stepper = ({
  max,
  activeIndex,
  setIndex,
  increment,
  decrement,
}: StepperProps): JSX.Element => {
  return (
    <BaseFlex sx={{ width: "100%", gap: "1rem" }}>
      <Slider
        max={max}
        value={activeIndex}
        onChange={(_e, newValue) => setIndex(newValue as number)}
      />

      <BaseFlex>
        <CaretButtons onClick={decrement}>
          <DownCaret sx={{ transform: "rotate(90deg)" }} />
        </CaretButtons>
        <CaretButtons onClick={increment}>
          <DownCaret sx={{ transform: "rotate(270deg)" }} />
        </CaretButtons>
      </BaseFlex>
    </BaseFlex>
  );
};

export default Stepper;
