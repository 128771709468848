import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const DiscordIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      // width="24"
      // height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4974 5.50645C18.0992 4.8961 16.6042 4.45253 15.0412 4.19995C14.8492 4.52374 14.625 4.95925 14.4704 5.3057C12.8088 5.07257 11.1626 5.07257 9.53164 5.3057C9.37705 4.95925 9.14769 4.52374 8.95402 4.19995C7.38932 4.45253 5.89258 4.89773 4.49437 5.50968C1.67417 9.48577 0.909665 13.3631 1.29192 17.1854C3.16242 18.4886 4.97516 19.2803 6.75731 19.7983C7.19734 19.2333 7.58978 18.6327 7.92785 17.9997C7.28397 17.7714 6.66727 17.4898 6.08456 17.1627C6.23915 17.0559 6.39037 16.9442 6.53646 16.8292C10.0906 18.3802 13.9522 18.3802 17.4639 16.8292C17.6117 16.9442 17.7629 17.0559 17.9158 17.1627C17.3313 17.4914 16.7129 17.7731 16.0691 18.0013C16.4071 18.6327 16.7979 19.235 17.2396 19.8C19.0235 19.2819 20.8379 18.4903 22.7084 17.1854C23.1569 12.7544 21.9422 8.91266 19.4974 5.50645ZM8.41205 14.8347C7.34514 14.8347 6.47019 13.9054 6.47019 12.7738C6.47019 11.6422 7.32646 10.7113 8.41205 10.7113C9.49767 10.7113 10.3726 11.6406 10.3539 12.7738C10.3556 13.9054 9.49767 14.8347 8.41205 14.8347ZM15.5883 14.8347C14.5214 14.8347 13.6464 13.9054 13.6464 12.7738C13.6464 11.6422 14.5026 10.7113 15.5883 10.7113C16.6739 10.7113 17.5488 11.6406 17.5301 12.7738C17.5301 13.9054 16.6739 14.8347 15.5883 14.8347Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DiscordIcon;
