import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "1rem",
    marginTop: "0.8125rem",
    minWidth: "12.3125rem",
    backgroundColor: theme.palette.common.white,

    "& .MuiMenu-list": {
      padding: "0.5rem",
    },
  },
}));

export const StyledMenuItem = styled((props: MenuItemProps & { active?: boolean }) => (
  <MenuItem disableRipple dense {...props} />
))(({ theme, active = false }) => ({
  ...theme.typography.body1,
  margin: "0 0 0.25rem",
  padding: "0.75rem 1rem",
  borderRadius: "0.5rem",
  color: active ? theme.palette.common.white : theme.palette.primary.main,
  transition: "background-color 0.1s ease-in-out, color 0.1s ease-in-out",
  backgroundColor: active ? theme.palette.primary.main : "transparent",

  ":hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
