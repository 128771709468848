import React, { Fragment } from "react";
import { CellData, CellLoader } from "../TableComponents";
import { LineGrid } from "./BorrowedComponents";

const BorrowedLoader = (): JSX.Element => {
  return (
    <Fragment>
      {[...Array(2)].map((_, index) => (
        <LineGrid className="lineGrid" key={index}>
          <CellData sx={{ gridArea: "collateral", justifyContent: "start" }}>
            <CellLoader variant="text" />
          </CellData>

          <CellData sx={{ gridArea: "locked" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "borrowed" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "borrowingFee" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "collateralRatio" }}>
            <CellLoader variant="text" width="30%" />
          </CellData>

          <CellData sx={{ gridArea: "expiration" }}>
            <CellLoader variant="text" width="80%" />
          </CellData>

          <CellData sx={{ gridArea: "action", justifyContent: "flex-end" }}>
            <CellLoader variant="text" width="40%" />
          </CellData>
        </LineGrid>
      ))}
    </Fragment>
  );
};

export default BorrowedLoader;
