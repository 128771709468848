import React from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { MenuProps } from "@mui/material/Menu";
import ButtonBase from "@mui/material/ButtonBase";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import { TransactionBox } from "@components/common/";
import {
  AccountDetailsBox,
  Copy,
  StyledAccountMenu,
  StyledAccountMenuItem,
} from "./AccountDropdownComponents";
import { DownCaret, ExternalLinkIcon } from "@components/common/Icons";
import { convertTime, getExplorerLink, HashType, generateTransactionStatus } from "@lib/utils";
import { TransactionDetailBase } from "@lib/providers/Store";
import { TransactionType } from "@lib/types";

export interface AccountDropdownProps {
  menuProps: MenuProps;
  address: string;
  transactions?: Array<TransactionDetailBase>;
  clearTransactions?: () => void;
  explorerLink: string;
  onLogoutClick: () => void;
}

const AccountDropdown = ({
  menuProps,
  address,
  transactions,
  clearTransactions,
  explorerLink,
  onLogoutClick,
}: AccountDropdownProps): JSX.Element => {
  return (
    <StyledAccountMenu {...menuProps}>
      <AccountDetailsBox>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="headline1" sx={{ color: (theme) => theme.palette.dark.main }}>
            Account
          </Typography>
          <ButtonBase className="content" onClick={onLogoutClick}>
            <Typography variant="buttonM" sx={{ color: (theme) => theme.palette.primary.main }}>
              Log Out
            </Typography>

            <DownCaret
              sx={{
                marginLeft: "0.5rem",
                transform: "rotate(270deg)",
                color: (theme) => theme.palette.primary.main,
              }}
            />
          </ButtonBase>
        </Box>

        <Box>
          <Typography
            variant="headline3"
            sx={{ color: (theme) => theme.palette.dark.main, opacity: 0.45 }}
          >
            Connected Address
          </Typography>
          <Typography variant="body1" sx={{ color: (theme) => theme.palette.dark.main }}>
            {address}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "2.0625rem" }}>
          <MuiLink
            href={explorerLink}
            rel="noopener noreferrer"
            target="_blank"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main }}>
              Explorer
            </Typography>
            <ExternalLinkIcon sx={{ height: "0.875rem" }} />
          </MuiLink>

          <Copy address={address} />
        </Box>
      </AccountDetailsBox>

      <Divider sx={{ backgroundColor: (theme) => theme.palette.primary[200] }} />

      <AccountDetailsBox>
        <Box
          className="label"
          sx={{
            paddingBottom: "1em",
            display: "flex",
          }}
        >
          <Typography
            variant="headline3"
            sx={{ color: (theme) => theme.palette.dark.main, opacity: 0.45 }}
          >
            Recent Transactions
          </Typography>

          {transactions?.length !== 0 && (
            <Box
              component="span"
              onClick={clearTransactions}
              sx={{
                textTransform: "none",
                marginLeft: "auto",
                cursor: "pointer",
              }}
            >
              <Typography variant="headline3" sx={{ color: (theme) => theme.palette.primary.main }}>
                Clear all
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            justifyContent: "flex-start",
            maxHeight: "40vh",
            flexDirection: "column",
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {transactions?.length === 0 ? (
            <Typography
              variant="headline3"
              sx={{ color: (theme) => theme.palette.dark.main, opacity: 0.45, marginTop: "1rem" }}
            >
              Nothing to show
            </Typography>
          ) : (
            transactions?.map((transaction) => {
              return (
                <StyledAccountMenuItem key={transaction.hash}>
                  <TransactionBox
                    transactionTitle={transaction.title}
                    transactionType={transaction.transactionInfo.type}
                    status={generateTransactionStatus({
                      isMined: transaction.isMined,
                      error: transaction.error,
                      isCancelled: !!transaction.isCancelled,
                    })}
                    dateTime={convertTime(transaction.addedTime / 1000).monthDayTime_short}
                    explorerLink={getExplorerLink(
                      transaction.chainId as number,
                      transaction.newHash || transaction.hash,
                      HashType.TRANSACTION
                    )}
                    tokenValue={
                      transaction.transactionInfo.type !== TransactionType.Approve
                        ? transaction.transactionInfo.transactedAmount?.amount
                        : undefined
                    }
                    fiatValueUSD={
                      transaction.transactionInfo.type !== TransactionType.Approve
                        ? (transaction.transactionInfo.transactedAmount?.amountInUSD as string)
                        : undefined
                    }
                  />
                </StyledAccountMenuItem>
              );
            })
          )}
        </Box>
      </AccountDetailsBox>
    </StyledAccountMenu>
  );
};

export default AccountDropdown;
