import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { ChainId, DEFAULT_CHAIN } from "@lib/constants";
import { useWeb3 } from "@lib/hooks";

interface ChainSyncI {
  chainIdSynced: ChainId | undefined;
  manualSetChainIdHandle: (chainId: ChainId) => void;
}

const ChainSyncContext = createContext<ChainSyncI>({
  chainIdSynced: undefined,
  manualSetChainIdHandle: () => undefined,
});

/**
 * the chainId state given by the web3-react library is undefined when the user wallet is disconnected
 *
 * this provider will get the chainId from the injected metamask provider when the above happens.
 *
 * if there is no metamask installed then the user can manually choose a network by clicking on the network dropdown menu
 * this is used to get the proper graphql endpoints
 */
const ChainSyncProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [chainIdSync, setChainIdSync] = useState<ChainId | undefined>(DEFAULT_CHAIN);
  const { chainId } = useWeb3();

  const manualSetChainIdHandle = useCallback((selectedChain = DEFAULT_CHAIN) => {
    setChainIdSync(selectedChain);
  }, []);

  const setChainIdHandler = useCallback(() => {
    if (chainId) {
      setChainIdSync(chainId);
    } else {
      setChainIdSync(parseInt(window?.ethereum?.chainId));
    }
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window?.ethereum) return;

    const chainChangedListener = (chainId: string) => {
      manualSetChainIdHandle(parseInt(chainId));
    };

    window.ethereum.on("chainChanged", chainChangedListener);

    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener("chainChanged", chainChangedListener);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window?.ethereum) return;

    setChainIdHandler();
  }, [chainId]);

  return (
    <ChainSyncContext.Provider value={{ chainIdSynced: chainIdSync, manualSetChainIdHandle }}>
      {children}
    </ChainSyncContext.Provider>
  );
};

export const useChainSyncContext = (): ChainSyncI => {
  return useContext(ChainSyncContext);
};

export default ChainSyncProvider;
