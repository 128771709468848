import createStore from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { TokenI } from "@lib/types";
import { BigNumber } from "ethers";

interface TokenDetails extends Pick<TokenI, "id"> {
  spender: string;
  allowance:
    | {
        value: BigNumber;
        formatted: string;
      }
    | undefined;
  isApproving?: boolean;
  isFetchingAllowance?: boolean;
  lastUpdateOnBlock?: number | undefined;
}

type State = Record<string, TokenDetails>;

interface TokenStoreI {
  tokenAllowanceDetails: State;
  updateTokenAllowanceDetail: (
    payload: Omit<TokenDetails, "allowance"> & {
      allowance?: {
        value: BigNumber;
        formatted: string;
      };
    }
  ) => void;
  getTokenAllowanceDetail: (address: string) => TokenDetails | undefined;
}

export const useTokenAllowanceStore = createStore<
  TokenStoreI,
  [["zustand/devtools", TokenStoreI], ["zustand/immer", TokenStoreI]]
>(
  devtools(
    immer((set, get) => ({
      tokenAllowanceDetails: {},
      updateTokenAllowanceDetail: (payload) => {
        set(
          (state) => {
            const id = `${payload.id}-${payload.spender}`;
            state.tokenAllowanceDetails[id] = {
              ...(state.tokenAllowanceDetails[id] || {}),
              ...payload,
            };
          },
          false,
          "updateTokenAllowanceDetails"
        );
      },
      getTokenAllowanceDetail: (address) => {
        return get().tokenAllowanceDetails[address];
      },
    })),
    { name: "TokenAllowanceStore" }
  )
);
