import { useTransactionStore } from "@lib/providers/";
import { useWeb3 } from "@lib/hooks";
import { Chain } from "@lib/constants";
import { TransactionDetailBase } from "@lib/providers/Store/types";

export const useAllTransactions = (): Array<TransactionDetailBase> => {
  const { chainId, account } = useWeb3();
  const transactions = useTransactionStore((state) => state.transactions[chainId as Chain]);

  if (!transactions) return [];

  return Object.keys(transactions)
    .map((hash) => transactions[hash])
    .filter((transaction) => transaction.account.toLowerCase() === account?.toLowerCase())
    .sort((a, b) => b.addedTime - a.addedTime);
};

export const useUnminedTransactions = (): Array<TransactionDetailBase> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => !transaction.isMined)
    .filter((transaction) => !transaction.isCancelled)
    .filter((transaction) => !transaction.error);
};

export const usePendingTransactions = (): Array<TransactionDetailBase> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => !transaction.isMined)
    .filter((transaction) => !transaction.isCancelled)
    .filter((transaction) => !transaction.error);
};
