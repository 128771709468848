const pathNames = {
  supply: "/supply",
  borrow: "/borrow",
  portfolio: "/portfolio",
  managePortfolio: "/portfolio/manage-lp/[poolId]",
  wrapUnwrap: "/wrap-unwrap-eth",
  termsOfUse: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
};

export default pathNames;
