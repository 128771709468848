import { useCallback, useState } from "react";
import { ContractTransaction, ethers } from "ethers";
import { Logger } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { ErrorToast } from "@components/common";
import { useContract, useIsMounted, useWeb3 } from "@lib/hooks";
import { TokenI, TransactionStatus, TransactionType } from "@lib/types";
import { useTokenAllowanceStore, useTransactionStore } from "@lib/providers";

import { ERC20 } from "@coraprotocol/core/typechain";
import ERC20ABI from "@coraprotocol/core/abis/IERC20.json";

interface UseApproveProps {
  tokenAddress: string;
  spender: string;
}

interface CallbackArgs {
  token: Pick<TokenI, "id" | "symbol">;
}

const useApprove = ({
  tokenAddress,
  spender,
}: UseApproveProps): {
  approve: (raw: CallbackArgs) => Promise<void>;
  response: ContractTransaction | undefined;
  error: any;
  transactionStatus: TransactionStatus;
} => {
  const { chainId } = useWeb3();

  const tokenContract = useContract<ERC20>(tokenAddress, ERC20ABI);
  const [response, setResponse] = useState<undefined | ContractTransaction>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(undefined);

  const addTransaction = useTransactionStore((state) => state.addTransaction);
  const isReplacedTransaction = useTransactionStore((state) => state.isReplacedTransaction);
  const isCancelledTransaction = useTransactionStore((state) => state.isCancelledTransaction);

  const updateTokenAllowanceDetail = useTokenAllowanceStore(
    (state) => state.updateTokenAllowanceDetail
  );

  // checks if the component using this hook is still mounted
  // useful for when the promise has not resolved or rejected yet but the transaction modal is already unmounted
  // the transaction modal will unmount after the user confirms transaction in metamask
  const isMounted = useIsMounted();

  const approve = useCallback(
    async (raw: CallbackArgs) => {
      if (!tokenContract) return;
      if (isMounted.current) {
        setTransactionStatus("confirm");
      }

      updateTokenAllowanceDetail({
        id: tokenAddress,
        spender,
        isApproving: true,
        // lastUpdateOnBlock: blockNumber,
      });

      try {
        const blockNumber = await tokenContract.provider?.getBlockNumber();
        const tx = await tokenContract.approve(spender, ethers.constants.MaxUint256);
        // const tx = await tokenContract.approve(spender, 0);
        setResponse(tx);

        if (isMounted.current) {
          setTransactionStatus("submitted");
        }

        addTransaction({
          hash: tx.hash,
          account: tx.from,
          chainId: chainId as number,
          receipt: tx,
          blockNumber,
          title: raw.token.symbol,
          description: `${raw.token.symbol} token for use.`,
          transactionInfo: {
            type: TransactionType.Approve,
            spender: spender,
            token: { id: raw.token.id, symbol: raw.token.symbol },
          },
        });

        await tx?.wait();

        if (isMounted.current) {
          setTransactionStatus("success");
        }
      } catch (error: any) {
        updateTokenAllowanceDetail({
          id: tokenAddress,
          spender,
          isApproving: false,
          // lastUpdateOnBlock: blockNumber,
        });

        if (error.code === Logger.errors.TRANSACTION_REPLACED) {
          if (error.reason === "cancelled") {
            isCancelledTransaction({
              hash: error.hash,
              chainId: chainId as number,
              newHash: error.replacement?.hash,
            });
            return;
          } else {
            isReplacedTransaction({
              hash: error.hash,
              chainId: chainId as number,
              newHash: error.replacement?.hash,
            });
            return;
          }
        }

        if (!isMounted.current) return;

        setError(error);
        setTransactionStatus("error");

        if (error.code === Logger.errors.ACTION_REJECTED) return;

        toast.error(<ErrorToast description={error?.message} />, {
          autoClose: 10000,
          hideProgressBar: false,
        });
      }
    },
    [tokenContract]
  );

  return { approve, response, error, transactionStatus };
};

export default useApprove;
