import React from "react";
import MuiCheckBox, { checkboxClasses, CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material";
import { BaseComponentProps } from "styles/theme";

interface CustomCheckBoxProps extends BaseComponentProps, CheckboxProps {
  error?: boolean;
}

const CheckBox = styled((props: CustomCheckBoxProps) => <MuiCheckBox {...props} />, {
  // do not forward error prop to MuiCheckBox
  shouldForwardProp: (prop) => prop !== "color" && prop !== "error",
})(({ theme, error = false }) => ({
  padding: 0,
  color: error ? theme.palette.error[300] : theme.palette.secondary.main,
  [`&.${checkboxClasses.checked}`]: {
    color: error ? theme.palette.error[300] : theme.palette.secondary.main,
  },
}));

export default CheckBox;
