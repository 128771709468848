import React, { Fragment } from "react";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import { ActionButton, ErrorBoxIcon } from "@components/common";
import { Content, MessageTitle, SubMessage } from "../TransactionStatusMessageComponents";
import { TransactionStatusMessageBaseProps } from "../types";

export interface ErrorMsgProps extends TransactionStatusMessageBaseProps {
  /**
   * @dev
   * waiting for an FAQ about errors in the docs
   */
  errorExplanationLink?: string;
}

const ErrorMsg = ({
  errorExplanationLink,
  onClose,
  message,
  show = false,
}: ErrorMsgProps): JSX.Element | null => {
  if (!show) return null;

  return (
    <Fragment>
      <Content
        data-test="transaction-error-message"
        sx={{
          height: {
            xs: "90%",
          },
        }}
      >
        <ErrorBoxIcon sx={{ height: "56px", width: "56px" }} />

        <Box sx={{ display: "flex", gap: "0.4375rem", flexDirection: "column" }}>
          <MessageTitle>Ooops...</MessageTitle>
          <SubMessage>{message ? message : "Something went wrong!"}</SubMessage>
        </Box>

        <ActionButton
          data-test="continue"
          onClick={onClose}
          className="actionButton"
          scheme="secondary"
        >
          Continue
        </ActionButton>
      </Content>

      {errorExplanationLink && (
        <Content
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: "1.4rem",
            paddingTop: "1rem",
            borderTop: (theme) => `1px solid ${theme.palette.primary[1000]}`,

            height: {
              xs: "10%",
            },
          }}
        >
          <MuiLink href={errorExplanationLink}>
            <SubMessage
              sx={{
                color: (theme) => theme.palette.primary[400],
              }}
            >
              Why do I have this issue?
            </SubMessage>
          </MuiLink>
        </Content>
      )}
    </Fragment>
  );
};

export default ErrorMsg;
