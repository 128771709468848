import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ExclamationIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* viewBox="0 0 16 14" */}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25694 1.09882C7.02154 -0.26048 8.97863 -0.260481 9.74324 1.09882L15.3235 11.0194C16.0735 12.3526 15.11 13.9999 13.5804 13.9999H2.41978C0.890129 13.9999 -0.073299 12.3526 0.676631 11.0194L6.25694 1.09882ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM8 3C7.44772 3 7 3.44772 7 4V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V4C9 3.44772 8.55228 3 8 3Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ExclamationIcon;
