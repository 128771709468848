import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { parseUnits } from "ethers/lib/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BorrowTransactionCard } from "@components/common";
import { TransactionStatusMessage } from "@components/ui";
import {
  useApprove,
  useBorrow,
  useDebounce,
  useERCBalance,
  useGetBorrowingFee,
  useWeb3,
  usePool,
  usePriceFeed,
  useShouldAppoveToken,
} from "@lib/hooks";
import { useToggle } from "@lib/providers";
import { bigNumberToFloat, commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI, TransactionStatus, TransactionType } from "@lib/types";

type Props = {
  poolAddress: string;
};

const COLLATERAL_INPUT_NAME = "collateralInput";
const COLLATERAL_SLIDER_NAME = "collateralSlider";
const BORROW_INPUT_NAME = "borrowInput";
const BORROW_SLIDER_NAME = "borrowSlider";
const LOAN_TERM_NAME = "periodSelector";

const Borrow = ({ poolAddress }: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [borrowStatusSync, setBorrowStatusSync] = useState<TransactionStatus | "form">("form");

  const methods = useForm({
    defaultValues: {
      [COLLATERAL_INPUT_NAME]: "",
      [COLLATERAL_SLIDER_NAME]: 0,
      [BORROW_INPUT_NAME]: "",
      [BORROW_SLIDER_NAME]: 0,
      [LOAN_TERM_NAME]: 0,
    },
    mode: "onChange",
  });

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useToggle();

  const { lendingPool } = usePool(poolAddress);

  const { balance: collateralBalance, loading } = useERCBalance({
    tokenAddress: lendingPool?.collateralToken.id as string,
    decimals: lendingPool?.collateralToken.decimals,
  });

  const { price: ETH_USDC_CONVERSION_RATE } = usePriceFeed({
    priceFeedAddress: lendingPool?.priceFeed as string,
    token: {
      id: lendingPool?.collateralToken.id,
      decimals: lendingPool?.collateralToken.decimals,
    },
  });

  const { price: DEBTTOKEN_USD_PRICE } = usePriceFeed({
    priceFeedAddress: lendingPool?.priceFeed as string,
    token: {
      id: lendingPool?.debtToken.id,
      decimals: lendingPool?.debtToken.decimals,
    },
  });

  const { shouldApprove, isApproving } = useShouldAppoveToken({
    token: lendingPool?.collateralToken as TokenI,
    spender: poolAddress,
    balance: collateralBalance?.value,
  });

  const debouncedCollateralValue = useDebounce(methods.watch(COLLATERAL_INPUT_NAME, "0"), 600);
  const debouncedBorrowValue = useDebounce(methods.watch(BORROW_INPUT_NAME, "0"), 600);
  const debouncedLoanTerm = useDebounce(methods.watch(LOAN_TERM_NAME, 0), 600);

  const {
    borrowingFee,
    getBorrowingFee,
    updating: isUpdatingBorrowingFee,
  } = useGetBorrowingFee(poolAddress);

  useEffect(() => {
    getBorrowingFee({
      loanAmount: { amount: debouncedBorrowValue, decimal: lendingPool?.debtToken.decimals },
      collateralAmount: {
        amount: debouncedCollateralValue,
        decimal: lendingPool?.collateralToken.decimals,
      },
      loanTerm: debouncedLoanTerm,
    });
  }, [
    debouncedBorrowValue,
    debouncedCollateralValue,
    debouncedLoanTerm,
    methods.formState.isValid,
  ]);

  const {
    borrow,
    transactionStatus: borrowStatus,
    error: borrowError,
    response: borrowResponse,
  } = useBorrow(poolAddress);

  const { approve } = useApprove({
    tokenAddress: lendingPool?.collateralToken.id as string,
    spender: poolAddress,
  });

  const handleBorrow = () => {
    borrow(
      parseUnits(methods.getValues(BORROW_INPUT_NAME), lendingPool?.debtToken.decimals),
      parseUnits(methods.getValues(COLLATERAL_INPUT_NAME), lendingPool?.collateralToken.decimals),
      methods.getValues(LOAN_TERM_NAME),
      {
        poolName: lendingPool?.name as string,
        collateralToken: {
          id: lendingPool?.collateralToken.id as string,
          symbol: lendingPool?.collateralToken.symbol as string,
          amount: methods.getValues(COLLATERAL_INPUT_NAME),
        },
        debtToken: {
          id: lendingPool?.debtToken.id as string,
          symbol: lendingPool?.debtToken.symbol as string,
          amount: methods.getValues(BORROW_INPUT_NAME),
          amountUnitPriceInUSD: DEBTTOKEN_USD_PRICE?.formatted,
        },
      }
    );
  };

  useEffect(() => {
    if (borrowStatus) {
      setBorrowStatusSync(borrowStatus);
    }
  }, [borrowStatus]);

  return borrowStatusSync === "form" ? (
    <FormProvider {...methods}>
      <BorrowTransactionCard
        loading={loading}
        collateralBalance={collateralBalance?.formatted ?? "0"}
        collateralInputName={COLLATERAL_INPUT_NAME}
        collateralSliderName={COLLATERAL_SLIDER_NAME}
        borrowInputName={BORROW_INPUT_NAME}
        borrowSliderName={BORROW_SLIDER_NAME}
        periodSelectorName={LOAN_TERM_NAME}
        collateralDebtTokenConversion={
          ETH_USDC_CONVERSION_RATE && lendingPool?.ltv
            ? parseFloat(ETH_USDC_CONVERSION_RATE.formatted) * lendingPool.ltv
            : 0.0
        }
        collateralToken={{
          ...(lendingPool?.collateralToken as TokenI),
          price: ETH_USDC_CONVERSION_RATE?.formatted,
          logo: lendingPool?.collateralTokenLogoUrl as string,
        }}
        debtToken={{
          ...(lendingPool?.debtToken as TokenI),
          price: DEBTTOKEN_USD_PRICE?.formatted,
          logo: lendingPool?.stableCoinTokenlogoUrl as string,
        }}
        poolLiquidity={lendingPool?.availableLiquidity || 0}
        periods={lendingPool?.allowedLoanTerms}
        minAmountToBorrow={lendingPool?.minAmountToBorrow as number}
        borrowingFee={
          borrowingFee ? bigNumberToFloat(borrowingFee, lendingPool?.debtToken.decimals) : undefined
        }
        nextRoundTimeStamp={lendingPool?.nextRoundTimestamp as number}
        nextRoundTimestampReadable={lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string}
        approveButtonIsDisabled={!shouldApprove}
        isApproving={isApproving}
        approveButtonOnClick={() =>
          approve({
            token: {
              id: lendingPool?.collateralToken.id as string,
              symbol: lendingPool?.collateralToken.symbol as string,
            },
          })
        }
        isSubmitting={borrowStatus === "confirm" || borrowStatus === "submitted"}
        isValid={active && !shouldApprove && !isApproving}
        onSubmit={handleBorrow}
        fullScreen={fullScreen}
        isUpdatingBorrowingFee={isUpdatingBorrowingFee}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.Borrow}
      status={borrowStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(false)}
      transactionHash={borrowResponse?.hash as string}
      onBackToForm={() => setBorrowStatusSync("form")}
      errorMessage={borrowError}
      confirmMessage={`Borrowing the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(BORROW_INPUT_NAME))
      )} ${lendingPool?.debtToken.symbol}.`}
    />
  );
};

export default Borrow;
