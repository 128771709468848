import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { BaseFlex, InfoIcon } from "@components/common";
import StrategyDetails from "./StrategyDetails";
import LPProjectionChart from "./LPProjectionChart";
import { useFormContext } from "react-hook-form";
import { decimalToPercentage } from "@lib/utils";

interface LendingPoolInfoProps {
  apy: number;
  depositValueInputName: string;
  nextRoundIn: string;
}

// TODO: get actual apy
const LendingPoolInfo = ({
  apy,
  depositValueInputName,
  nextRoundIn,
}: LendingPoolInfoProps): JSX.Element => {
  const { watch } = useFormContext();
  const depositValue = watch(depositValueInputName);

  return (
    <BaseFlex
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: "2.5em",
        color: "white",
        justifyContent: "start",
        maxWidth: {
          md: "460px",
        },
        display: {
          xs: "none",
          lg: "flex",
        },
      }}
    >
      <BaseFlex
        sx={{
          width: "100%",
          marginTop: "0.5em",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            color: (theme) => theme.palette.common.white,
            marginBottom: "0.75em",
          }}
        >
          Projected APY:{" "}
          <Typography
            variant="headline2"
            component="span"
            sx={{ color: (theme) => theme.palette.secondary.main }}
          >
            {decimalToPercentage(apy)}
          </Typography>
        </Typography>

        <Box sx={{ width: "100%", margin: "0" }}>
          <LPProjectionChart depositValue={depositValue} apy={apy} aspectRatio={1.6} />
        </Box>
      </BaseFlex>

      <BaseFlex
        sx={{
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
          justifyContent: "start",
          height: "100%",
        }}
      >
        <StrategyDetails
          detailList={[
            {
              id: "0",
              title: "1. Pool Creation",
              diagram: "/images/lendingStrategy/1.png",
              description: (
                <div>
                  <p>Pools are created by the community.</p>
                </div>
              ),
            },
            {
              id: "1",
              title: "2. LP's Deposit",
              diagram: "/images/lendingStrategy/2.png",
              description: (
                <div>
                  <p>
                    Genesis round starts. Liquidity providers can deposit their tokens into the
                    pool.
                  </p>
                </div>
              ),
            },
            {
              id: "2",
              title: `3. Round of ${nextRoundIn} starts`,
              diagram: "/images/lendingStrategy/4.png",
              description: (
                <div>
                  <p>
                    Borrowing is enabled. LPs can deposit and withdraw but these will be active till
                    the next round.
                  </p>
                  <MuiLink
                    sx={{ color: "inherit", display: "flex", alignItems: "center", gap: "0.5em" }}
                    href="https://docs.cora.money/protocol-concepts/lending-pools/rounds"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <InfoIcon
                      sx={{
                        fontSize: {
                          xs: "0.8125rem",
                          xl: "1rem",
                        },
                      }}
                    />
                    <Box component="span" sx={{ textDecoration: "underline" }}>
                      Learn more about rounds.
                    </Box>
                  </MuiLink>
                </div>
              ),
            },
            {
              id: "3",
              title: `4. After ${nextRoundIn}, the round restarts`,
              diagram: "/images/lendingStrategy/5.png",
              description: (
                <div>
                  <p>
                    Signaled deposits and withdrawals are computed. Pool rewards are distributed.
                  </p>
                  <MuiLink
                    sx={{ color: "inherit", display: "flex", alignItems: "center", gap: "0.5em" }}
                    href="https://docs.cora.money/protocol-concepts/lending-pools/rounds"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <InfoIcon
                      sx={{
                        fontSize: {
                          xs: "0.8125rem",
                          xl: "1rem",
                        },
                      }}
                    />
                    <Box component="span" sx={{ textDecoration: "underline" }}>
                      Learn more about rounds.
                    </Box>
                  </MuiLink>
                </div>
              ),
            },
          ]}
        />
      </BaseFlex>
    </BaseFlex>
  );
};

export default LendingPoolInfo;
