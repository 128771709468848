import { Interface } from "ethers/lib/utils";
import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import BorrowerLogicAbi from "@coraprotocol/core/abis/BorrowerLogic.json";
import LiquidityProviderLogicAbi from "@coraprotocol/core/abis/LiquidityProviderLogic.json";
import PoolLogicAbi from "@coraprotocol/core/abis/PoolLogic.json";

interface TransactionErrorHandlerReturn {
  name: string;
  message: string;
}

interface NestedError {
  code: number;
  message: string;
  data: {
    originalError: {
      code: number;
      data: string;
      message: string;
    };
  };
}

const FALLBACK_MESSAGE = {
  name: "Transaction error",
  message: "Something went wrong.",
};

const camelCaseToSentence = (camelCase: string) => {
  const result = camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
    .toLowerCase();

  return result.charAt(0).toUpperCase() + result.slice(1);
};

const errorList: Record<string, string> = {
  DepositTooLow: "The deposit amount is too low.",
  PendingWithdrawals:
    "You still have a pending withdrawal. Please withdraw the funds first before supplying.",
  DepositMaxCap: "The deposit amount exceeds the maximum deposit cap.",
  MaxBorrowAmount: "The borrow amount exceeds the maximum borrow amount.",
  LoanAmountTooHigh: "The loan amount exceeds the maximum loan amount.",
  LoanAmountTooLow: "The loan amount is too low.",
  LoanTermInvalid: "The loan term is invalid.",
  LoanExpirationInNextRound: "The loan expiration is in the next round.",
  BorrowFeeCannotBeZero: "Borrow fee cannot be zero.",
  RepayExpiredLoan: "Repaying an expired loan is not allowed.",
  RepayNonOwnedLoan: "Repaying a loan that is not owned is not allowed.",
  RepayAlreadyPaid: "The loan has already been repaid.",
  AdjustLpRoundSequentially: "The LP round must be adjusted sequentially.",
  AdjustLpRoundUnfinishedRound: "The LP round is not finished.",
  AdjustLpRoundNoDeposit: "The LP round has no deposit.",
  PreviousRoundNotAdjusted: "The previous round must be adjusted before the current round.",
  WithdrawAlreadySignaled: "Withdraw has already been signaled.",
  WithdrawSignalCannotBeZero: "Withdraw signal cannot be zero.",
  WithdrawSignalNoDeposit: "A withdraw signal cannot be created without a deposit.",
  WithdrawSignalBadAmount: "Withdraw signal amount is invalid.",
  WithdrawSignalAmountTooHigh: "Withdraw signal amount is too high.",
  WithdrawNoSignaled: "Withdraw has not been signaled.",
  CollectIsEmpty: "Nothing to collect.",
  PreviousRoundNotPopulated: "Previous round must be populated before the current round.",
  CreateRoundPreviousRoundNotAdjusted: "Previous round must be adjusted before the current round.",
  ProtocolPaused: "The protocol is paused.",
  RecoverFundsBadParams: "Bad parameters when recovering funds.",
};

const errorDecoder = (errorHex: string, abi: any) => {
  const iface = new Interface(abi);

  try {
    const decoded = iface.parseError(errorHex);
    return { ...decoded, parsedMessage: errorList[decoded.name] };
  } catch {
    return undefined;
  }
};

const transactionErrorHandler = (error: unknown): TransactionErrorHandlerReturn => {
  const errorHex = (error as Record<"error", NestedError>)?.error?.data?.originalError?.data;

  // this is for the docker
  // const errorHex = (error as Record<"error", NestedError>)?.data?.data?.result;

  for (const decoder of [
    errorDecoder(errorHex, LendingPoolAbi),
    errorDecoder(errorHex, BorrowerLogicAbi),
    errorDecoder(errorHex, LiquidityProviderLogicAbi),
    errorDecoder(errorHex, PoolLogicAbi),
  ]) {
    if (decoder?.name && decoder?.parsedMessage) {
      return {
        name: "Transaction error",
        message: decoder.parsedMessage || `${camelCaseToSentence(decoder.name)}.`,
      };
    }
  }

  return {
    ...FALLBACK_MESSAGE,
    message:
      (error as Record<"reason", string>).reason === "execution reverted" // coming from the actual ethers error
        ? FALLBACK_MESSAGE.message
        : (error as Record<"reason", string>).reason,
  };
};

export default transactionErrorHandler;
