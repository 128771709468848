import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";

/**
 * from dev/subgraph
 * collateral ratio = amount of collateral * collateral price / debt
 * The front end should multiple collateral ratio * collateral price
 * loan.collateralRatio = loan.collateralAmount.div(loan.debtAmount)
 *
 * This will be equal to => collateral * collateralPrice / debt
 */
export const getCollateralRatio = (
  collateralAmount?: number,
  debtAmount?: number,
  collateralPrice?: number
): number | undefined => {
  if (!collateralAmount || !debtAmount || !collateralPrice) return undefined;

  return (collateralAmount * collateralPrice) / debtAmount;
};

/**
 * from dev/subgraph
 *  if (pool.totalBorrowed.gt(ZERO)) {
 *   pool.utilization = pool.totalBorrowed.div(pool.totalDeposits)
 * }
 */
export const getUtilization = (
  totalBorrowed?: BigNumber,
  availableLiquidity?: BigNumber,
  decimals?: number
): number | undefined => {
  if (!totalBorrowed || !availableLiquidity) return undefined;

  if (totalBorrowed.gt(BigNumber.from(0))) {
    const utilization =
      parseFloat(formatUnits(totalBorrowed, decimals)) /
      parseFloat(formatUnits(availableLiquidity, decimals));

    return utilization;
  } else {
    return 0;
  }
};
