import React from "react";
import { Navbar } from "@components/ui";
import { Box } from "@mui/material";
import { ActionButton, BaseFlex, ErrorBoxIcon, Footer } from "@components/common";
import { hexToRgba } from "@lib/utils";

const Root = ({ resetError }: { resetError: () => void }): JSX.Element => {
  return (
    <Box style={{ position: "relative", zIndex: 2 }}>
      <BaseFlex sx={{ flexDirection: "column" }}>
        <Box
          sx={{
            padding: {
              xs: "1.75rem 1.5rem 0",
              md: "1.75rem 2rem",
              lg: "1.75rem 3rem",
              xl: "1.75rem 5.375rem",
            },
            minHeight: {
              xs: "100vh",
              xl: "80vh",
            },
            width: "100%",

            maxWidth: {
              xl: "115rem",
            },
          }}
        >
          <Navbar />

          <Box
            component="main"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: {
                xs: "10rem",
                sm: "8rem",
                md: "3.375rem",
              },
            }}
          >
            <BaseFlex
              sx={{
                borderRadius: "1rem",
                gap: "0.5rem",
                flexDirection: "column",
                width: "35rem",
                padding: "2.5rem",
                backgroundColor: (theme) => theme.palette.primary[800],
                textAlign: "center",
              }}
            >
              <ErrorBoxIcon sx={{ height: "56px", width: "56px" }} />

              <Box
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontSize: "1.0625rem",
                  lineHeight: 1.4,
                  margin: "1rem 0 0.2rem",
                }}
              >
                Ooops...
              </Box>

              <Box
                sx={{
                  color: (theme) => hexToRgba(theme.palette.common.white, 0.8),
                  marginBottom: "1rem",
                }}
              >
                It looks like we&apos;re having issues. The team has been notified.
              </Box>

              <ActionButton onClick={resetError} className="actionButton" scheme="secondary">
                Continue
              </ActionButton>
            </BaseFlex>
          </Box>
        </Box>

        <Footer />
      </BaseFlex>
    </Box>
  );
};

export default Root;
