/* eslint-disable no-console */
import { Contract } from "ethers";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ChainId, chainList } from "@lib/constants";
import { PoolI } from "@lib/types";
import { AddPoolsPayload } from "@lib/providers/PoolsStore/types";

import FactoryAbi from "@coraprotocol/core/abis/LendingPoolFactory.json";
import CoraLendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import ERC20Abi from "@lib/contracts/abi/ERC20.json";
import { LendingPoolFactory, LendingPool } from "@coraprotocol/core/typechain";
import { ERC20 } from "@lib/contracts/abi/types/ERC20";

type PoolBaseI = AddPoolsPayload[0];

const getDeployedPools = async (
  supportedChains: Array<ChainId>
): Promise<Record<ChainId, Record<PoolI["id"], PoolBaseI>>> => {
  const list = Object.fromEntries<Record<PoolI["id"], PoolBaseI>>(
    supportedChains.map((chain) => [chain, {}])
  );

  await Promise.all(
    supportedChains.map(async (chain) => {
      const poolList: Record<PoolI["id"], PoolBaseI> = {};
      const externalRPC = chainList[chain].externalRPC as string;
      const factoryAddress = chainList[chain].factoryAddress as string;
      if (!externalRPC || !factoryAddress) return;

      console.log(`Using chainId: ${chain} and factory: ${factoryAddress}`);

      try {
        const provider = new StaticJsonRpcProvider(externalRPC);
        const factoryContract = new Contract(
          factoryAddress,
          FactoryAbi,
          provider
        ) as LendingPoolFactory;

        console.log("getting pool address generated by factory:", factoryAddress);

        const poolsLengthHex = await factoryContract.poolsLength();
        const poolsLength = parseInt(poolsLengthHex.toString(), 16);

        for (let i = 0; i < poolsLength; i++) {
          try {
            const poolId = await factoryContract.getPoolByIndex(i);

            console.log(`Fetching pool info for pool: ${poolId}`);

            const lendingPoolContract = new Contract(
              poolId,
              CoraLendingPoolAbi,
              provider
            ) as LendingPool;

            const poolParams = await lendingPoolContract.poolParams();
            const poolsInfo = await lendingPoolContract.roundsInfo();

            const stablecoinTokenContract = new Contract(
              poolParams.stablecoinToken,
              ERC20Abi,
              provider
            ) as ERC20;

            const collateralTokenContract = new Contract(
              poolParams.collateralToken,
              ERC20Abi,
              provider
            ) as ERC20;

            console.log(`Fetching stablecoin info: ${poolParams.stablecoinToken}`);

            const stablecoinTokenName = await stablecoinTokenContract.name();
            const stablecoinTokenSymbol = await stablecoinTokenContract.symbol();
            const stablecoinTokenDecimals = await stablecoinTokenContract.decimals();

            console.log(`Fetching collateralcoin info: ${poolParams.collateralToken}`);

            const collateralTokenName = await collateralTokenContract.name();
            const collateralTokenSymbol = await collateralTokenContract.symbol();
            const collateralTokenDecimals = await collateralTokenContract.decimals();

            poolList[poolId] = {
              id: poolId,
              name: `${stablecoinTokenName}-${collateralTokenName}`, // TODO: get pool name from contract

              debtToken: {
                id: poolParams.stablecoinToken,
                name: stablecoinTokenName,
                symbol: stablecoinTokenSymbol,
                decimals: stablecoinTokenDecimals,
              },
              collateralToken: {
                id: poolParams.collateralToken,
                name: collateralTokenName,
                symbol: collateralTokenSymbol,
                decimals: collateralTokenDecimals,
              },
              priceFeed: poolParams.priceFeed,
              createdAtTimestamp: parseInt(poolsInfo.initialRoundTimestamp.toString()),
              genesisRoundDuration: parseInt(poolsInfo.genesisRoundDuration.toString()),
              roundDuration: parseInt(poolsInfo.roundDuration.toString()),
            };
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      }

      list[chain] = poolList;
    })
  );
  return list;
};

export default getDeployedPools;
