import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ExternalLinkIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.705 2.565a.855.855 0 000 1.71h2.211l-5.38 5.38a.855.855 0 101.209 1.21l5.38-5.38v2.21a.855.855 0 101.71 0V3.42a.855.855 0 00-.855-.855H9.705z"
        fill="currentColor"
      />
      <path
        d="M4.575 4.275a1.71 1.71 0 00-1.71 1.71v6.84c0 .944.766 1.71 1.71 1.71h6.84a1.71 1.71 0 001.71-1.71V10.26a.855.855 0 10-1.71 0v2.565h-6.84v-6.84H7.14a.855.855 0 100-1.71H4.575z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ExternalLinkIcon;
