import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BaseFlex } from "@components/common";
import { Wrapper } from "./ManageLiquidityComponents";

interface NFTCardProps {
  name: string;
  link?: string;
  loading?: boolean;
  nftIcon: ReactNode;
}

const NFTCard = ({ name, loading, nftIcon }: NFTCardProps): JSX.Element => {
  return (
    <Wrapper>
      <BaseFlex
        sx={{
          flexDirection: "column",
          padding: "0 1rem",
          gap: "4.25rem",
        }}
      >
        <Box sx={{ width: "260px", height: "260px" }}>{nftIcon}</Box>

        <BaseFlex
          sx={{
            display: "flex",
            gap: "0.5em",
            visibility: loading ? "hidden" : "visible",
          }}
        >
          <Typography variant="headline1" sx={{ color: (theme) => theme.palette.common.white }}>
            {loading ? "..." : name}
          </Typography>
        </BaseFlex>
      </BaseFlex>
    </Wrapper>
  );
};

export default NFTCard;
