import React from "react";
import NextLink from "next/link";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export interface NavlinkProps {
  text: string;
  url: string;
  selected?: boolean;
}

const Navlink = ({ text, url, selected = false }: NavlinkProps): JSX.Element => {
  return (
    <NextLink href={url} passHref>
      <MuiLink
        data-test={`navlink-${text}`}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "0.375rem",
            width: "0.375rem",
            marginRight: "0.3rem",
            borderRadius: "9999px",
            backgroundColor: (theme) => theme.palette.secondary.main,
            opacity: selected ? 1 : 0,
          }}
        />
        <Typography
          component="span"
          variant="buttonM"
          sx={{
            whiteSpace: "nowrap",
            color: ({ palette }) => (selected ? palette.secondary.main : palette.common.white),
            textTransform: "capitalize",

            ":hover": {
              color: ({ palette }) => palette.secondary.main,
            },
          }}
        >
          {text}
        </Typography>
      </MuiLink>
    </NextLink>
  );
};

export default Navlink;
