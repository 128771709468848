import React, { ReactNode } from "react";
import { ConfirmIcon } from "@components/common";
import { Content, MessageTitle, SubMessage } from "../TransactionStatusMessageComponents";
import { TransactionStatusMessageBaseProps } from "../types";

export interface ConfirmMsgProps extends Omit<TransactionStatusMessageBaseProps, "onClose"> {
  title?: string | ReactNode;
}

const ConfirmMsg = ({
  title = (
    <>
      Confirm this transaction <br /> in your wallet
    </>
  ),
  message,
  show = false,
}: ConfirmMsgProps): JSX.Element | null => {
  if (!show) return null;

  return (
    <Content data-test="transaction-confirm-message">
      <ConfirmIcon />

      <MessageTitle>{title}</MessageTitle>

      <SubMessage>{message}</SubMessage>
    </Content>
  );
};

export default ConfirmMsg;
