import React from "react";

const NFTCoin = (): JSX.Element => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 260 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_648_9301)">
        <g filter="url(#filter0_b_648_9301)">
          <circle cx="130.001" cy="130" r="130" fill="url(#paint0_linear_648_9301)" />
          <circle
            cx="130.001"
            cy="130"
            r="129.5"
            stroke="url(#paint1_linear_648_9301)"
            strokeOpacity="0.5"
          />
        </g>
        <rect
          opacity="0.5"
          x="16.2972"
          y="15.6322"
          width="227.611"
          height="227.611"
          rx="113.805"
          stroke="#17C9FF"
          strokeWidth="2"
        />
        <rect
          opacity="0.5"
          x="8.54382"
          y="7.87878"
          width="243.117"
          height="243.117"
          rx="121.559"
          stroke="white"
          strokeWidth="5.62771"
          strokeDasharray="1.13 4.5"
        />
        <path
          d="M69.1904 157.364C84.9264 158.419 110.529 158.114 132.659 148.4C151.28 140.226 161.618 132.435 173.835 114.915C183.695 100.774 186.686 84.1541 189.308 73.5198"
          stroke="white"
          strokeDasharray="2.25 2.25"
        />
        <path d="M56.1888 157.727H207.359" stroke="white" strokeDasharray="2.25 2.25" />
        <rect
          width="154.679"
          height="0.776927"
          transform="matrix(1 0 0 -1 54.6918 186.297)"
          fill="white"
        />
        <rect
          x="54.6918"
          y="186.297"
          width="112.777"
          height="0.74843"
          transform="rotate(-90 54.6918 186.297)"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_648_9301"
          x="-225.108"
          y="-225.109"
          width="710.217"
          height="710.217"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="112.554" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_648_9301" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_648_9301"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_648_9301"
          x1="51"
          y1="26"
          x2="193.5"
          y2="241.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7DA9FF" stopOpacity="0.22" />
          <stop offset="0.229167" stopColor="#5CD5EA" stopOpacity="0.24" />
          <stop offset="0.778927" stopColor="#66F4E3" stopOpacity="0.24" />
          <stop offset="1" stopColor="white" stopOpacity="0.24" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_648_9301"
          x1="130.001"
          y1="0"
          x2="130.001"
          y2="260"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_648_9301">
          <rect width="260" height="260" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NFTCoin;
