import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const SearchIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.4">
        <path
          d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13405 6.13401 3.00004 10 3.00004C13.866 3.00004 17 6.13405 17 10Z"
          stroke="white"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};

export default SearchIcon;
