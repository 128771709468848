import React from "react";
import { SubDetails } from "@components/common";
import FeesEarnedUI from "./subDetails/FeesEarned";
import CollateralUI from "./subDetails/Collateral";
import LiquidityActionDetailUI from "./subDetails/LiquidityActionDetail";

const SubDetailsUI = ({ poolId }: { poolId: string }): JSX.Element => {
  return (
    <SubDetails>
      <FeesEarnedUI poolId={poolId} />
      <CollateralUI poolId={poolId} />
      <LiquidityActionDetailUI poolId={poolId} />
    </SubDetails>
  );
};

export default SubDetailsUI;
