import { TransactionDetailBase, CancelWithdrawTransactionInfo } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingCancelWithdrawTransactionByPool = (
  poolId: string
): TransactionDetailBase<CancelWithdrawTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.CancelWithdraw)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (cancelWithdraw): cancelWithdraw is TransactionDetailBase<CancelWithdrawTransactionInfo> =>
        !cancelWithdraw.isMined
    )
    .filter((cancelWithdraw) => cancelWithdraw.transactionInfo.lendingPoolId === poolId)[0];
};

export const usePendingCancelWithdrawTransactions =
  (): TransactionDetailBase<CancelWithdrawTransactionInfo>[] => {
    const transactions = useAllTransactions();

    return transactions
      .filter((transaction) => transaction.transactionInfo.type === TransactionType.CancelWithdraw)
      .filter((transaction) => !transaction.error)
      .filter((transaction) => !transaction.isCancelled)
      .filter(
        (cancelWithdraw): cancelWithdraw is TransactionDetailBase<CancelWithdrawTransactionInfo> =>
          !cancelWithdraw.isMined
      );
  };
