import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex, Search } from "@components/common";
import { ColumnHeader, TableContainer, SortDirectionAsc } from "../TableComponents";
import { LineGrid, Wrapper, Message, PoolItem } from "./PoolComponents";
import { ToggleProvider } from "@lib/providers";
import useSearch from "@lib/hooks/useSearch/";
import PoolLoader from "./PoolLoader";
import { PoolI } from "@lib/types";

export type PoolParams = Pick<
  PoolI,
  | "id"
  | "name"
  | "maxCap"
  | "availableLiquidity"
  | "apy"
  | "utilization"
  | "debtToken"
  | "collateralToken"
  | "collateralTokenLogoUrl"
  | "stableCoinTokenlogoUrl"
>;

export interface PoolTableProps {
  error: boolean;
  loading: boolean;
  poolDataList: Array<PoolParams>;
  onDebtTokenClick?: () => void;
  onCollateralTokenClick?: () => void;
  onAPYClick?: () => void;
  onUtilizationClick?: () => void;
  onPoolSizeClick?: () => void;
  onMaxCapClick?: () => void;
  sortedColumn?: PoolColumns;
  sortDirection?: SortDirectionAsc;
  connected?: boolean;
}

export enum PoolColumns {
  DEBTTOKEN = "debtToken",
  COLLATERALTOKEN = "collateralToken",
  APY = "apy",
  UTILIZATION = "utilization",
  TOTALDEPOSIT = "totalDeposits",
  MAXCAP = "maxCap",
}

const poolSearch = (pool: PoolParams, query: string): boolean =>
  pool.name.toLowerCase().includes(query.toLowerCase());

const PoolTable = ({
  error,
  loading,
  poolDataList,
  onDebtTokenClick,
  onCollateralTokenClick,
  onAPYClick,
  onUtilizationClick,
  onMaxCapClick,
  onPoolSizeClick,
  sortedColumn,
  sortDirection,
  connected = false,
}: PoolTableProps): JSX.Element => {
  const [searchedList, handleChange, queryString] = useSearch(poolDataList, poolSearch);

  const items =
    searchedList.length > 0 ? (
      searchedList.map((pool, index) => {
        return (
          // this provider is used so that we can toggle close a parent modal from a child
          // like in this case the the modal should close when a transaction is sent
          // but the one calling the close function is a grandchild of this component
          <ToggleProvider key={pool.id}>
            <PoolItem index={index} pool={pool} connected={connected} />
          </ToggleProvider>
        );
      })
    ) : (
      <Message message="No pools to show" />
    );

  return (
    <TableContainer sx={{ paddingBottom: 0 }}>
      <LineGrid
        sx={{
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: "2rem",
        }}
      >
        <BaseFlex sx={{ gridColumn: "debtToken / action" }}>
          <Search
            inputPlaceholder="Search pool"
            inputValue={queryString}
            onInputChange={handleChange}
          />
        </BaseFlex>
      </LineGrid>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid className="lineGrid">
            <ColumnHeader
              sx={{ padding: 0, gridArea: "debtToken", justifyContent: "start" }}
              onClick={onDebtTokenClick}
            >
              Asset to supply
              {sortedColumn !== PoolColumns.DEBTTOKEN || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader
              sx={{ padding: 0, gridArea: "collateralToken" }}
              onClick={onCollateralTokenClick}
            >
              Asset backing loan
              {sortedColumn !== PoolColumns.COLLATERALTOKEN || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "totalDeposits" }} onClick={onPoolSizeClick}>
              Total Deposits
              {sortedColumn !== PoolColumns.TOTALDEPOSIT || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "apy" }} onClick={onAPYClick}>
              APY
              {sortedColumn !== PoolColumns.APY || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "utilization" }} onClick={onUtilizationClick}>
              Utilization
              {sortedColumn !== PoolColumns.UTILIZATION || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "maxCap" }} onClick={onMaxCapClick}>
              Max Cap
              {sortedColumn !== PoolColumns.MAXCAP || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader
              sx={{
                padding: 0,
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          {!loading ? items : <PoolLoader />}
          {error && !loading && <Message message="An error occured" />}
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default PoolTable;
