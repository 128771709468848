import React, { useState } from "react";
import NextLink from "next/link";
import Box from "@mui/system/Box";
import MuiLink from "@mui/material/Link";
import { BaseBox, CoraLogo, FaucetMarquee, MenuIcon } from "@components/common";
import { AccountNavButton, Drawer } from "@components/ui";
import { pathNames } from "@lib/constants";

const Mobile = (): JSX.Element => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  return (
    <>
      <Box sx={{ inset: 0, position: "absolute" }}>
        <FaucetMarquee />
        <BaseBox
          as="nav"
          sx={{
            position: "sticky",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            width: "100%",
            backgroundColor: "#010d30",
            borderRadius: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MenuIcon onClick={() => setShowDrawer(!showDrawer)} />

            <NextLink href={pathNames.supply} passHref>
              <MuiLink sx={{ display: "flex", alignItems: "center" }}>
                <CoraLogo />
              </MuiLink>
            </NextLink>
          </Box>

          <AccountNavButton />
        </BaseBox>
      </Box>

      <Drawer open={showDrawer} setOpen={setShowDrawer} />
    </>
  );
};

export default Mobile;
