import React from "react";
import { ActionButton, BaseFlex, SpinnerTwo } from "@components/common";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";
import { Detail, DetailsWrapper } from "../TransactionCardBase";

export interface WithdrawCollateralTransactionCardProps {
  loading: boolean;
  collateralAvailable: string;
  collateralToken: TokenI;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const WithdrawCollateral = ({
  collateralAvailable,
  collateralToken,
  loading,
  isSubmitting,
  onSubmit,
  isValid,
}: WithdrawCollateralTransactionCardProps): JSX.Element => {
  return (
    <BaseFlex
      as="form"
      sx={{
        padding: "4.25rem 2rem 1rem",
        flexDirection: "column",
        gap: "6.75rem",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <DetailsWrapper>
        <Detail
          label="Collateral to be withdrawn"
          detail={`${commaSeparateNumbers(truncateDecimal(collateralAvailable, 4), 4)} ${
            collateralToken.symbol
          }`}
          loading={loading}
        />
      </DetailsWrapper>

      <BaseFlex sx={{ width: "100%" }}>
        <ActionButton
          disabled={!isValid || isSubmitting || loading}
          pendingAction={isSubmitting}
          onClick={onSubmit}
          fullWidth
        >
          {isSubmitting && <SpinnerTwo />}
          {isSubmitting ? "Withdrawing" : "Withdraw"}
        </ActionButton>
      </BaseFlex>
    </BaseFlex>
  );
};

export default WithdrawCollateral;
