import React from "react";
import { CoinPriceBox } from "@components/common";
import { usePools, usePriceFeed, useWeb3 } from "@lib/hooks";
import { chainList } from "@lib/constants";

const CoinPrice = (): JSX.Element => {
  const { chainId } = useWeb3();
  const { lendingPools, loading: poolLoading } = usePools();
  const { price, loading: priceLoading } = usePriceFeed({
    priceFeedAddress: lendingPools[0]?.priceFeed as string,
    token: {
      id: lendingPools[0]?.collateralToken.id as string,
      decimals: lendingPools[0]?.collateralToken.decimals as number,
    },
  });

  return (
    <CoinPriceBox
      header="Cora Wrapped Ethereum -  USD (cWETH-USD)"
      oracle={chainList[chainId as number]?.priceFeed?.oracle}
      url={chainList[chainId as number]?.priceFeed?.oracleDocs}
      price={price?.formatted}
      loading={priceLoading || poolLoading}
    />
  );
};

export default CoinPrice;
