import React from "react";
import { MainDetails } from "@components/common";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import WithdrawActionButton from "./mainDetails/WithdrawActionButton";
import SupplyActionButton from "./mainDetails/SupplyActionButton";
import { useLPPositionsStore } from "@lib/providers/";

const MainDetailsUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const { activeLiquidity, feesEarned, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolId]
  );

  return (
    <MainDetails
      loading={!activeLiquidity.amount || !feesEarned.amount}
      activeLiquidity={
        activeLiquidity.formattedAmount && feesEarned.formattedAmount
          ? `${commaSeparateNumbers(truncateDecimal(activeLiquidity.formattedAmount))} ${
              poolDetails.debtToken?.symbol
            } (+ ${commaSeparateNumbers(truncateDecimal(feesEarned.formattedAmount))})`
          : undefined
      }
      withdrawActionButton={<WithdrawActionButton poolId={poolId} />}
      supplyActionButton={<SupplyActionButton poolId={poolId} />}
    />
  );
};

export default MainDetailsUI;
