import React, { useState, useEffect } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { CopyIcon } from "@components/common/Icons";
import { BaseFlex } from "@components/common";

export const StyledAccountMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transitionDuration={{ enter: 400, exit: 100 }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "1rem",
    marginTop: "0.8125em",
    color: "#FFF",
    backgroundColor: theme.palette.common.white,
    boxShadow: "10px 10px 35px rgba(0, 0, 0, 0.33)",
    padding: 0,
    width: "400px",
    [theme.breakpoints.up("xl")]: {
      width: "445px",
    },
  },
}));

export const AccountDetailsBox = styled("div")({
  padding: "1.5em 1.8em",
  lineHeight: 1.6,
});

export const StyledAccountMenuItem = styled("li")(({ theme }) => ({
  padding: 0,
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.primary[200]}`,
  },
}));

export const Copy = ({ address }: { address: string }): JSX.Element => {
  const [text, setText] = useState<"Copy" | "Copied!">("Copy");

  const handleClick = () => {
    navigator.clipboard.writeText(address);
    setText("Copied!");
  };

  useEffect(() => {
    const timer = setTimeout(() => setText("Copy"), 1000);
    return () => clearTimeout(timer);
  }, [text]);

  return (
    <BaseFlex
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        color: (theme) => (text === "Copy" ? theme.palette.primary.main : theme.palette.error.main),
        gap: 0,
      }}
    >
      <Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main }}>
        {text}
      </Typography>

      <CopyIcon
        sx={{
          height: "14px",
          width: "14px",
          marginLeft: "0.5em",
          color: (theme) =>
            text === "Copy" ? theme.palette.primary.main : theme.palette.error.main,
        }}
      />
    </BaseFlex>
  );
};
