import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TransactionCardBase } from "@components/common";
import { TransactionCardBaseProps } from "@components/common/TransactionCard/TransactionCardBase";
import Borrow from "./Borrow/Borrow";
import Repay from "./Repay/Repay";
import { UserLoan } from "@lib/types";
import { gradient4 } from "styles/color";

export interface BorrowModalProps extends Omit<TransactionCardBaseProps, "children"> {
  poolAddress: string;
}

export interface RepayModalProps extends Omit<TransactionCardBaseProps, "children"> {
  userLoan: UserLoan;
}

export const BorrowModal = ({ open, onClose, poolAddress }: BorrowModalProps): JSX.Element => {
  return (
    <TransactionCardBase open={open} onClose={onClose}>
      <Typography
        variant="headline1"
        sx={{
          textAlign: "center",
          width: "100%",
          color: (theme) => theme.palette.common.white,
          padding: "0.8em",
        }}
      >
        Borrow
      </Typography>

      <Box sx={{ height: "4px", background: gradient4, width: "100%" }} />

      <Borrow poolAddress={poolAddress} />
    </TransactionCardBase>
  );
};

export const RepayModal = ({ open, onClose, userLoan }: RepayModalProps): JSX.Element => {
  return (
    <TransactionCardBase open={open} onClose={onClose}>
      <Typography
        variant="headline1"
        sx={{
          textAlign: "center",
          width: "100%",
          color: (theme) => theme.palette.common.white,
          padding: "0.8em",
        }}
      >
        Repay
      </Typography>

      <Box sx={{ height: "4px", background: gradient4, width: "100%" }} />

      <Repay userLoan={userLoan} />
    </TransactionCardBase>
  );
};
