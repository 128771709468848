import { styled } from "@mui/material";

export const MessageTitle = styled("div")(({ theme }) => ({
  ...theme.typography.headline1,
  color: theme.palette.common.white,
  margin: "1rem 0 0.2rem",
}));

export const SubMessage = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.common.white,
  width: "100%",
  overflow: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "2.3125rem",
  flexDirection: "column",
  padding: "3.375rem 2.5rem 3rem",

  textAlign: "center",
  width: "28.75rem",

  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    height: "100%",
  },

  ".actionButton": {
    marginTop: "2.4375rem",
    width: "unset",
    padding: "1rem 1.5rem",
  },
}));
