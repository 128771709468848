import React, { ReactNode } from "react";
import {
  useEagerConnect,
  useInactiveListener,
  useIsMobile,
  useReloadOnChainChanged,
} from "@lib/hooks";
import { Navbar } from "@components/ui";
import { Box } from "@mui/material";
import { BaseFlex, FaucetMarquee, Footer } from "@components/common";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const { isMobile } = useIsMobile();
  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);

  // The app needs to reload to cancel all inflight multicall requests
  useReloadOnChainChanged();

  return (
    <>
      <Box style={{ position: "relative", zIndex: 2 }}>
        {!isMobile && <FaucetMarquee />}

        <BaseFlex sx={{ flexDirection: "column" }}>
          <Box
            sx={{
              padding: {
                xs: "1.75rem 1.5rem 0",
                md: "1.75rem 2rem",
                lg: "1.75rem 3rem",
                xl: "1.75rem 5.375rem 10rem",
              },
              minHeight: {
                xs: "100vh",
                xl: "80vh",
              },
              width: "100%",

              maxWidth: {
                xl: "1840px",
              },
            }}
          >
            <Navbar />

            <Box
              component="main"
              sx={{
                marginTop: {
                  xs: "10rem",
                  sm: "8rem",
                  md: "3.375rem",
                },
              }}
            >
              {children}
            </Box>
          </Box>

          <Footer />
        </BaseFlex>
      </Box>
    </>
  );
};

export default Layout;
