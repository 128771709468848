import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const BaseButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  borderRadius: "0.25em",
  background: theme.palette.primary.main,
  color: "#FFF",
  padding: "1.1em 1.43em",
  fontWeight: 500,
}));

export default BaseButton;
