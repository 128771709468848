import { useCallback, useState, ChangeEvent, useEffect } from "react";

type Filter<Type> = (item: Type, queryString: string) => boolean;

const search = <Type>(
  collection: Array<Type>,
  searchFunction: Filter<Type>,
  queryString: string
): Array<Type> => {
  if (queryString) {
    return collection.filter((item) => searchFunction(item, queryString));
  } else {
    return collection;
  }
};

const useSearch = <Type>(
  collection: Array<Type>,
  searchFunction: Filter<Type>
): [
  filteredCollection: Array<Type>,
  handleChange: (event: ChangeEvent<HTMLInputElement> | string) => void,
  queryString: string
] => {
  const [queryString, setQueryString] = useState<string>(""); //initial query: ""
  const [filtered, setFiltered] = useState<Array<Type>>(() =>
    search<Type>(collection, searchFunction, queryString)
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | string) => {
      setQueryString(typeof event === "string" ? event : event.target.value);
    },
    [setQueryString]
  );

  useEffect(() => {
    setFiltered(search(collection, searchFunction, queryString));
  }, [queryString, searchFunction, collection]);

  return [filtered, handleChange, queryString];
};

export default useSearch;
