import { TransactionDetailBase, BorrowTransactionInfo } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingBorrowTransactions = (): Array<
  TransactionDetailBase<BorrowTransactionInfo>
> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.Borrow)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter((borrow): borrow is TransactionDetailBase<BorrowTransactionInfo> => !borrow.isMined);
};
