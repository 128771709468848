import { useAllTransactions } from "@lib/hooks";
import { TransactionDetailBase, SignalWithdrawTransactionInfo } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";

export const usePendingSignalWithdrawTransactionByPool = (
  poolId: string
): TransactionDetailBase<SignalWithdrawTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.SignalWithdraw)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (signalWithdraw): signalWithdraw is TransactionDetailBase<SignalWithdrawTransactionInfo> =>
        !signalWithdraw.isMined
    )
    .filter((signalWithdraw) => signalWithdraw.transactionInfo.lendingPoolId === poolId)[0];
};

export const usePendingSignalWithdrawTransactions =
  (): TransactionDetailBase<SignalWithdrawTransactionInfo>[] => {
    const transactions = useAllTransactions();

    return transactions
      .filter((transaction) => transaction.transactionInfo.type === TransactionType.SignalWithdraw)
      .filter((transaction) => !transaction.error)
      .filter((transaction) => !transaction.isCancelled)
      .filter(
        (signalWithdraw): signalWithdraw is TransactionDetailBase<SignalWithdrawTransactionInfo> =>
          !signalWithdraw.isMined
      );
  };
