import React, { useEffect, useState } from "react";
import { useWeb3, useWithdrawCollateral } from "@lib/hooks";
import { WithdrawCollateralTransactionCard } from "@components/common";
import { useLPPositionsStore } from "@lib/providers";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TransactionStatus, TransactionType } from "@lib/types";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";

const WithdrawCollateral = ({ poolAddress }: { poolAddress: string }): JSX.Element => {
  const [withdrawStatusSync, setWithdrawStatusSync] = useState<TransactionStatus | "form">("form");

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useModalManager();

  const { pendingCollateral, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolAddress]
  );
  const {
    withdrawCollateral,
    transactionStatus: withdrawCollateralStatus,
    error: withdrawCollateralError,
    response: withdrawCollateralResponse,
  } = useWithdrawCollateral(poolAddress);

  const handleSubmit = () => {
    withdrawCollateral({
      poolName: poolDetails.name,
      collateralToken: {
        ...poolDetails.collateralToken,
        amount: pendingCollateral.formattedAmount as string,
        amountUnitPriceInUSD: poolDetails.collateralToken?.priceInUSD,
      },
    });
  };

  useEffect(() => {
    if (withdrawCollateralStatus) {
      setWithdrawStatusSync(withdrawCollateralStatus);
    }
  }, [withdrawCollateralStatus]);

  return withdrawStatusSync === "form" ? (
    <WithdrawCollateralTransactionCard
      loading={!pendingCollateral.formattedAmount}
      collateralAvailable={pendingCollateral.formattedAmount as string}
      collateralToken={{ ...poolDetails.collateralToken }}
      isSubmitting={
        withdrawCollateralStatus === "confirm" || withdrawCollateralStatus === "submitted"
      }
      onSubmit={handleSubmit}
      isValid={active}
    />
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.WithdrawCollateral}
      status={withdrawCollateralStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setWithdrawStatusSync("form")}
      transactionHash={withdrawCollateralResponse?.hash as string}
      errorMessage={withdrawCollateralError}
      confirmMessage={`Withdrawing the amount of ${commaSeparateNumbers(
        truncateDecimal(pendingCollateral.formattedAmount as string, 4),
        4
      )} ${poolDetails.collateralToken.symbol}.`}
    />
  );
};

export default WithdrawCollateral;
