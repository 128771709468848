import { TransactionDetailBase, TransactionInfo } from "./types";
import createStore from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";
import { Chain, ChainId } from "@lib/constants";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "@components/common";
import { actionDescriptor } from "./utils";
import { supportedChainIds } from "@lib/connectors";

type Hash = string;
type State = Record<ChainId, Record<Hash, TransactionDetailBase<TransactionInfo>>>;
type AddTransactionPayload = Omit<
  TransactionDetailBase<TransactionInfo>,
  "addedTime" | "confirmTime" | "isMined" | "error"
>;

const initialState: State = Object.fromEntries(supportedChainIds.map((chain) => [chain, {}]));

interface LocalTransactionI {
  transactions: State;
  addTransaction: (payload: AddTransactionPayload) => void;
  isMinedTransaction: (payload: { hash: string; chainId: Chain }) => void;
  isErrorTransaction: (payload: { hash: string; chainId: Chain; error?: string }) => void;
  isReplacedTransaction: (payload: { hash: string; chainId: Chain; newHash: string }) => void;
  isCancelledTransaction: (payload: { hash: string; chainId: Chain; newHash: string }) => void;
  clearTransactions: (payload: { chainId: Chain; account: string }) => void;
}

export const useTransactionStore = createStore<
  LocalTransactionI,
  [
    ["zustand/devtools", LocalTransactionI],
    ["zustand/persist", LocalTransactionI],
    ["zustand/immer", LocalTransactionI]
  ]
>(
  devtools(
    persist(
      immer((set, get) => ({
        transactions: initialState,
        addTransaction: (payload) =>
          set(
            (state) => {
              state.transactions[payload.chainId][payload.hash] = {
                ...payload,
                addedTime: new Date().getTime(),
                confirmTime: undefined,
                isMined: false,
                error: false,
              };
            },
            false,
            "transactionIsAdded"
          ),
        isMinedTransaction: ({ hash, chainId }) => {
          set(
            (state) => {
              state.transactions[chainId][hash] = {
                ...state.transactions[chainId][hash],
                description: `${actionDescriptor(
                  state.transactions[chainId][hash].transactionInfo.type
                )} ${state.transactions[chainId][hash].description}`,
                isMined: true,
              };
            },
            false,
            "transactionIsMined"
          );
          const transaction = get().transactions[chainId][hash];

          toast.success(
            <SuccessToast
              hash={transaction.newHash || hash}
              chainId={chainId}
              description={transaction.description}
            />,
            { autoClose: 10000, hideProgressBar: false, toastId: hash }
          );
        },
        isErrorTransaction: ({ hash, chainId }) => {
          set(
            (state) => {
              state.transactions[chainId][hash] = {
                ...state.transactions[chainId][hash],
                description: `${actionDescriptor(
                  state.transactions[chainId][hash].transactionInfo.type,
                  false
                )} ${state.transactions[chainId][hash].description}`,
                isMined: false,
                error: true,
              };
            },
            false,
            "transactionHasError"
          );
          const transaction = get().transactions[chainId][hash];

          toast.error(
            <ErrorToast hash={hash} chainId={chainId} description={transaction.description} />,
            { autoClose: 10000, hideProgressBar: false, toastId: hash }
          );
        },
        isReplacedTransaction: ({ hash, chainId, newHash }) => {
          set(
            (state) => {
              state.transactions[chainId][hash] = {
                ...state.transactions[chainId][hash],
                newHash,
                isMined: false,
                error: false,
              };
            },
            false,
            "transactionIsReplaced"
          );
        },
        isCancelledTransaction: ({ hash, chainId, newHash }) => {
          set(
            (state) => {
              state.transactions[chainId][hash] = {
                ...state.transactions[chainId][hash],
                newHash,
                isMined: false,
                error: false,
                isCancelled: true,
              };
            },
            false,
            "transactionIsCancelled"
          );
        },
        clearTransactions: ({ chainId, account }) => {
          set((state) => {
            state.transactions[chainId] = Object.fromEntries(
              Object.entries(state.transactions[chainId]).filter(([_, info]) => {
                return info.account !== account;
              })
            );
          });
        },
      })),
      {
        name: "local-transaction-6",
      }
    ),
    { name: "TransactionStore" }
  )
);
