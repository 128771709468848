import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";

export enum ManageLiquidityModalType {
  SUPPLY,
  SIGNAL_WITHDRAW,
  WITHDRAW_COLLATERAL,
  CANCEL_PENDING_DEPOSIT,
  CANCEL_PENDING_WITHDRAWAL,
  WITHDRAW_AVAILABLE,
}

interface LiquidityModalManagerI {
  open: ManageLiquidityModalType | null;
  setOpen: Dispatch<SetStateAction<ManageLiquidityModalType | null>>;
}

const ModalContext = createContext<LiquidityModalManagerI>({
  open: null,
  setOpen: () => undefined,
});

const ManageLiquidityModalManager = ({ children }: { children: ReactNode }): JSX.Element => {
  const [open, setOpen] = useState<ManageLiquidityModalType | null>(null);

  return <ModalContext.Provider value={{ open, setOpen }}>{children}</ModalContext.Provider>;
};

export const useModalManager = (): LiquidityModalManagerI => {
  return useContext(ModalContext);
};

export default ManageLiquidityModalManager;
