import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { stepLabelClasses, StepLabelProps } from "@mui/material/StepLabel";
import { styled } from "@mui/material";
import { TransactionStep } from "@lib/types";
import { hexToRgba } from "@lib/utils";

const steps = [TransactionStep.MINING, TransactionStep.INDEXING, TransactionStep.DONE];

interface TransactionStepperProps {
  activeStep: TransactionStep;
}

const TransactionStepper = ({ activeStep }: TransactionStepperProps): JSX.Element => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={steps.indexOf(activeStep)} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <CustomStepLabel error={activeStep === TransactionStep.FAIL}>{label}</CustomStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TransactionStepper;

type CustomSliderProps = StepLabelProps;

const CustomStepLabel = styled((props: CustomSliderProps) => <StepLabel {...props} />)(
  ({ theme }) => ({
    [`&.${stepLabelClasses.root}`]: {
      [`.${stepLabelClasses.label}`]: {
        fontSize: "11.7px",
        color: hexToRgba(theme.palette.common.white, 0.5),
      },

      [`& .${stepLabelClasses.active}`]: {
        color: theme.palette.secondary.main,
      },

      [`& .${stepLabelClasses.completed}`]: {
        color: theme.palette.secondary.main,
      },
      [`& .${stepLabelClasses.iconContainer}`]: {
        // fontSize: "21.7px",
      },
    },
  })
);
