import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ClockIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4185 16 16 12.4183 16 8C16 3.58167 12.4185 0 8 0C3.58154 0 0 3.58167 0 8C0 12.4183 3.58154 16 8 16ZM7.44238 2.16968C7.60156 2.0625 7.79346 2 8 2C8.55225 2 9 2.44775 9 3V7H12C12.5522 7 13 7.44775 13 8C13 8.55225 12.5522 9 12 9H8C7.44775 9 7 8.55225 7 8V3C7 2.65417 7.17529 2.34937 7.44238 2.16968Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ClockIcon;
