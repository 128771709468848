const externalLinks = {
  DOCS: "https://docs.cora.money/",
  GITHUB: "https://github.com/Cora-Protocol",
  // AUDIT: "#audit",
  AUDIT: undefined,
  // FORUM: "#forum",
  FORUM: undefined,
  // VOTING: "#voting",
  VOTING: undefined,
  DISCORD: "https://t.co/xsLBy5I2Pn",
  MEDIUM: "https://blog.cora.money/",
  MIRROR: "https://mirror.xyz/coraprotocol.eth",
  TWITTER: "https://twitter.com/CoraProtocol",
  TERMSOFUSE_PRIVACYPOLICY: "#termofuseandprivacypolicy",
  BLACKSCHOLES_MODEL: "https://docs.cora.money/protocol-concepts/pricing-models/black-scholes",
  PRICING_MODELS: "https://docs.cora.money/protocol-concepts/pricing-models",
  GOERLI_FAUCET: process.env.NEXT_PUBLIC_FAUCET_LINK as string,
  MOONBASE_ALPHA_TESTNET_FAUCET: process.env
    .NEXT_PUBLIC_MOONBASE_ALPHA_TESTNET_FAUCET_LINK as string,
};

export default externalLinks;
