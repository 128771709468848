import React from "react";
import { ActionButton } from "@components/common/ManageLiquidity/ManageLiquidityComponents";
import { ManageLiquidityModalType, useModalManager } from "../modalManager/ModalManager";
import { usePendingDepositTransactionsByPool } from "@lib/hooks";

const SupplyActionButton = ({ poolId }: { poolId: string }): JSX.Element => {
  const { setOpen } = useModalManager();
  const transaction = usePendingDepositTransactionsByPool(poolId);

  return (
    <ActionButton
      handleClick={() => setOpen(ManageLiquidityModalType.SUPPLY)}
      name={transaction ? "Supplying" : "Supply"}
      disabled={!poolId}
      isTransacting={!!transaction}
    />
  );
};

export default SupplyActionButton;
