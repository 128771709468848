import React from "react";
import Typography from "@mui/material/Typography";
import { ActionButton, BaseFlex, SpinnerTwo } from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import InputSliderPair from "@components/common/InputSliderPair/InputSliderPair";
import { useFormContext } from "react-hook-form";
import { TokenI } from "@lib/types";

export interface WithdrawTransactionCardProps extends Pick<TransactionCardBaseProps, "fullScreen"> {
  inputName: string;
  sliderName: string;
  loading: boolean;
  totalAvailable: string;
  activeLiquidity: string;
  pendingDeposit: string;
  pendingWithdrawal: string;
  poolName: string;
  poolUrl: string;
  debtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
  willBeActiveIn: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const SignalWithdraw = ({
  inputName,
  sliderName,
  loading,
  totalAvailable,
  activeLiquidity,
  pendingDeposit,
  pendingWithdrawal,
  poolName,
  // poolUrl,
  debtToken,
  willBeActiveIn,
  isSubmitting,
  onSubmit,
  isValid,
  fullScreen = false,
}: WithdrawTransactionCardProps): JSX.Element => {
  const { watch, handleSubmit, formState } = useFormContext();

  return (
    <BaseFlex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: "2em",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <BaseFlex
        sx={{
          flexDirection: "column",
          width: "100%",
          marginTop: "0.5em",
          alignItems: "flex-start",
          color: (theme) => theme.palette.common.white,
        }}
      >
        {/* <MuiLink href={poolUrl} rel="noopener noreferrer" target="_blank" sx={{ color: "white" }}> */}
        <BaseFlex
          sx={{
            display: "flex",
            gap: "0.5em",
            marginBottom: "1em",
          }}
        >
          <Typography variant="headline2">{poolName}</Typography>

          {/* <div style={{ height: "19px", width: "19px" }}>
            <ExternalLinkIcon />
          </div> */}
        </BaseFlex>
        {/* </MuiLink> */}

        <DetailsWrapper
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.primary[600]}`,
            paddingBottom: "1rem",
          }}
        >
          <Detail
            label="Active Liquidity"
            detail={`${commaSeparateNumbers(truncateDecimal(activeLiquidity))} ${debtToken.symbol}`}
            loading={loading}
          />

          <Detail
            label="Pending deposit"
            detail={`${commaSeparateNumbers(truncateDecimal(pendingDeposit))} ${debtToken.symbol} `}
            loading={loading}
          />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ flexDirection: "column", width: "100%" }}>
        <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
          <Typography
            variant="headline2"
            component="span"
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            Withdraw Amount
          </Typography>

          <BaseFlex
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "0",
            }}
          >
            <Typography variant="caption2" component="span">
              Total Available:
            </Typography>
            <Typography
              variant="caption1"
              component="span"
              sx={{ color: "white", marginLeft: "0.5em" }}
            >
              {loading || !totalAvailable
                ? "-"
                : `${commaSeparateNumbers(truncateDecimal(totalAvailable))}
                ${debtToken.symbol}`}
            </Typography>
          </BaseFlex>
        </BaseFlex>

        <InputSliderPair
          inputName={inputName}
          logo={debtToken.logo}
          sliderName={sliderName}
          max={totalAvailable}
          conversionRate={debtToken.price}
          scheme="secondary"
        />
      </BaseFlex>

      <BaseFlex
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1em",
          marginTop: fullScreen ? "-1em" : 0,
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            paddingBottom: "1em",
          }}
        >
          New position
        </Typography>
        <DetailsWrapper>
          <Detail
            label="Active Liquidity"
            detail={`${commaSeparateNumbers(truncateDecimal(activeLiquidity))} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail
            label="Pending withdrawal"
            detail={`${commaSeparateNumbers(
              truncateDecimal(
                (parseFloat(watch(inputName) || "0") + parseFloat(pendingWithdrawal)).toString()
              )
            )} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail label="Pending deposit" detail={`0.00 ${debtToken.symbol}`} loading={loading} />
          <Detail label="Will Be Available In" detail={willBeActiveIn} loading={loading} />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ width: "100%" }}>
        <ActionButton
          scheme="secondary"
          disabled={!isValid || isSubmitting || loading || !formState.isValid}
          pendingAction={isSubmitting}
          type="submit"
          fullWidth
        >
          {isSubmitting && <SpinnerTwo />}
          {isSubmitting ? "Signalling" : "Signal Withdraw"}
        </ActionButton>
      </BaseFlex>
    </BaseFlex>
  );
};

export default SignalWithdraw;
