import React from "react";
import NextHead from "next/head";

const Head = (): JSX.Element => {
  return (
    <NextHead>
      <title>Cora.</title>
      <meta name="description" content="Interest free lending, without liquidations" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />

      <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <meta name="description" content="Interest free lending, without liquidations" />

      <meta property="og:url" content="https://cora.money/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cora." />
      <meta property="og:description" content="Decentralized Lending Protocol" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/cora-protocol/image/upload/v1669915404/OG%20assets/linkedin_post_1_tvbfer.png"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="cora.money" />
      <meta property="twitter:url" content="https://cora.money/" />
      <meta name="twitter:title" content="Cora" />
      <meta name="twitter:description" content="Decentralized Lending Protocol" />
      <meta
        name="twitter:image"
        content="https://res.cloudinary.com/cora-protocol/image/upload/v1669915404/OG%20assets/linkedin_post_1_tvbfer.png"
      />
    </NextHead>
  );
};

export default Head;
