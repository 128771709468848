import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ActionButton, BaseFlex, SpinnerTwo } from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import InputSliderPair from "@components/common/InputSliderPair/InputSliderPair";
import { useFormContext } from "react-hook-form";
import { TokenI } from "@lib/types";

export interface SupplyBasicTransactionCardProps
  extends Pick<TransactionCardBaseProps, "fullScreen"> {
  inputName: string;
  sliderName: string;
  loading: boolean;
  walletBalance: string;
  activeLiquidity: string;
  pendingDeposit: string;
  poolName: string;
  poolUrl: string;
  debtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
  minAmountToDeposit: number;
  willBeActiveIn: string;
  approveButtonIsDisabled: boolean;
  approveButtonOnClick: () => void;
  isApproving: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const SupplyBasic = ({
  inputName,
  sliderName,
  loading,
  walletBalance,
  activeLiquidity,
  pendingDeposit,
  poolName,
  // poolUrl,
  debtToken,
  minAmountToDeposit,
  willBeActiveIn,
  approveButtonIsDisabled,
  approveButtonOnClick,
  isApproving,
  onSubmit,
  isSubmitting,
  isValid,
  fullScreen = false,
}: SupplyBasicTransactionCardProps): JSX.Element => {
  const { watch, handleSubmit, formState } = useFormContext();
  const { isValid: formStateIsValid } = formState;

  return (
    <BaseFlex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: "2em",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <BaseFlex
        sx={{
          flexDirection: "column",
          width: "100%",
          marginTop: "0.5em",
          alignItems: "flex-start",
          color: (theme) => theme.palette.common.white,
        }}
      >
        {/* <MuiLink href={poolUrl} rel="noopener noreferrer" target="_blank" sx={{ color: "white" }}> */}
        <BaseFlex
          sx={{
            display: "flex",
            gap: "0.5em",
            marginBottom: "1em",
          }}
        >
          <Typography variant="headline2">{poolName}</Typography>

          {/* <div style={{ height: "19px", width: "19px" }}>
            <ExternalLinkIcon />
          </div> */}
        </BaseFlex>
        {/* </MuiLink> */}

        <DetailsWrapper
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.primary[600]}`,
            paddingBottom: "1rem",
          }}
        >
          <Detail
            label="Active Liquidity"
            detail={`${commaSeparateNumbers(truncateDecimal(activeLiquidity))} ${debtToken.symbol}`}
            loading={loading}
          />

          <Detail
            label="Pending deposit"
            detail={`${commaSeparateNumbers(truncateDecimal(pendingDeposit))} ${debtToken.symbol} `}
            loading={loading}
          />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ flexDirection: "column", width: "100%" }}>
        <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
          <Box
            component="span"
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            <Typography
              variant="headline2"
              component="span"
              sx={{
                color: (theme) => theme.palette.common.white,
                whiteSpace: "pre-wrap",
              }}
            >
              Supply Amount
            </Typography>
          </Box>

          <BaseFlex
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "0",
            }}
          >
            <Typography
              variant="caption2"
              component="span"
              sx={{ color: (theme) => theme.palette.primary[300] }}
            >
              Wallet Balance:
            </Typography>
            <Typography
              variant="caption1"
              data-test="wallet-balance"
              component="span"
              sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
            >
              {loading || !walletBalance
                ? "-"
                : `${commaSeparateNumbers(truncateDecimal(walletBalance))}
                ${debtToken.symbol}`}
            </Typography>
          </BaseFlex>
        </BaseFlex>

        <InputSliderPair
          inputName={inputName}
          logo={debtToken.logo}
          disabled={loading}
          sliderName={sliderName}
          max={walletBalance}
          conversionRate={debtToken.price}
          moreValidationRules={{
            min: (value: number) => {
              if (value < minAmountToDeposit) {
                return `Minimum supply amount: ${minAmountToDeposit} ${debtToken.symbol}.`;
              }
            },
          }}
        />
      </BaseFlex>

      <BaseFlex
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1em",
          marginTop: fullScreen ? "-1em" : 0,
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            paddingBottom: "1em",
          }}
        >
          New position
        </Typography>
        <DetailsWrapper>
          <Detail
            label="Active Liquidity"
            detail={`${commaSeparateNumbers(truncateDecimal(activeLiquidity))} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail
            label="Pending deposit"
            detail={`${commaSeparateNumbers(
              truncateDecimal(
                (parseFloat(watch(inputName) || "0") + parseFloat(pendingDeposit)).toString()
              )
            )} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail
            label="Pending Withdrawal"
            detail={`0.00 ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail label="Will Be Active In" detail={willBeActiveIn} loading={loading} />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ width: "100%", flexDirection: "column" }}>
        <BaseFlex sx={{ width: "100%" }}>
          <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
            <ActionButton
              data-test="approve-button"
              onClick={approveButtonOnClick}
              disabled={isApproving || approveButtonIsDisabled || loading}
              pendingAction={isApproving}
            >
              {isApproving && <SpinnerTwo />}
              {isApproving ? "Approving" : "Approve"}
            </ActionButton>
          </BaseFlex>

          <ActionButton
            type="submit"
            data-test="supply-button"
            disabled={!isValid || isSubmitting || loading || !formStateIsValid}
            pendingAction={isSubmitting}
          >
            {isSubmitting ? "Supplying" : "Supply"}
          </ActionButton>
        </BaseFlex>
        {!approveButtonIsDisabled && !loading && (
          <BaseFlex sx={{ fontSize: "13px", width: "100%", justifyContent: "left" }}>
            Allow the Cora Protocol to use your token on your behalf.
          </BaseFlex>
        )}
      </BaseFlex>
    </BaseFlex>
  );
};

export default SupplyBasic;
