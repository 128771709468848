import React, { Dispatch, SetStateAction } from "react";
import Toolbar from "@mui/material/Toolbar";
import { DrawerPaper, DrawerLink, BaseFlex, ChainMenu } from "@components/common";
import { externalLinks, pathNames } from "@lib/constants";
import { networksIncludedInChainMenu } from "@lib/connectors";

interface DrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

// NOTE: wrap/unwrap page is removed for v1
const Drawer = ({ open, setOpen }: DrawerProps): JSX.Element => {
  //   The following properties are used in this documentation website for optimal usability of the component:
  // iOS is hosted on high-end devices. The backdrop transition can be enabled without dropping frames. The performance will be good enough.
  // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <DrawerPaper
        anchor="left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <BaseFlex sx={{ flexDirection: "column", gap: "1.5em" }}>
          <Toolbar />
          <DrawerLink
            label="Supply"
            url={pathNames.supply}
            onClick={() => setOpen(false)}
            external={false}
          />
          <DrawerLink
            label="Borrow"
            url={pathNames.borrow}
            onClick={() => setOpen(false)}
            external={false}
          />
          <DrawerLink
            label="Portfolio"
            url={pathNames.portfolio}
            onClick={() => setOpen(false)}
            external={false}
          />
          {/* <DrawerLink
            label="Wrap / unwrap eth"
            url={pathNames.wrapUnwrap}
            onClick={() => setOpen(false)}
            external={false}
          /> */}
          <DrawerLink
            label="Documentation"
            url={externalLinks.DOCS}
            onClick={() => setOpen(false)}
          />
          <DrawerLink label="Github" url={externalLinks.GITHUB} onClick={() => setOpen(false)} />
          <DrawerLink label="Audit" url={externalLinks.AUDIT} onClick={() => setOpen(false)} />
          <DrawerLink label="Discord" url={externalLinks.DISCORD} onClick={() => setOpen(false)} />
          <DrawerLink label="Medium" url={externalLinks.MEDIUM} onClick={() => setOpen(false)} />
          <DrawerLink label="Twitter" url={externalLinks.TWITTER} onClick={() => setOpen(false)} />
          <Toolbar />

          <BaseFlex
            sx={{
              position: "fixed",
              bottom: "1em",
              right: "1em",
              borderRadius: "0.25em",
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
          >
            <ChainMenu options={networksIncludedInChainMenu} />
          </BaseFlex>
        </BaseFlex>
      </DrawerPaper>
    </>
  );
};

export default Drawer;
