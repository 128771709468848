import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex, Search } from "@components/common";
import { ColumnHeader, TableContainer } from "@components/common/Table/TableComponents";
import { LineGrid, Message } from "@components/common/Table/Collateral/CollateralComponents";
import { Wrapper } from "@components/common/Table/Pool/PoolComponents";

const CollateralTable = (): JSX.Element => {
  return (
    <TableContainer sx={{ backgroundColor: "transparent", padding: 0 }}>
      <LineGrid
        sx={{
          marginBottom: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          paddingBottom: "1em",
        }}
      >
        <BaseFlex sx={{ gridColumn: "collateral / action" }}>
          <Search
            inputPlaceholder="Search collateral"
            inputValue={""}
            onInputChange={() => undefined}
          />
        </BaseFlex>
      </LineGrid>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid className="lineGrid">
            <ColumnHeader
              sx={{ padding: "1.6875em 0", gridArea: "collateral", justifyContent: "start" }}
            >
              Collateral
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "stablecoin" }}>
              Stablecoin
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "borrowingFee" }}>
              Borrowing Fee
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "liquidity" }}>
              Liquidity
            </ColumnHeader>
            <ColumnHeader sx={{ padding: "1.6875em 0", gridArea: "ltv" }}>LTV</ColumnHeader>
            <ColumnHeader
              sx={{
                padding: "1.6875em 0",
                gridArea: "wallet",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Wallet
            </ColumnHeader>
            <ColumnHeader
              sx={{
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          <Message message="An error occured" />
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default CollateralTable;
