import React from "react";
import Box from "@mui/material/Box";
import { toggleButtonClasses } from "@mui/material/ToggleButton";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  PeriodTogglerButton,
  PeriodTogglerGroup,
  PeriodToggleLoader,
} from "./PeriodSelectionComponents";
import { BaseFlex, HintTooltip } from "@components/common";
import { ExclamationIcon } from "../Icons";
import { isLoanTermAllowable } from "@lib/utils";
import { fromSecondsToDays } from "@lib/utils/convertTime";

export interface PeriodSelectionProps {
  periods: Array<number> | undefined;
  value: number;
  nextRoundTimeStamp: number;
  nextRoundTimestampReadable: string;
  onChange: (e: any, value: number) => void;
  error?: string;
}

const PeriodSelection = ({
  periods,
  nextRoundTimeStamp,
  nextRoundTimestampReadable,
  value,
  onChange,
  error,
}: PeriodSelectionProps): JSX.Element => {
  if (!periods) {
    return <PeriodToggleLoader />;
  }

  return (
    <BaseFlex sx={{ flexDirection: "column", width: "100%", gap: 0, alignItems: "start" }}>
      <PeriodTogglerGroup
        value={value}
        onChange={onChange}
        exclusive
        fullWidth
        sx={{ outline: (theme) => (error ? `1px solid ${theme.palette.error[300]}` : "none") }}
      >
        {periods.map((period) => {
          return isLoanTermAllowable(period, nextRoundTimeStamp) ? (
            <PeriodTogglerButton
              data-test={`loanTerm-${period}`}
              key={period}
              value={period}
              error={!!error}
            >
              {fromSecondsToDays(period)}
            </PeriodTogglerButton>
          ) : (
            <HintTooltip
              title={`This loan term will be available ${nextRoundTimestampReadable}.`}
              data-test={`loanTerm-not-allowed-${period}`}
              key={period}
              placement="top"
              sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                  top: "0px",
                },
              }}
            >
              <Box component="span" className="not-allowed-term">
                <PeriodTogglerButton
                  data-test={`loanTerm-${period}`}
                  key={period}
                  value={period}
                  disabled
                  sx={{
                    [`&.${toggleButtonClasses.disabled}`]: {
                      pointerEvents: "auto",
                    },
                  }}
                >
                  {fromSecondsToDays(period)}
                </PeriodTogglerButton>
              </Box>
            </HintTooltip>
          );
        })}
      </PeriodTogglerGroup>
      {error && (
        <BaseFlex
          role="alert"
          sx={(theme) => ({
            ...theme.typography.caption1,
            color: (theme) => theme.palette.error[300],
            padding: "6px 0",
            justifyContent: "flex-start",
            textDecoration: "italic",
            gap: 0,
          })}
        >
          <ExclamationIcon sx={{ color: (theme) => theme.palette.error[300] }} />
          {error}
        </BaseFlex>
      )}
    </BaseFlex>
  );
};

export default PeriodSelection;
