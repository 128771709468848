import React, { useEffect, useState } from "react";
import { parseUnits } from "ethers/lib/utils";
import { FormProvider, useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SignalWithdrawTransactionCard } from "@components/common";
import { useWeb3, usePool, useSignalWithdraw } from "@lib/hooks";
import { useLPPositionsStore } from "@lib/providers";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";
import { TokenI, TransactionStatus, TransactionType } from "@lib/types";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { BigNumber } from "ethers";

type Props = {
  poolAddress: string;
};

const INPUT_NAME = "withdrawAmount";
const SLIDER_NAME = "withdrawSlider";

const SignalWithdraw = ({ poolAddress }: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [statusSync, setStatusSync] = useState<TransactionStatus | "form">("form");
  const methods = useForm({
    defaultValues: { [INPUT_NAME]: "", [SLIDER_NAME]: 0 },
    mode: "onChange",
  });

  const position = useLPPositionsStore((state) => state.positions[poolAddress]);

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useModalManager();

  const { lendingPool, loading } = usePool(poolAddress);

  const {
    signalWithdraw,
    transactionStatus: withdrawStatus,
    error: withdrawError,
    response: withdrawResponse,
  } = useSignalWithdraw(poolAddress);

  const handleSubmit = () => {
    signalWithdraw(
      parseUnits(methods.getValues(INPUT_NAME), lendingPool?.debtToken.decimals).add(
        position?.pendingDeposit.amount || BigNumber.from(0)
      ),
      {
        poolName: lendingPool?.name as string,
        debtToken: {
          id: lendingPool?.debtToken.id as string,
          symbol: lendingPool?.debtToken.symbol as string,
          amount: methods.getValues(INPUT_NAME),
          amountUnitPriceInUSD: position?.poolDetails.debtToken.priceInUSD,
        },
      }
    );
  };

  useEffect(() => {
    if (withdrawStatus) {
      setStatusSync(withdrawStatus);
    }
  }, [withdrawStatus]);

  return statusSync === "form" ? (
    <FormProvider {...methods}>
      <SignalWithdrawTransactionCard
        inputName={INPUT_NAME}
        sliderName={SLIDER_NAME}
        poolName={lendingPool?.name as string}
        loading={
          loading ||
          !position?.pendingDeposit.formattedAmount ||
          !position?.activeLiquidity.formattedAmount ||
          !position?.totalLiquidityThatCanBeSignaledForWithdrawal.formattedAmount
        }
        totalAvailable={
          position?.totalLiquidityThatCanBeSignaledForWithdrawal.formattedAmount as string
        }
        activeLiquidity={position?.activeLiquidity.formattedAmount as string}
        pendingDeposit={position?.pendingDeposit.formattedAmount as string}
        pendingWithdrawal={position?.pendingWithdrawal.formattedAmount as string}
        poolUrl="#"
        willBeActiveIn={lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string}
        debtToken={{
          ...(lendingPool?.debtToken as TokenI),
          logo: lendingPool?.stableCoinTokenlogoUrl as string,
          price: position?.poolDetails.debtToken.priceInUSD,
        }}
        onSubmit={handleSubmit}
        isSubmitting={withdrawStatus === "confirm" || withdrawStatus === "submitted"}
        isValid={active}
        fullScreen={fullScreen}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.SignalWithdraw}
      status={withdrawStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setStatusSync("form")}
      transactionHash={withdrawResponse?.hash as string}
      errorMessage={withdrawError}
      confirmMessage={`Signaling the pool to withdraw the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(INPUT_NAME))
      )} ${lendingPool?.debtToken.symbol} from the ${lendingPool?.name} pool.`}
    />
  );
};

export default SignalWithdraw;
