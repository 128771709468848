/**
 * converts unix time to readable format
 */

interface Converted {
  monthDay: string;
  monthDayYear: string;
  monthDayTime: string;
  monthDayTime_short: string;
  full: string;
}

const convertTime = (unixTime: number): Converted => {
  return {
    monthDay: new Date(unixTime * 1000).toLocaleString("en-us", { month: "short", day: "numeric" }),
    monthDayTime: new Date(unixTime * 1000).toLocaleString("en-us", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }),
    monthDayTime_short: new Date(unixTime * 1000).toLocaleString("en-us", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    monthDayYear: new Date(unixTime * 1000).toLocaleString("en-us", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }),
    full: new Date(unixTime * 1000).toString(),
  };
};

export default convertTime;

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_DAY = 86400;

export const fromSecondsToMinutes = (seconds: number | string): string => {
  const value =
    typeof seconds === "string"
      ? parseInt(seconds) / SECONDS_IN_MINUTE
      : seconds / SECONDS_IN_MINUTE;
  return `${value} ${value > 1 ? "mins" : "min"}`;
};

export const fromSecondsToDays = (seconds: number | string): string => {
  const value =
    typeof seconds === "string" ? parseInt(seconds) / SECONDS_IN_DAY : seconds / SECONDS_IN_DAY;
  return `${value} ${value > 1 ? "days" : "day"}`;
};
