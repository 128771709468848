import React, { useState } from "react";
import {
  CollateralTable,
  CollateralColumns,
  SortDirectionAsc,
  TABLE_LOADER_MIN_DURATION,
} from "@components/common/Table";
import { useDebounce, usePools, useWeb3 } from "@lib/hooks";

const Collateral = (): JSX.Element => {
  const { active } = useWeb3();
  const { lendingPools, loading, error } = usePools();
  const debouncedLoading = useDebounce(loading, TABLE_LOADER_MIN_DURATION);

  const [sortedColumn, setSortedColumn] = useState<CollateralColumns>(
    CollateralColumns.BORROWING_FEE
  );
  const [sortDirection, setSortDirection] = useState<SortDirectionAsc | undefined>(undefined);

  // const [pools, setPools] = useState<LendingPoolsParsed>([]);

  // useEffect(() => {
  //   if (!data) {
  //     return;
  //   }

  //   // parse pools data
  //   const parsedLendingPools = data.lendingPools.map((pool) => {
  //     return {
  //       ...pool,
  //       collateralName: pool.collateralToken.name,
  //       stablecoinName: pool.debtToken.name,
  //     };
  //   });

  //   // copy the list before sorting so there will be no mutation in the state
  //   // TODO: fix sorting when the protocol has multiple pools
  //   const list = [...parsedLendingPools].sort((a, b) =>
  //     a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * -1 : sortDirection ? -1 : 1
  //   );

  //   setPools(list);
  // }, [data, sortedColumn, sortDirection]);

  return (
    <CollateralTable
      loading={debouncedLoading}
      error={!!error}
      borrowableDataList={lendingPools}
      onCollateralClick={() => {
        setSortedColumn(CollateralColumns.COLLATERAL);
        setSortDirection(sortedColumn !== CollateralColumns.COLLATERAL ? true : !sortDirection);
      }}
      onStablecoinClick={() => {
        setSortedColumn(CollateralColumns.STABLECOIN);
        setSortDirection(sortedColumn !== CollateralColumns.STABLECOIN ? true : !sortDirection);
      }}
      onBorrowingFeeClick={() => {
        setSortedColumn(CollateralColumns.BORROWING_FEE);
        setSortDirection(sortedColumn !== CollateralColumns.BORROWING_FEE ? true : !sortDirection);
      }}
      onLiquidityClick={() => {
        setSortedColumn(CollateralColumns.LIQUIDITY);
        setSortDirection(sortedColumn !== CollateralColumns.LIQUIDITY ? true : !sortDirection);
      }}
      onLTVClick={() => {
        setSortedColumn(CollateralColumns.LTV);
        setSortDirection(sortedColumn !== CollateralColumns.LTV ? true : !sortDirection);
      }}
      sortedColumn={sortedColumn}
      sortDirection={sortDirection}
      connected={active}
    />
  );
};

export default Collateral;
