import { styled } from "@mui/material";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";
import { BaseButton } from "@components/common";
import { hexToRgba } from "@lib/utils";

export const TableContainer = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: hexToRgba(theme.palette.primary.main, 0.4),
  padding: "1.6875rem 1.6875rem",
  borderRadius: "1rem",
}));

export const ColumnHeader = styled("div")(({ theme }) => ({
  ...theme.typography.caption2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  userSelect: "none",
  textAlign: "center",
  padding: "1rem 0",

  "&:hover": {
    cursor: "pointer",
    opacity: 0.6,
  },
}));

export const CellData = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.common.white,
}));

export const LogoWrapper = styled("div")({
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  borderRadius: "9999px",
});

export const CellLoader = styled((props: SkeletonProps) => (
  <Skeleton data-test="CellLoader" variant="text" {...props} />
))(({ theme }) => ({
  backgroundColor: hexToRgba(theme.palette.common.white, 0.3),
  width: "100%",
}));

export interface RowActionButtonProps {
  name: string;
  handleClick: () => void;
  disabled?: boolean;
  dataTestId?: string;
}
export const RowActionButton = ({
  name,
  handleClick,
  disabled,
  dataTestId,
}: RowActionButtonProps): JSX.Element => {
  return (
    <BaseButton
      data-test={dataTestId}
      onClick={handleClick}
      disabled={disabled}
      sx={({ typography, palette }) => ({
        ...typography.buttonM,
        borderRadius: "0.5rem",
        padding: "0.3rem 1rem",
        margin: "-0.5rem 0 -0.5rem 1rem",
        backgroundColor: palette.secondary.main,
        color: palette.dark.main,
        minWidth: "0px",

        "&:disabled": {
          backgroundColor: hexToRgba(palette.secondary.main, 0.2),
          color: (theme) => hexToRgba(theme.palette.dark.main, 0.4),
        },

        "&:hover": {
          backgroundColor: palette.secondary[600],
        },
      })}
    >
      {name}
    </BaseButton>
  );
};

/**
 * ascending sort direction
 */
export type SortDirectionAsc = boolean;

/**in milliseconds */
export const TABLE_LOADER_MIN_DURATION = 500;
