export const white = "#FFFFFF";
export const lightBlue = "#17C9FF";
export const lime = "#C1FF90";
export const dark = "#010E30";

export const gradient1 = "linear-gradient(135deg, #8CFF63 0%, #94F8F2 51.21%, #8459FF 100%)";
export const gradient2 = "linear-gradient(135deg, #B883FF 0%, #A6BFD3 51.21%, #93FFA4 100%)";
export const gradient3 = "linear-gradient(135deg, #EAFAFF 0%, #A1E9FF 51.21%, #53AFFF 100%)";
export const gradient3p2 = "linear-gradient(135deg, #FFFFFF 0%, #A1E9FF 51.21%, #DAE6FF 100%)";
export const gradient4 = "linear-gradient(135deg, #63DAFF 0%, #7FC4FF 51.21%, #5991FF 100%)";
export const gradientBG = "linear-gradient(98.8deg, #0030B7 42.2%, #2289F2 99.49%)";
export const colorBG = "#0030B7";

export const mint = {
  [100]: "#EFFFFD",
  [200]: "#E3FFFC",
  [300]: "#D1FFFA",
  [400]: "#AEFFF5",
  main: "#66F4E3",
  [500]: "#66F4E3",
  [600]: "#4ED3C3",
  [700]: "#3EB4A5",
  [800]: "#2A8378",
  [900]: "#194B45",
  [1000]: "#0C2825",
};

export const blue = {
  [100]: "#EEF4FF",
  [200]: "#DAE6FF",
  [300]: "#B6CEFF",
  [400]: "#7DA9FF",
  main: "#4080FF",
  [500]: "#4080FF",
  [600]: "#1C5BD9",
  [700]: "#0D40A7",
  [800]: "#0E2C67",
  [900]: "#0A1835",
  [1000]: "#060D1B",
};

export const yellow = {
  [100]: "#FFEFBD",
  [200]: "#FCE7A2",
  [300]: "#FAE087",
  [400]: "#F7D96C",
  main: "#F4D151",
  [500]: "#F4D151",
  [600]: "#C3A746",
  [700]: "#937E3A",
  [800]: "#62542F",
  [900]: "#312A23",
  [1000]: "#222013",
};

export const green = {
  [100]: "#D7FFE8",
  [200]: "#B5FCD5",
  [300]: "#94FAC2",
  [400]: "#72F7AF",
  main: "#51F49C",
  [500]: "#51F49C",
  [600]: "#44C381",
  [700]: "#389165",
  [800]: "#2B604A",
  [900]: "#1E2E2E",
  [1000]: "#151F1F",
};

export const red = {
  [100]: "#FFDCE9",
  [200]: "#FFB3CB",
  [300]: "#FF8BAE",
  [400]: "#FF6290",
  main: "#FF3972",
  [500]: "#FF3972",
  [600]: "#D33262",
  [700]: "#A82A52",
  [800]: "#7D2341",
  [900]: "#511B31",
  [1000]: "#2C0E1A",
};
