import React, { Fragment, useState } from "react";
import { UnsupportedChainIdError } from "@web3-react/core";
import { toast } from "react-toastify";
import {
  AccountButton,
  AccountDropdown,
  ConnectWallet,
  UnsupportedNetwork,
} from "@components/common";
import { useWeb3, useAllTransactions, usePendingTransactions } from "@lib/hooks";
import { connectWalletHandler, getExplorerLink, HashType } from "@lib/utils";
import { useTransactionStore } from "@lib/providers";

// this component will be wrapped by a context provider
// it will query for for the AccountButtonProps
const AccountNavButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { active, activate, deactivate, account, error, chainId } = useWeb3();
  const open = Boolean(anchorEl);

  const pendingTransactions = usePendingTransactions();
  const transactions = useAllTransactions();
  const clearTransactions = useTransactionStore((state) => state.clearTransactions);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConnectWalletClick = () => {
    connectWalletHandler({ activate })
      .then(() => {
        toast.success("Successfully connected wallet!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  if (!active) {
    if (error instanceof UnsupportedChainIdError) {
      return <UnsupportedNetwork />;
    }
    return <ConnectWallet onClick={handleConnectWalletClick} />;
  }

  return (
    <Fragment>
      <AccountButton
        onClick={handleClick}
        address={account as string}
        pendingTransactions={pendingTransactions.length}
        dropdownIsOpen={open}
      />

      <AccountDropdown
        address={account as string}
        explorerLink={getExplorerLink(chainId as number, account as string, HashType.ADDRESS)}
        menuProps={{
          anchorEl: anchorEl,
          open: open,
          onClose: handleClose,
        }}
        transactions={transactions}
        clearTransactions={() =>
          clearTransactions({ chainId: chainId as number, account: account as string })
        }
        onLogoutClick={() => {
          deactivate();
          handleClose();
        }}
      />
    </Fragment>
  );
};

export default AccountNavButton;
