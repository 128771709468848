import { styled } from "@mui/material";

export const TopContainer = styled("div")({
  width: "100%",
  display: "flex",
  gap: "1em",
  alignItems: "center",
});

export const BottomContainer = styled("div")(({ theme }) => ({
  ...theme.typography.caption1,
  width: "100%",
  display: "flex",
  gap: "0.5em",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  padding: "1em 0.5em",
  // textTransform: "uppercase",
  backgroundColor: theme.palette.primary[600],
}));

export const DataContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.6em",
});
