import {
  TransactionDetailBase,
  WithdrawCollateralTransactionInfo,
} from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingWithdrawCollateralTransactionByPool = (
  poolId: string
): TransactionDetailBase<WithdrawCollateralTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter(
      (transaction) => transaction.transactionInfo.type === TransactionType.WithdrawCollateral
    )
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (
        withdrawCollateral
      ): withdrawCollateral is TransactionDetailBase<WithdrawCollateralTransactionInfo> =>
        !withdrawCollateral.isMined
    )
    .filter((withdrawCollateral) => withdrawCollateral.transactionInfo.lendingPoolId === poolId)[0];
};

export const usePendingWithdrawCollateralTransactions =
  (): TransactionDetailBase<WithdrawCollateralTransactionInfo>[] => {
    const transactions = useAllTransactions();

    return transactions
      .filter(
        (transaction) => transaction.transactionInfo.type === TransactionType.WithdrawCollateral
      )
      .filter((transaction) => !transaction.error)
      .filter((transaction) => !transaction.isCancelled)
      .filter(
        (
          withdrawCollateral
        ): withdrawCollateral is TransactionDetailBase<WithdrawCollateralTransactionInfo> =>
          !withdrawCollateral.isMined
      );
  };
