import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const MenuIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      // width="24"
      // height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.72045 20.25H1.5V19.9634L3.99201 16.8774V6.77866L1.80671 4.03662V3.75H7.78754L12.5224 14.0398L16.6438 3.75H22.5V4.03662L20.6118 5.89013V18.1672L22.385 19.9634V20.25H14.1613V19.9634L15.992 18.1672V8.39331L11.3051 20.25H10.6917L5.21885 8.39331V16.8774L7.72045 19.9634V20.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default MenuIcon;
