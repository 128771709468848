import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CancelPendingWithdrawalTransactionCard } from "@components/common";
import { useCancelWithdraw, usePool, useWeb3 } from "@lib/hooks";
import { useLPPositionsStore } from "@lib/providers";
import { TransactionStatus, TransactionType } from "@lib/types";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { parseUnits } from "ethers/lib/utils";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";

const INPUT_NAME = "cancelAmount";
const SLIDER_NAME = "sliderAmount";

const CancelPendingWithdrawal = ({ poolId }: { poolId: string }): JSX.Element => {
  const [supplyStatusSync, setSupplyStatusSync] = useState<TransactionStatus | "form">("form");

  const methods = useForm({
    defaultValues: { [INPUT_NAME]: "", [SLIDER_NAME]: 0 },
    mode: "onChange",
  });

  const { active, chainId } = useWeb3();
  const { pendingWithdrawal, activeLiquidity, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolId]
  );
  const { lendingPool, loading } = usePool(poolId);

  const { setOpen: setOpenParent } = useModalManager();

  const {
    deposit,
    transactionStatus: depositStatus,
    error: depositError,
    response: depositResponse,
  } = useCancelWithdraw(poolId);

  const handleSubmit = () => {
    deposit(parseUnits(methods.getValues(INPUT_NAME), poolDetails?.debtToken.decimals), {
      poolName: poolDetails?.name as string,
      debtoken: {
        id: poolDetails?.debtToken.id as string,
        symbol: poolDetails?.debtToken.symbol as string,
        amount: methods.getValues(INPUT_NAME),
        amountUnitPriceInUSD: poolDetails?.debtToken.priceInUSD as string,
      },
    });
  };

  useEffect(() => {
    if (depositStatus) {
      setSupplyStatusSync(depositStatus);
    }
  }, [depositStatus]);

  return supplyStatusSync === "form" ? (
    <FormProvider {...methods}>
      <CancelPendingWithdrawalTransactionCard
        inputName={INPUT_NAME}
        sliderName={SLIDER_NAME}
        loading={!pendingWithdrawal?.formattedAmount || loading}
        totalPendingAmount={pendingWithdrawal?.formattedAmount as string}
        activeLiquidity={activeLiquidity?.formattedAmount as string}
        willBeActiveIn={lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string}
        debtToken={{
          ...poolDetails.debtToken,
          price: poolDetails.debtToken.priceInUSD,
          logo: "/assets/tokenImages/usd-coin-usdc-logo.png",
        }}
        isSubmitting={depositStatus === "confirm" || depositStatus === "submitted"}
        onSubmit={handleSubmit}
        isValid={active}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.CancelWithdraw}
      status={depositStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setSupplyStatusSync("form")}
      transactionHash={depositResponse?.hash as string}
      errorMessage={depositError}
      confirmMessage={`Supplying the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(INPUT_NAME))
      )} ${poolDetails?.debtToken.symbol} to the ${poolDetails?.name} pool.`}
    />
  );
};

export default CancelPendingWithdrawal;
