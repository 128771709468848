import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { generateLPProjectionData } from "@lib/utils";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LPProjectionChartProps {
  depositValue: string;
  apy: number;
  aspectRatio?: number;
}

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const LPProjectionChart = ({
  depositValue,
  apy,
  aspectRatio = 2,
}: LPProjectionChartProps): JSX.Element => {
  const projection = useMemo(
    () => generateLPProjectionData(parseFloat(depositValue), apy),
    [depositValue, apy]
  );

  return (
    <Line
      data={{
        labels: projection.map((item) => item.month),
        datasets: [
          {
            data: projection.map((item) => item.value),
            borderColor: "#63DAFF",
            pointRadius: 2,
          },
        ],
      }}
      options={{
        aspectRatio,
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
            external: (context) => {
              // Tooltip Element
              const { chart, tooltip } = context;

              const tooltipEl = getOrCreateTooltip(chart);

              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set Text
              if (tooltip.body) {
                const titleLines = tooltip.title || []; // month
                const bodyLines = tooltip.body.map((b) => b.lines); // amount
                const month = titleLines[0];
                const amount = bodyLines[0][0];

                const customTooltipEl = document.createElement("div");
                const monthElement = document.createElement("span");
                monthElement.appendChild(document.createTextNode(`${month}    `));

                const amountElement = document.createElement("span");
                amountElement.appendChild(document.createTextNode(`≈ ${amount} cUSDC`));

                customTooltipEl.appendChild(monthElement);
                customTooltipEl.appendChild(amountElement);

                const tableRoot = tooltipEl.querySelector("table");

                // Remove old children
                while (tableRoot.firstChild) {
                  tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(customTooltipEl);
              }

              const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.minWidth = "150px";
              tooltipEl.style.left = positionX + tooltip.caretX + "px";
              tooltipEl.style.top = positionY + tooltip.caretY + 20 + "px";
              tooltipEl.style.padding =
                tooltip.options.padding + "px " + tooltip.options.padding + "px";
              tooltipEl.style.fontSize = "10px";
              tooltipEl.style.display = "flex";
              tooltipEl.style.justifyContent = "center";
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: "rgba(255, 255, 255, 1)",
            },
            ticks: {
              color: "rgba(255, 255, 255, 0.6)",
            },
          },
          y: {
            grid: {
              display: false,
              borderColor: "rgba(255, 255, 255, 1)",
            },
            ticks: {
              color: "rgba(255, 255, 255, 0.6)",
            },
          },
        },
      }}
    />
  );
};

export default LPProjectionChart;
