import { useEffect, useState } from "react";
import { useMultiCall, usePools, useWeb3 } from "@lib/hooks";
import { useBlockNumber } from "@lib/providers";
import { BigNumber } from "ethers";
import { UserLoan } from "@lib/types";

import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import { useUserLoanIds } from "@lib/hooks/useUserLoanIds";
import { reshapeLoanList } from "./reshapeLoanList";
import { UseMultiCallAggregateProps } from "../useMultiCall";

const callData = (loanIds: Record<string, Array<BigNumber>>): UseMultiCallAggregateProps => {
  return Object.keys(loanIds).flatMap((poolAddress) => {
    return loanIds[poolAddress].map((loanId) => ({
      target: poolAddress,
      abi: LendingPoolAbi,
      reference: loanId.toHexString(),
      method: "getLoan",
      args: [loanId],
    }));
  });
};

export type GetLoanReturn = Record<
  UserLoan["id"],
  {
    netDebt: BigNumber;
    borrowingFee: BigNumber;
    collateral: BigNumber;
    expirationTime: BigNumber;
    borrower: string;
    paid: boolean;
  }
>;

export interface UseUserLoanReturn {
  loading: boolean;
  error?: any;
  loans: Array<UserLoan> | undefined;
}

const useUserLoans = (borrower?: string): UseUserLoanReturn => {
  const { chainId } = useWeb3();
  const { lendingPools, loading: poolLoading } = usePools();

  const { data, call, loading: callLoading } = useMultiCall<GetLoanReturn>();

  const blockNumber = useBlockNumber();
  const {
    data: loanIds,
    error,
    loading: loanIdsLoading,
  } = useUserLoanIds(lendingPools.map((pool) => pool.id));
  const [userLoans, setUserLoans] = useState<Record<UserLoan["id"], UserLoan> | undefined>(
    undefined
  );

  useEffect(() => {
    if (poolLoading) return;
    if (loanIdsLoading) return;

    call(callData(loanIds));
  }, [blockNumber, loanIds, poolLoading, loanIdsLoading]);

  useEffect(() => {
    if (!chainId) return;

    if (error) {
      return;
    }
    if (data === undefined) return;
    if (!lendingPools[0]?.id) return;

    const parsedLoans = reshapeLoanList({
      // TODO rewrite this to use multiple pools
      loans: data,
      pool: {
        id: lendingPools[0].id,
        name: lendingPools[0].name,
        debtToken: lendingPools[0].debtToken,
        collateralToken: lendingPools[0].collateralToken,
        collateralTokenLogoUrl: lendingPools[0].collateralTokenLogoUrl,
        stableCoinTokenlogoUrl: lendingPools[0].stableCoinTokenlogoUrl,
        priceFeed: lendingPools[0].priceFeed,
      },
    });

    setUserLoans(parsedLoans);
  }, [JSON.stringify(data), JSON.stringify(lendingPools), error]);

  return {
    loans: userLoans
      ? Object.values(userLoans).filter((loan) => loan.borrower.toLowerCase() === borrower)
      : undefined,
    loading: callLoading,
    error,
  };
};

export default useUserLoans;
