import React from "react";
import { ActionButton, BaseFlex, SpinnerTwo } from "@components/common";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";
import { Detail, DetailsWrapper } from "../TransactionCardBase";

export interface WithdrawAvailableTransactionCardProps {
  loading: boolean;
  withdrawAvailable: string;
  debtToken: TokenI;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const WithdrawAvailable = ({
  withdrawAvailable,
  debtToken,
  loading,
  isSubmitting,
  onSubmit,
  isValid,
}: WithdrawAvailableTransactionCardProps): JSX.Element => {
  return (
    <BaseFlex
      as="form"
      sx={{
        padding: "4.25rem 2rem 1rem",
        flexDirection: "column",
        gap: "6.75rem",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <DetailsWrapper>
        <Detail
          label="To be withdrawn"
          detail={`${commaSeparateNumbers(truncateDecimal(withdrawAvailable))} ${debtToken.symbol}`}
          loading={loading}
        />
      </DetailsWrapper>

      <BaseFlex sx={{ width: "100%" }}>
        <ActionButton
          disabled={!isValid || isSubmitting || loading}
          pendingAction={isSubmitting}
          onClick={onSubmit}
          fullWidth
        >
          {isSubmitting && <SpinnerTwo />}
          {isSubmitting ? "Withdrawing" : "Withdraw"}
        </ActionButton>
      </BaseFlex>
    </BaseFlex>
  );
};

export default WithdrawAvailable;
