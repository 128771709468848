import React from "react";
import { TransitionGroup } from "react-transition-group";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";
import { BaseFlex, Spinner, ExternalLinkIcon } from "@components/common";
import { LineGrid } from "./BorrowedComponents";
import { CellData } from "../TableComponents";
import { useWeb3, usePendingBorrowTransactions } from "@lib/hooks";
import { getExplorerLink, HashType } from "@lib/utils";

export const Description = styled("div")({
  fontWeight: 600,
  "& span": {
    fontWeight: 400,
    opacity: 0.7,
  },
});

const PendingBorrowList = (): JSX.Element | null => {
  const { chainId } = useWeb3();
  const pendingBorrows = usePendingBorrowTransactions();

  if (pendingBorrows.length === 0) return null;

  return (
    <TransitionGroup component={null}>
      {pendingBorrows.map((borrow) => {
        return (
          <Collapse component="div" key={borrow.hash}>
            <LineGrid className="lineGrid" sx={{ alignItems: "center" }}>
              <CellData
                sx={{
                  gridArea: "1 / collateral / 1 / expiration",
                  justifyContent: "start",
                  textAlign: "left",
                }}
              >
                <BaseFlex sx={{ gap: "1rem" }}>
                  <Box component="span">
                    <Spinner />
                  </Box>

                  <Description>
                    <span> Borrowing </span>
                    {borrow.transactionInfo.transactedAmount?.amount} <span>using </span>
                    {borrow.transactionInfo.transactedAmountSecondary?.amount}
                    <span> as the loan collateral.</span>
                  </Description>
                </BaseFlex>
              </CellData>

              <CellData
                sx={{ gridArea: "action", justifyContent: "flex-end", whiteSpace: "nowrap" }}
              >
                <MuiLink
                  href={getExplorerLink(
                    chainId as number,
                    borrow?.hash as string,
                    HashType.TRANSACTION
                  )}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BaseFlex>
                    <Box
                      sx={{
                        height: "17px",
                        width: "17px",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      <ExternalLinkIcon />
                    </Box>
                    <Box
                      sx={{
                        color: "white",
                        opacity: 0.7,

                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      View on explorer
                    </Box>
                  </BaseFlex>
                </MuiLink>
              </CellData>
            </LineGrid>
          </Collapse>
        );
      })}
    </TransitionGroup>
  );
};

export default PendingBorrowList;
