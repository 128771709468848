import React, { useEffect, useMemo, useState } from "react";
import { BorrowedTable } from "@components/common/Table";
import {
  SortDirectionAsc,
  TABLE_LOADER_MIN_DURATION,
} from "@components/common/Table/TableComponents";
import { BorrowedColumns, BorrowedList } from "@components/common/Table";
import { useDebounce, useUserLoans, useWeb3 } from "@lib/hooks";

export type UserLiquidityUIProps = Pick<BorrowedList, "withLabel">;

export enum BORROWED_FILTER_OPTIONS {
  ALL = "All loans",
  UNPAID = "Unpaid",
  PAID = "Repaid",
  EXPIRED = "Expired",
}

const BorrowedAssetsUI = ({ withLabel }: UserLiquidityUIProps): JSX.Element => {
  const { account, active } = useWeb3();
  const [activeFilter, setActiveFilter] = useState<BORROWED_FILTER_OPTIONS>(
    BORROWED_FILTER_OPTIONS.UNPAID
  );

  const { loans, loading, error } = useUserLoans(account?.toLowerCase());
  const debouncedLoading = useDebounce(loading, TABLE_LOADER_MIN_DURATION);

  const [sortedColumn, setSortedColumn] = useState<BorrowedColumns>(BorrowedColumns.BORROWED);
  const [sortDirection, setSortDirection] = useState<SortDirectionAsc | undefined>(undefined);

  const [allTotalLoaned, setAllTotalLoaned] = useState<number>(0);

  useEffect(
    function addAllOpenLoans() {
      if (!loans) {
        setAllTotalLoaned(0);
        return;
      }

      const totalLoaned = loans
        .filter((loan) => !loan.isExpired)
        .filter((loan) => !loan.paid)
        .reduce((accumulator, current) => {
          return (
            accumulator +
            parseFloat(current.netDebt) * parseFloat(current.pool.debtToken.priceInUSD || "1")
          );
        }, 0);

      setAllTotalLoaned(totalLoaned);
    },
    [loans]
  );

  const filteredLoans = useMemo(() => {
    if (!loans) {
      return [];
    }

    return [...loans].filter((loan) => {
      if (activeFilter === BORROWED_FILTER_OPTIONS.ALL) {
        return true;
      } else if (activeFilter === BORROWED_FILTER_OPTIONS.UNPAID) {
        return !loan.paid;
      } else if (activeFilter === BORROWED_FILTER_OPTIONS.PAID) {
        return loan.paid;
      } else if (activeFilter === BORROWED_FILTER_OPTIONS.EXPIRED) {
        return loan.isExpired && !loan.paid;
      }
    });
  }, [JSON.stringify(loans), activeFilter]);

  const sortedBorrowedAssets = useMemo(() => {
    if (!filteredLoans.length) {
      return [];
    }

    return [...filteredLoans].sort((a, b) => {
      if (sortedColumn === BorrowedColumns.COLLATERAL) {
        return a.pool.collateralToken.name > b.pool.collateralToken.name
          ? (sortDirection ? -1 : 1) * -1
          : sortDirection
          ? -1
          : 1;
      } else {
        const aVal =
          typeof a[sortedColumn] === "string"
            ? parseFloat(a[sortedColumn] as string)
            : a[sortedColumn];
        const bVal =
          typeof b[sortedColumn] === "string"
            ? parseFloat(b[sortedColumn] as string)
            : b[sortedColumn];

        // do not sort if there is no value
        if (!aVal || !bVal) return 0;

        return aVal > bVal ? (sortDirection ? -1 : 1) * -1 : sortDirection ? -1 : 1;
      }
    });
  }, [filteredLoans, sortedColumn, sortDirection]);

  return (
    <BorrowedTable
      loading={debouncedLoading}
      error={!!error}
      withLabel={withLabel}
      // TODO:
      // the actual allTotalBorrowed will be done when it is clear
      // how to convert all the borrowed assets to USD
      // for now all loans are added in the useEffect above
      allTotalBorrowed={allTotalLoaned}
      borrowedList={sortedBorrowedAssets}
      collateralClick={() => {
        setSortedColumn(BorrowedColumns.COLLATERAL);
        setSortDirection(sortedColumn !== BorrowedColumns.COLLATERAL ? true : !sortDirection);
      }}
      lockedClick={() => {
        setSortedColumn(BorrowedColumns.LOCKED);
        setSortDirection(sortedColumn !== BorrowedColumns.LOCKED ? true : !sortDirection);
      }}
      borrowedClick={() => {
        setSortedColumn(BorrowedColumns.BORROWED);
        setSortDirection(sortedColumn !== BorrowedColumns.BORROWED ? true : !sortDirection);
      }}
      borrowingFeeClick={() => {
        setSortedColumn(BorrowedColumns.BORROWING_FEE);
        setSortDirection(sortedColumn !== BorrowedColumns.BORROWING_FEE ? true : !sortDirection);
      }}
      collRatioClick={() => {
        setSortedColumn(BorrowedColumns.COLLRATIO);
        setSortDirection(sortedColumn !== BorrowedColumns.COLLRATIO ? true : !sortDirection);
      }}
      expiratioClick={() => {
        setSortedColumn(BorrowedColumns.EXPIRATION);
        setSortDirection(sortedColumn !== BorrowedColumns.EXPIRATION ? true : !sortDirection);
      }}
      sortedColumn={sortedColumn}
      sortDirection={sortDirection}
      isUserActive={active}
      activeFilter={activeFilter}
      filterOptions={[
        BORROWED_FILTER_OPTIONS.ALL,
        BORROWED_FILTER_OPTIONS.UNPAID,
        BORROWED_FILTER_OPTIONS.PAID,
        BORROWED_FILTER_OPTIONS.EXPIRED,
      ]}
      setFilter={(option: BORROWED_FILTER_OPTIONS) => setActiveFilter(option)}
    />
  );
};

export default BorrowedAssetsUI;
