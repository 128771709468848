import { useCallback, useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { useBlockNumber } from "@lib/providers";
import { useWeb3 } from "@lib/hooks";

import { IPriceFeed } from "@coraprotocol/core/typechain";
import IPriceFeedAbi from "@coraprotocol/core/abis/IPriceFeed.json";
import { TokenI } from "@lib/types";
import { bigNumberToFloat } from "@lib/utils";

interface UsePriceFeedProps {
  priceFeedAddress: string;
  token?: Partial<Pick<TokenI, "id" | "decimals">>;
  refetch?: boolean;
}

interface FetchState {
  price?: {
    value: BigNumber;
    formatted: string;
  };
  loading: boolean;
  error?: {
    name: string;
    message: string;
  };
}

/**
 * from cora dev
 * https://github.com/Cora-Protocol/dev/blob/main/projects/protocol/contracts/price-feeds/ChainlinkPriceFeed.sol
 * https://github.com/Cora-Protocol/dev/blob/main/projects/protocol/contracts/price-feeds/FluxPriceFeed.sol
 *
 *  hook for fetching price feed
 *  @returns price in USD with 4 decimal places
 */
const usePriceFeed = ({
  priceFeedAddress,
  token,
  refetch = true,
}: UsePriceFeedProps): FetchState => {
  const { library } = useWeb3();
  const blockNumber = useBlockNumber();
  const [fetchState, setFetchState] = useState<FetchState>({ loading: true });

  const fetchPrice = useCallback(
    async ({
      tokenAddress,
      priceFeedAddress,
      formatDecimal = 18,
    }: {
      tokenAddress: string;
      priceFeedAddress: string;
      formatDecimal?: number;
    }) => {
      try {
        if (!isAddress(tokenAddress)) throw new Error("Invalid token address");
        if (!isAddress(priceFeedAddress)) throw new Error("Invalid price feed address");

        setFetchState((state) => ({ ...state, loading: !state.price?.value, error: undefined }));

        const priceFeedContract = new ethers.Contract(
          priceFeedAddress,
          IPriceFeedAbi,
          library
        ) as IPriceFeed;

        const value = await priceFeedContract.getAssetPrice(tokenAddress);

        setFetchState((state) => ({
          ...state,
          price: {
            value,
            formatted: bigNumberToFloat(value, formatDecimal).toFixed(4),
          },
          loading: false,
        }));
      } catch (error: any) {
        setFetchState({
          price: undefined,
          loading: false,
          error: {
            name: error?.name || "usePriceFeed error",
            message: error?.message || "Error while fetching price",
          },
        });
      }
    },
    [priceFeedAddress, library, token?.id]
  );

  useEffect(() => {
    if (!priceFeedAddress) return;
    if (!token?.id) return;
    if (!library) return;

    let cancelled = false;

    if (cancelled) return;

    fetchPrice({ tokenAddress: token.id, priceFeedAddress, formatDecimal: token.decimals });

    return () => {
      cancelled = true;
    };
  }, [priceFeedAddress, token?.id]);

  useEffect(() => {
    if (!refetch) return;
    if (!priceFeedAddress) return;
    if (!token?.id) return;
    if (!library) return;
    if (!blockNumber) return;

    fetchPrice({ tokenAddress: token.id, priceFeedAddress, formatDecimal: token.decimals });
  }, [blockNumber]);

  return fetchState;
};

export default usePriceFeed;
