import { TransactionType } from "@lib/types";

const descriptions: Record<TransactionType, { success: string; failure: string }> = {
  [TransactionType.Approve]: {
    success: "Approved",
    failure: "Failed to approve",
  },
  [TransactionType.Deposit]: {
    success: "Deposited",
    failure: "Failed to deposit",
  },
  [TransactionType.CancelDeposit]: {
    success: "Cancelled",
    failure: "Failed to cancel",
  },
  [TransactionType.SignalWithdraw]: {
    success: "Signaled to withdraw",
    failure: "Failed to withdraw",
  },
  [TransactionType.CancelWithdraw]: {
    success: "Cancelled the signal to withdraw",
    failure: "Failed to send a cancel signal to withdraw",
  },
  [TransactionType.WithdrawAvailable]: {
    success: "Withdrawn",
    failure: "Failed to withdraw",
  },
  [TransactionType.WithdrawCollateral]: {
    success: "Withdrawn",
    failure: "Failed to withdraw",
  },
  [TransactionType.Borrow]: {
    success: "Borrowed",
    failure: "Failed to borrow",
  },
  [TransactionType.Repay]: {
    success: "Repaid",
    failure: "Failed to repay",
  },
};

export const actionDescriptor = (transaction: TransactionType, success = true): string => {
  return descriptions[transaction][success ? "success" : "failure"];
};
