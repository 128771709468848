import React from "react";
import { useRouter } from "next/router";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "next/image";
import {
  CellData,
  LogoWrapper,
  RowActionButton,
  ColumnHeader,
  CellLoader,
} from "../TableComponents";
import { BaseFlex } from "@components/common";
import { commaSeparateNumbers, hexToRgba, truncateDecimal } from "@lib/utils";
import { PoolI, PoolMetaI } from "@lib/types";

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1em",
  fontWeight: 500,
  color: "#FFF",
  marginBottom: "1.25em",

  "& .total": {
    fontSize: "23.4px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "26px",
    },
  },
  "& .apy": {
    fontSize: "14.4px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
    color: hexToRgba(theme.palette.common.white, 0.7),

    "& span": {
      color: theme.palette.common.white,
    },
  },
}));

export const Wrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    minWidth: "34.375rem",
    maxWidth: "50rem",
  },
  "& .lineGrid": {
    borderBottom: `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
  },
  "& .lineGrid:nth-last-of-type(1)": {
    borderBottom: "none",
  },
}));

export const LineGrid = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1.2fr 0.8fr",
  gridTemplateAreas: `'pool totalSupplied rewardsEarned action'`,
  padding: "1.54rem 0",
});

export const Message = ({ message }: { message: string }): JSX.Element => {
  return (
    <LineGrid
      className="lineGrid"
      sx={{
        display: "flex",
        justifyContent: {
          xs: "flex-start",
          sm: "center",
        },
        color: "rgba(255,255,255, 0.6)",
      }}
    >
      {message}
    </LineGrid>
  );
};

export const UserLiquidityHeader = (): JSX.Element => {
  return (
    <LineGrid className="lineGrid" sx={{ padding: 0 }}>
      <ColumnHeader sx={{ gridArea: "pool", justifyContent: "start" }}>Pool</ColumnHeader>
      <ColumnHeader sx={{ gridArea: "rewardsEarned" }}>Rewards Earned</ColumnHeader>

      <ColumnHeader sx={{ gridArea: "totalSupplied" }}>Total Supplied</ColumnHeader>
      <ColumnHeader
        sx={{
          gridArea: "action",
          justifyContent: "flex-end",
          "&:hover": {
            cursor: "default",
            opacity: 1,
          },
        }}
      >
        Action
      </ColumnHeader>
    </LineGrid>
  );
};

export interface UserLiquidityItemProps {
  pool?: Pick<PoolI & PoolMetaI, "id" | "name" | "debtToken" | "collateralTokenLogoUrl">;
  totalLiquidity: string | undefined;
  feesEarned: string | undefined;
  rewardsEarnedPercentage?: string;
  loading?: boolean;
  isActionButtonDisabled?: boolean;
}

export const UserLiquidityItem = ({
  pool,
  totalLiquidity,
  feesEarned,
  rewardsEarnedPercentage,
  loading,
  isActionButtonDisabled = true,
}: UserLiquidityItemProps): JSX.Element => {
  const { push } = useRouter();

  return (
    <LineGrid className="lineGrid">
      <CellData
        data-test="pool"
        sx={{
          gridArea: "pool",
          justifyContent: "start",
        }}
      >
        <BaseFlex>
          <LogoWrapper>
            {/* @ todo */}
            {pool?.collateralTokenLogoUrl ? (
              <Image src={pool?.collateralTokenLogoUrl} height={18} width={18} />
            ) : (
              <Image src="/assets/tokenImages/Ethereum-ETH-icon.png" height={18} width={18} />
            )}
          </LogoWrapper>
          {pool?.name}
        </BaseFlex>
      </CellData>
      <CellData data-test="totalSupplied" sx={{ gridArea: "totalSupplied" }}>
        {!loading && totalLiquidity ? (
          `${commaSeparateNumbers(truncateDecimal(totalLiquidity))} ${pool?.debtToken.symbol}`
        ) : (
          <CellLoader width="60%" />
        )}
      </CellData>

      <CellData data-test="rewardsEarned" sx={{ gridArea: "rewardsEarned" }}>
        {!loading && feesEarned ? (
          `${commaSeparateNumbers(feesEarned)} ${pool?.debtToken.symbol}`
        ) : (
          <CellLoader width="60%" />
        )}
        {rewardsEarnedPercentage !== undefined && (
          <Box
            component="span"
            sx={{
              color: (theme) =>
                rewardsEarnedPercentage === "0.00%"
                  ? hexToRgba(theme.palette.common.white, 0.6)
                  : theme.palette.secondary.main,
            }}
          >
            &nbsp; (+ {rewardsEarnedPercentage})
          </Box>
        )}
      </CellData>

      <CellData data-test="action" sx={{ gridArea: "action", justifyContent: "flex-end" }}>
        <RowActionButton
          name="Manage"
          handleClick={() => push(`portfolio/manage-lp/${pool?.id}`)}
          disabled={loading || isActionButtonDisabled}
        />
      </CellData>
    </LineGrid>
  );
};
