import { Fragment, useEffect } from "react";
import { useLPPositionsStore } from "@lib/providers";
import { useDebounce, usePools, useWeb3 } from "@lib/hooks";
import { TABLE_LOADER_MIN_DURATION } from "@components/common/Table";
import Callbacks from "./callbacks";

const LPPositionsPoller = (): JSX.Element => {
  const { lendingPools, loading, error } = usePools();
  const debouncedLoading = useDebounce(loading, TABLE_LOADER_MIN_DURATION);
  const { account } = useWeb3();
  const addPosition = useLPPositionsStore((state) => state.addPosition);
  const statusUpdate = useLPPositionsStore((state) => state.statusUpdate);

  useEffect(() => {
    if (!lendingPools) return;

    lendingPools.forEach((pool) =>
      addPosition({
        poolId: pool.id,
        poolDetails: {
          name: pool.name,
          debtToken: pool.debtToken,
          collateralToken: pool.collateralToken,
          collateralTokenLogoUrl: pool.collateralTokenLogoUrl,
          priceFeed: pool.priceFeed,
        },
      })
    );
  }, [loading, account]);

  useEffect(() => {
    statusUpdate({ loading: debouncedLoading, error: !!error });
  }, [debouncedLoading, error]);

  return (
    <Fragment>
      {lendingPools.map((lendingPool) => (
        <Callbacks
          key={lendingPool.id}
          pool={{
            id: lendingPool.id,
            priceFeed: lendingPool.priceFeed,
            debtToken: lendingPool.debtToken,
            collateralToken: lendingPool.collateralToken,
          }}
        />
      ))}
    </Fragment>
  );
};

export default LPPositionsPoller;
