import React, { SVGProps } from "react";

const DownArrowCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.3001 11.8L11.6001 14.5M11.6001 14.5L8.90007 11.8M11.6001 14.5L11.6001 7.3M11.6001 19C7.12657 19 3.50007 15.3735 3.50007 10.9C3.50007 6.4265 7.12657 2.8 11.6001 2.8C16.0736 2.8 19.7001 6.42649 19.7001 10.9C19.7001 15.3735 16.0736 19 11.6001 19Z"
        stroke="#EF5DA8"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownArrowCircle;
