import React from "react";
import { ActionButton } from "@components/common/ManageLiquidity/ManageLiquidityComponents";
import { ManageLiquidityModalType, useModalManager } from "../modalManager/ModalManager";
import { useLPPositionsStore } from "@lib/providers/";
import { usePendingSignalWithdrawTransactionByPool } from "@lib/hooks";

const WithdrawActionButton = ({ poolId }: { poolId: string }): JSX.Element => {
  const { setOpen } = useModalManager();
  const position = useLPPositionsStore((state) => state.positions[poolId]);
  const transaction = usePendingSignalWithdrawTransactionByPool(poolId);

  return (
    <ActionButton
      handleClick={() => setOpen(ManageLiquidityModalType.SIGNAL_WITHDRAW)}
      name={transaction ? "Signaling Withdraw" : "Signal Withdraw"}
      disabled={position?.activeLiquidity.amount?.isZero()}
      isTransacting={!!transaction}
      outline
    />
  );
};

export default WithdrawActionButton;
