import React from "react";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { BaseBox } from "@components/common";
import { convertToCurrency, hexToRgba } from "@lib/utils";

interface CoinPriceBoxProps {
  header: string;
  oracle?: string;
  url?: string;
  price: string | undefined;
  gainPoint?: number;
  gainPercentage?: number;
  loading: boolean;
}

const Container = styled(BaseBox)(({ theme }) => ({
  borderRadius: "0.5em",
  width: "100%",
  backgroundColor: theme.palette.primary[700],
  color: theme.palette.common.white,
  flexDirection: "column",
  padding: "1.4063rem 1.5625rem",
}));

// this component will be wrapped in a provider
// and will fetch its own data
const CoinPriceBox = ({
  header,
  oracle,
  url,
  price,
  gainPoint,
  gainPercentage,
  loading,
}: CoinPriceBoxProps): JSX.Element => {
  return (
    <Container>
      <Typography variant="headline1">{header}</Typography>
      <Typography variant="caption1" sx={{ color: (theme) => theme.palette.primary[300] }}>
        Cora&apos;s data feed uses{" "}
        <MuiLink
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          sx={{ textDecoration: "underline", color: (theme) => theme.palette.primary[300] }}
        >
          {oracle}
        </MuiLink>
      </Typography>

      <Typography
        variant="numbers1"
        sx={{
          marginTop: "1.5em",
        }}
      >
        {!loading ? (
          price && convertToCurrency(parseFloat(price))
        ) : (
          <Box component="span" sx={{ color: (theme) => hexToRgba(theme.palette.dark.main, 0.5) }}>
            Fetching price ...
          </Box>
        )}
        &nbsp;
        {gainPoint && gainPercentage && (
          <Typography
            component="span"
            variant="numbers2"
            sx={{
              color: (theme) =>
                gainPoint >= 0 ? theme.palette.secondary.main : theme.palette.error.main,
            }}
          >
            {`${gainPoint >= 0 ? "+" : ""}${gainPoint.toFixed(2)}`} (
            {`${gainPercentage >= 0 ? "+" : ""}${gainPercentage.toFixed(2)}`}%)
          </Typography>
        )}
      </Typography>
    </Container>
  );
};

export default CoinPriceBox;
