import React from "react";
import { TransitionGroup } from "react-transition-group";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";
import { BaseFlex, Spinner, ExternalLinkIcon } from "@components/common";
import { LineGrid } from "./UserLiquidityComponents";
import { CellData } from "../TableComponents";
import { useWeb3, usePendingWithdrawAvailableTransactions } from "@lib/hooks";
import { getExplorerLink, HashType } from "@lib/utils";

export const Description = styled("div")({
  fontWeight: 600,
  "& span": {
    fontWeight: 400,
    opacity: 0.7,
  },
});

const PendingCancelDepositList = (): JSX.Element | null => {
  const { chainId } = useWeb3();
  const pendingWithdrawAvailable = usePendingWithdrawAvailableTransactions();

  if (pendingWithdrawAvailable.length === 0) return null;

  return (
    <TransitionGroup component={null}>
      {pendingWithdrawAvailable.map((withdrawAvailable) => {
        return (
          <Collapse component="div" key={withdrawAvailable.hash}>
            <LineGrid className="lineGrid" sx={{ alignItems: "center" }}>
              <CellData
                sx={{
                  gridArea: "1 / pool / 1 / rewardsEarned",
                  justifyContent: "start",
                  textAlign: "left",
                }}
              >
                <BaseFlex sx={{ gap: "1rem" }}>
                  <Box component="span">
                    <Spinner />
                  </Box>

                  <Description>
                    <span>Withdrawing </span>
                    {withdrawAvailable.transactionInfo.transactedAmount?.amount}
                    <span> from the {withdrawAvailable.title} pool.</span>
                  </Description>
                </BaseFlex>
              </CellData>

              <CellData
                sx={{ gridArea: "action", justifyContent: "flex-end", whiteSpace: "nowrap" }}
              >
                <MuiLink
                  href={getExplorerLink(
                    chainId as number,
                    withdrawAvailable?.hash as string,
                    HashType.TRANSACTION
                  )}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BaseFlex>
                    <Box
                      sx={{
                        height: "17px",
                        width: "17px",
                        color: (theme) => theme.palette.secondary.main,
                      }}
                    >
                      <ExternalLinkIcon />
                    </Box>
                    <Box
                      sx={{
                        color: "white",
                        opacity: 0.7,

                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      View on explorer
                    </Box>
                  </BaseFlex>
                </MuiLink>
              </CellData>
            </LineGrid>
          </Collapse>
        );
      })}
    </TransitionGroup>
  );
};

export default PendingCancelDepositList;
