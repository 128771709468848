import { BigNumber } from "ethers";

export const getTotalLiquidityThatCanBeSignaledForWithdrawal = ({
  activeLiquidity = BigNumber.from(0),
  pendingWithdrawal = BigNumber.from(0),
}: {
  activeLiquidity?: BigNumber;
  pendingWithdrawal?: BigNumber;
}): BigNumber => {
  return activeLiquidity.sub(pendingWithdrawal);
};
