import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TransactionCardBase } from "@components/common";
import Supply from "./Supply/Supply";
import SupplyAgain from "./SupplyAgain/SupplyAgain";
import SupplyBasic from "./SupplyBasic/SupplyBasic";
import { TransactionCardBaseProps } from "@components/common/TransactionCard/TransactionCardBase";
import { useLPPositionsStore } from "@lib/providers";
import { gradient4 } from "styles/color";

export interface SupplyWithdrawModalProps extends Omit<TransactionCardBaseProps, "children"> {
  poolAddress: string;
}

/**
 * Show SupplyAgain modal if user has pending deposit or withdrawal or active liquidity
 */
export const SupplyModal = ({
  open,
  onClose,
  poolAddress,
}: SupplyWithdrawModalProps): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolAddress]);

  const [modalToShow, setModalToShow] = useState<"supply" | "supplyAgain">("supply");

  useEffect(
    function chooseWhichModalToShow() {
      if (
        (position?.pendingDeposit?.amount !== undefined &&
          !position?.pendingDeposit?.amount?.isZero()) ||
        (position?.pendingWithdrawal?.amount !== undefined &&
          !position?.pendingWithdrawal?.amount?.isZero()) ||
        (position?.activeLiquidity?.amount !== undefined &&
          !position?.activeLiquidity?.amount?.isZero())
      ) {
        setModalToShow("supplyAgain");
      } else {
        setModalToShow("supply");
      }
    },
    [
      position?.pendingDeposit?.amount,
      position?.pendingWithdrawal?.amount,
      position?.activeLiquidity?.amount,
    ]
  );

  return (
    <TransactionCardBase open={open} onClose={onClose}>
      <Typography
        variant="headline1"
        sx={{
          textAlign: "center",
          width: "100%",
          color: (theme) => theme.palette.common.white,
          padding: "0.8em",
        }}
      >
        Supply
      </Typography>

      <Box sx={{ height: "4px", background: gradient4, width: "100%" }} />

      {modalToShow === "supplyAgain" ? (
        <SupplyAgain poolAddress={poolAddress} />
      ) : (
        <Supply poolAddress={poolAddress} />
      )}
    </TransactionCardBase>
  );
};

export const SupplyBasicModal = ({
  open,
  onClose,
  poolAddress,
}: SupplyWithdrawModalProps): JSX.Element => {
  return (
    <TransactionCardBase open={open} onClose={onClose}>
      <Typography
        variant="headline1"
        sx={{
          textAlign: "center",
          width: "100%",
          color: (theme) => theme.palette.common.white,
          padding: "0.8em",
        }}
      >
        Supply
      </Typography>

      <Box sx={{ height: "4px", background: gradient4, width: "100%" }} />

      <SupplyBasic poolAddress={poolAddress} />
    </TransactionCardBase>
  );
};
