export enum Chain {
  ETHEREUM = 1,
  GOERLI = 5,
  AURORA_TESTNET = 1313161555,
  MOONBASE_ALPHA_TESTNET = 1287,
  LOCALHOST = 69420,
}

export const DEFAULT_CHAIN = process.env.APP_ENV !== "test" ? Chain.GOERLI : Chain.LOCALHOST;

// const CHAINLINK_ORACLE = {
//   oracle: "Chainlink",
//   oracleDocs: "https://docs.chain.link/docs/ethereum-addresses/",
// };
const FLUX_ORACLE = {
  oracle: "Flux protocol",
  oracleDocs: "https://docs.fluxprotocol.org/docs/live-data-feeds/fpo-live-networks-and-pairs",
};

export interface Currency {
  name: string;
  symbol: string;
  decimals: number;
}

export interface ChainInformation {
  name: string;
  explorer: string;
  docs?: string;
  nativeCurrency?: Currency;
  rpcUrls: Array<string>;
  priceFeed?: {
    oracle: string;
    oracleDocs: string;
  };
  /** if network is currently available in Cora */
  disabled: boolean;
  externalRPC?: string;
  factoryAddress?: string;
  multicallAddress?: string;
}

export type ChainId = number;

const chainList: Record<ChainId, ChainInformation> = {
  [Chain.GOERLI]: {
    name: "Goerli Testnet",
    explorer: "https://goerli.etherscan.io",
    rpcUrls: ["https://goerli.infura.io/v3/"],
    priceFeed: {
      ...FLUX_ORACLE,
    },
    nativeCurrency: {
      name: "Goerli ETH",
      symbol: "goerliETH",
      decimals: 18,
    },
    disabled: false,
    externalRPC: process.env.NEXT_PUBLIC_GOERLI_EXTERNAL_RPC,
    factoryAddress: process.env.NEXT_PUBLIC_LENDINGPOOLFACTORY_GOERLI as string,
    multicallAddress: process.env.NEXT_PUBLIC_MULTICALL_GOERLI as string,
  },
  [Chain.AURORA_TESTNET]: {
    name: "Aurora Testnet",
    explorer: "https://testnet.aurorascan.dev",
    rpcUrls: ["https://testnet.aurora.dev"],
    priceFeed: {
      ...FLUX_ORACLE,
    },
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    disabled: false,
  },
  [Chain.MOONBASE_ALPHA_TESTNET]: {
    name: "Moonbase Alpha Testnet",
    explorer: "https://moonbase.moonscan.io",
    rpcUrls: ["https://rpc.api.moonbase.moonbeam.network"],
    priceFeed: {
      ...FLUX_ORACLE,
    },
    nativeCurrency: {
      name: "DEV",
      symbol: "DEV",
      decimals: 18,
    },
    disabled: false,
    externalRPC: process.env.NEXT_PUBLIC_MOONBASE_ALPHA_TESTNET_EXTERNAL_RPC,
    factoryAddress: process.env.NEXT_PUBLIC_LENDINGPOOLFACTORY_MOONBASE_ALPHA_TESTNET as string,
    multicallAddress: process.env.NEXT_PUBLIC_MULTICALL_MOONBASE_ALPHA_TESTNET as string,
  },
  [Chain.LOCALHOST]: {
    name: "Localhost 8545",
    explorer: "",
    rpcUrls: ["http://127.0.0.1:8545"],
    priceFeed: {
      ...FLUX_ORACLE,
    },
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    disabled: false,
    externalRPC: "http://localhost:8545",
    factoryAddress: process.env.NEXT_PUBLIC_LENDINGPOOLFACTORY_LOCAL as string,
    multicallAddress: process.env.NEXT_PUBLIC_MULTICALL_LOCALHOST as string,
  },
};

export default chainList;
