import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { useAllowance } from "@lib/hooks";
import { TokenI } from "@lib/types";
import { useTokenAllowanceStore } from "@lib/providers";

interface UseShouldAppoveTokenProps {
  token: TokenI;
  spender: string;
  balance?: BigNumber;
}

/**
 * hook to determine if a token should be approved
 * false when doing an approve transaction
 * true when the user's amount to spend is higher than the allowance in the blockchain
 */
const useShouldAppoveToken = ({
  token,
  spender,
  balance,
}: UseShouldAppoveTokenProps): { shouldApprove: boolean; isApproving: boolean } => {
  const [shouldApprove, setShouldApprove] = useState<boolean>(false);

  const tokenAllowanceDetail = useTokenAllowanceStore(
    (state) => state.tokenAllowanceDetails[`${token?.id}-${spender}`]
  );

  const { allowance: tokenAllowance } = useAllowance({
    tokenAddress: token?.id as string,
    spender,
    decimals: token?.decimals,
  });

  useEffect(
    function testAccordingToBalance() {
      if (tokenAllowanceDetail?.isApproving) return;

      if (!balance) {
        setShouldApprove(false);
        return;
      }

      const shouldApprove = () => {
        if (!tokenAllowanceDetail?.allowance?.value || !balance) return false;
        try {
          return balance?.gt(tokenAllowanceDetail.allowance.value);
        } catch {
          return false;
        }
      };

      setShouldApprove(shouldApprove());
    },
    [tokenAllowance, balance, tokenAllowanceDetail?.isApproving]
  );

  return { shouldApprove, isApproving: tokenAllowanceDetail?.isApproving || false };
};

export default useShouldAppoveToken;
