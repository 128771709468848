import { useCallback, useEffect } from "react";
import { useWeb3, useUnminedTransactions } from "@lib/hooks";
import { useTransactionStore, useBlockNumber, useTokenAllowanceStore } from "@lib/providers";
import { TransactionType } from "@lib/types";
import { Logger } from "ethers/lib/utils";
import { BigNumber } from "ethers";

/**
 * on mount and on user wallet connection this updater will check for blockchain pending transactions
 * and send a message to the pubsub mechanism to setup a block listener for the transaction
 *
 * this would make persisting transaction history and updating it even when the user comes back to the page after closing the browser
 * like in the case of longer transaction times on busy networks
 *
 * inspired by uniswap https://app.uniswap.org/
 */
const UpdatePendingTransaction = (): null => {
  const { active, chainId, library } = useWeb3();

  const blockNumber = useBlockNumber();

  const unminedTransactions = useUnminedTransactions();
  const isMined = useTransactionStore((state) => state.isMinedTransaction);
  const isError = useTransactionStore((state) => state.isErrorTransaction);
  const isCancelledTransaction = useTransactionStore((state) => state.isCancelledTransaction);
  const isReplacedTransaction = useTransactionStore((state) => state.isReplacedTransaction);
  const updateTokenAllowanceDetail = useTokenAllowanceStore(
    (state) => state.updateTokenAllowanceDetail
  );

  const getReceipt = useCallback(
    (hash: string) => {
      if (!library || !chainId) throw new Error("No provider or chainId");

      return library.getTransactionReceipt(hash);
    },
    [library, chainId]
  );

  useEffect(
    function pollUnminedTransactions() {
      if (!active) return;

      unminedTransactions.forEach((transaction) => {
        getReceipt(transaction.newHash || transaction.hash)
          .then((receipt) => {
            if (!receipt) return;

            if (receipt.status === 1) {
              isMined({ hash: transaction.hash, chainId: chainId as number });

              if (transaction.transactionInfo.type === TransactionType.Approve) {
                updateTokenAllowanceDetail({
                  id: transaction.transactionInfo.token.id,
                  spender: transaction.transactionInfo.spender,
                  isApproving: false,
                  lastUpdateOnBlock: blockNumber,
                });
              }
            } else {
              isError({ hash: transaction.hash, chainId: chainId as number });
              if (transaction.transactionInfo.type === TransactionType.Approve) {
                updateTokenAllowanceDetail({
                  id: transaction.transactionInfo.token.id,
                  spender: transaction.transactionInfo.spender,
                  isApproving: false,
                  lastUpdateOnBlock: blockNumber,
                });
              }
            }
          })
          .catch((err) => console.error(err));
      });
    },
    [active, blockNumber, chainId, unminedTransactions]
  );

  useEffect(
    function listenForReplacedCancelledTransactions() {
      if (!active) return;
      if (!library) return;
      if (!chainId) return;

      unminedTransactions.forEach((transaction) => {
        library
          ?._waitForTransaction(transaction.hash, 1, 0, {
            from: transaction.receipt?.from as string,
            to: transaction.receipt?.to as string,
            data: transaction.receipt?.data as string,
            value: transaction.receipt?.value as BigNumber,
            nonce: transaction.receipt?.nonce as number,
            startBlock: transaction.blockNumber as number,
          })
          .catch((error) => {
            if (error.code === Logger.errors.TRANSACTION_REPLACED) {
              if (error.reason === "cancelled") {
                isCancelledTransaction({
                  hash: error.hash,
                  chainId: chainId as number,
                  newHash: error.replacement?.hash,
                });

                return;
              } else {
                isReplacedTransaction({
                  hash: error.hash,
                  chainId: chainId as number,
                  newHash: error.replacement?.hash,
                });
                return;
              }
            }
          });
      });
    },
    [active, chainId]
  );

  return null;
};

export default UpdatePendingTransaction;
