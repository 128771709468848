import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ActionButton, BaseFlex, SpinnerTwo, InputSliderPair } from "@components/common";
import { TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, decimalToPercentage, hexToRgba, truncateDecimal } from "@lib/utils";
import { useIsMobile } from "@lib/hooks";
import UserLiquidityDetails, { UserLiquidityDetailsProps } from "./UserLiquidityDetails";
import PoolDetails, { PoolDetailsProps } from "./PoolDetails";
import NewPositionDetails from "./NewPositionDetails";
import LPProjectionChart from "../Supply/LendingPoolInfo/LPProjectionChart";

export interface SupplyTransactionCardProps extends Pick<TransactionCardBaseProps, "fullScreen"> {
  inputName: string;
  sliderName: string;
  loading: boolean;
  walletBalance: string;
  walletBalanceSymbol: string;
  minAmountToDeposit: number;
  roundDurationInDays: string;
  approveButtonIsDisabled: boolean;
  approveButtonOnClick: () => void;
  isApproving: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
  poolDetails: Omit<PoolDetailsProps, "loading">;
  userLiquidityDetails: Omit<UserLiquidityDetailsProps, "loading"> & { pendingWithdrawal: string };
}

const SupplyAgain = ({
  inputName,
  sliderName,
  loading,
  walletBalance,
  walletBalanceSymbol,
  minAmountToDeposit,
  approveButtonIsDisabled,
  approveButtonOnClick,
  isApproving,
  onSubmit,
  isSubmitting,
  isValid,
  poolDetails,
  userLiquidityDetails,
  fullScreen = false,
}: SupplyTransactionCardProps): JSX.Element => {
  const { handleSubmit, formState, watch } = useFormContext();
  const { isValid: formStateIsValid } = formState;
  const { isMobile } = useIsMobile();

  return (
    <BaseFlex sx={{ gap: 0, padding: 0, alignItems: "start" }}>
      <BaseFlex
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          padding: "1.5em 2em",
          flexDirection: "column",
          gap: "2em",
          color: "white",
          width: {
            xs: "100%",
            md: "28.75rem",
          },
        }}
      >
        <BaseFlex sx={{ flexDirection: "column", width: "100%" }}>
          <UserLiquidityDetails loading={loading} {...userLiquidityDetails} />

          <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
            <Typography
              variant="headline2"
              component="span"
              sx={{
                color: (theme) => theme.palette.common.white,
                whiteSpace: "pre-wrap",
              }}
            >
              Supply Amount
            </Typography>
            <BaseFlex
              sx={{
                flexWrap: "wrap",
                justifyContent: "flex-end",
                gap: "0",
              }}
            >
              <Typography
                variant="caption2"
                component="span"
                sx={{ color: (theme) => theme.palette.primary[300] }}
              >
                Wallet Balance:
              </Typography>
              <Typography
                variant="caption1"
                data-test="wallet-balance"
                component="span"
                sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
              >
                {loading || !walletBalance || walletBalance === ""
                  ? "-"
                  : `${commaSeparateNumbers(truncateDecimal(walletBalance.toString()))}
                ${walletBalanceSymbol}`}
              </Typography>
            </BaseFlex>
          </BaseFlex>
          <InputSliderPair
            inputName={inputName}
            logo={poolDetails.poolDebtToken.logo}
            sliderName={sliderName}
            disabled={loading}
            max={walletBalance}
            conversionRate={poolDetails.poolDebtToken.price}
            moreValidationRules={{
              min: (value) => {
                if (value < minAmountToDeposit) {
                  return `Minimum supply amount: ${minAmountToDeposit} ${poolDetails.poolDebtToken.symbol}.`;
                }
              },
            }}
          />
        </BaseFlex>

        <BaseFlex
          sx={{
            width: "100%",
            flexDirection: "column",
            gap: "1em",
            marginTop: fullScreen ? "-1em" : 0,
          }}
        >
          <NewPositionDetails
            loading={loading}
            activeLiquidity={userLiquidityDetails.activeLiquidity}
            pendingDeposit={userLiquidityDetails.pendingDeposit}
            additionalDeposit={watch(inputName) || "0"}
            willBeActiveIn={poolDetails.nextRoundTimestampRelative}
            token={userLiquidityDetails.token}
          />
        </BaseFlex>

        <BaseFlex sx={{ width: "100%", flexDirection: "column" }}>
          <BaseFlex sx={{ width: "100%" }}>
            <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
              <ActionButton
                data-test="approve-button"
                onClick={approveButtonOnClick}
                disabled={isApproving || approveButtonIsDisabled || loading}
                pendingAction={isApproving}
              >
                {isApproving && <SpinnerTwo />}
                {isApproving ? "Approving" : "Approve"}
              </ActionButton>
            </BaseFlex>

            <ActionButton
              type="submit"
              data-test="supply-button"
              disabled={!isValid || isSubmitting || loading || !formStateIsValid}
              pendingAction={isSubmitting}
            >
              {isSubmitting ? "Supplying" : "Supply"}
            </ActionButton>
          </BaseFlex>
          {!approveButtonIsDisabled && !loading && (
            <BaseFlex sx={{ fontSize: "13px", width: "100%", justifyContent: "left" }}>
              Allow the Cora Protocol to use your token on your behalf.
            </BaseFlex>
          )}
        </BaseFlex>
      </BaseFlex>

      {!isMobile && (
        <Fragment>
          <Box sx={{ alignSelf: "stretch", padding: "1em 0" }}>
            <Box
              sx={{
                borderRight: (theme) => `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
                height: "100%",
              }}
            />
          </Box>

          <BaseFlex
            sx={{
              padding: "1.5em 2em",
              flexDirection: "column",
              gap: "3rem",
              color: "white",
              width: {
                xs: "100%",
                md: "28.75rem",
              },
            }}
          >
            <BaseFlex
              sx={{
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "1.5em",
                marginBottom: "auto",
              }}
            >
              <Box>
                <Typography variant="headline2" component="span">
                  Projected APY: &nbsp;
                </Typography>

                <Typography
                  variant="headline2"
                  component="span"
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                >
                  {decimalToPercentage(poolDetails.poolAPY)}
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <LPProjectionChart depositValue={watch(inputName)} apy={poolDetails.poolAPY} />
              </Box>
            </BaseFlex>

            <PoolDetails loading={loading} {...poolDetails} />
          </BaseFlex>
        </Fragment>
      )}
    </BaseFlex>
  );
};

export default SupplyAgain;
