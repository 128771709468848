import { useCallback, useState } from "react";
import { BigNumber } from "ethers";
import { useContract } from "@lib/hooks";

import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import { LendingPool } from "@coraprotocol/core/typechain";
import { parseUnits } from "ethers/lib/utils";

interface UseGetBorrowingFeeArgs {
  loanAmount: { amount: string; decimal?: number };
  collateralAmount: { amount: string; decimal?: number };
  loanTerm: number;
}

const borrowingFeeArgCheck = ({
  loanAmount,
  collateralAmount,
  loanTerm,
}: UseGetBorrowingFeeArgs) => {
  if (isNaN(parseFloat(loanAmount.amount)) || isNaN(parseFloat(collateralAmount.amount))) {
    throw new Error("Invalid: input is not a number");
  }

  if (loanAmount.amount === "0" || collateralAmount.amount === "0") {
    throw new Error("Invalid: input is zero");
  }

  if (!loanAmount.decimal || !collateralAmount.decimal) {
    throw new Error("Invalid: no decimal");
  }

  if (!loanTerm) {
    throw new Error("Invalid: no loan term");
  }
};

const useGetBorrowingFee = (
  poolAddress: string
): {
  borrowingFee: BigNumber | undefined;
  getBorrowingFee: (args: UseGetBorrowingFeeArgs) => void;
  updating: boolean;
} => {
  const contract = useContract<LendingPool>(poolAddress, LendingPoolAbi);
  const [borrowingFee, setBorrowingFee] = useState<BigNumber | undefined>(undefined);
  const [updating, setUpdating] = useState<boolean>(false);

  const getBorrowingFee = useCallback(
    async ({ loanAmount, collateralAmount, loanTerm }: UseGetBorrowingFeeArgs) => {
      if (!contract) {
        setBorrowingFee(BigNumber.from(0));
        return;
      }

      try {
        borrowingFeeArgCheck({ loanAmount, collateralAmount, loanTerm });
      } catch (error) {
        setBorrowingFee(undefined);
        return;
      }

      const loanAmountFormatted = parseUnits(loanAmount.amount, loanAmount.decimal);
      const collateralAmountFormatted = parseUnits(
        collateralAmount.amount,
        collateralAmount.decimal
      );

      let newBorrowingValue: BigNumber;

      try {
        setUpdating(true);
        newBorrowingValue = await contract.getBorrowingFee(
          loanAmountFormatted,
          collateralAmountFormatted,
          loanTerm
        );
      } catch (error) {
        newBorrowingValue = BigNumber.from(0);
        console.error(error);
      }

      const timer1 = setTimeout(() => {
        setBorrowingFee(newBorrowingValue);
        setUpdating(false);
      }, 500);

      return () => {
        clearTimeout(timer1);
      };
    },
    [contract]
  );

  return { borrowingFee, getBorrowingFee, updating };
};

export default useGetBorrowingFee;
