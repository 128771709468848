import { useEffect } from "react";

/**
 * Needed because the app needs to reload to cancel inflight multicall requests
 *
 * https://github.com/ethers-io/ethers.js/issues/866
 * https://github.com/ethers-io/ethers.js/discussions/1480
 */
const useReloadOnChainChanged = (): null => {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  }, []);

  return null;
};

export default useReloadOnChainChanged;
