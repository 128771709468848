import { useCallback, useState } from "react";
import { BigNumberish, ContractTransaction } from "ethers";
import { Logger } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { ErrorToast } from "@components/common";
import { useContract, useIsMounted, useWeb3 } from "@lib/hooks";
import { useTransactionStore } from "@lib/providers";
import { TokenI, TransactionStatus, TransactionType } from "@lib/types";
import { truncateDecimal, commaSeparateNumbers, transactionErrorHandler } from "@lib/utils";

import { LendingPool } from "@coraprotocol/core/typechain";
import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";

import * as Sentry from "@sentry/react";

interface CallbackArgs {
  poolName: string;
  debtoken: Pick<TokenI, "id" | "symbol"> & {
    amount: string;
    amountUnitPriceInUSD?: string;
  };
}

/**
 * same as useDeposit but just different TransactionType
 */
const useCancelWithdraw = (
  poolAddress: string
): {
  deposit: (amount: BigNumberish, raw: CallbackArgs) => Promise<void>;
  response: ContractTransaction | undefined;
  error: any;
  transactionStatus: TransactionStatus;
} => {
  const { chainId } = useWeb3();

  const lendingContract = useContract<LendingPool>(poolAddress, LendingPoolAbi);
  const [response, setResponse] = useState<undefined | ContractTransaction>(undefined);
  const [error, setError] = useState<string>();
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(undefined);

  const addTransaction = useTransactionStore((state) => state.addTransaction);
  const isReplacedTransaction = useTransactionStore((state) => state.isReplacedTransaction);
  const isCancelledTransaction = useTransactionStore((state) => state.isCancelledTransaction);

  // checks if the component using this hook is still mounted
  // useful for when the promise has not resolved or rejected yet but the transaction modal is already unmounted
  // the transaction modal will unmount after the user confirms transaction in metamask
  const isMounted = useIsMounted();

  const deposit = useCallback(
    async (amount: BigNumberish, raw: CallbackArgs) => {
      if (!lendingContract) return;

      if (isMounted.current) {
        setTransactionStatus("confirm");
      }

      try {
        const blockNumber = await lendingContract.provider?.getBlockNumber();
        const tx = await lendingContract.deposit(amount);
        setResponse(tx);

        if (isMounted.current) {
          setTransactionStatus("submitted");
        }

        addTransaction({
          hash: tx.hash,
          account: tx.from,
          chainId: chainId as number,
          receipt: tx,
          blockNumber,
          title: raw.poolName,
          description: `an amount of ${commaSeparateNumbers(
            truncateDecimal(raw.debtoken.amount)
          )} ${raw.debtoken.symbol} from the ${raw.poolName} pool.`,
          transactionInfo: {
            type: TransactionType.CancelWithdraw,
            lendingPoolId: poolAddress,
            transactedAmount: {
              id: raw.debtoken.id,
              symbol: raw.debtoken.symbol,
              amount: `${commaSeparateNumbers(truncateDecimal(raw.debtoken.amount))} ${
                raw.debtoken.symbol
              }`,
              amountInUSD: raw.debtoken.amountUnitPriceInUSD
                ? (
                    parseFloat(raw.debtoken.amountUnitPriceInUSD) * parseFloat(raw.debtoken.amount)
                  ).toString()
                : null,
            },
          },
        });

        await tx?.wait();

        if (isMounted.current) {
          setTransactionStatus("success");
        }
      } catch (error: any) {
        if (error.code === Logger.errors.TRANSACTION_REPLACED) {
          if (error.reason === "cancelled") {
            isCancelledTransaction({
              hash: error.hash,
              chainId: chainId as number,
              newHash: error.replacement?.hash,
            });

            if (isMounted.current) {
              setTransactionStatus("error");
              setError("Transaction cancelled.");
            }

            return;
          } else {
            isReplacedTransaction({
              hash: error.hash,
              chainId: chainId as number,
              newHash: error.replacement?.hash,
            });
            return;
          }
        }

        if (!isMounted.current) return;
        setTransactionStatus("error");

        if (error.code === Logger.errors.ACTION_REJECTED) {
          setError("Transaction rejected.");
        } else {
          const parsedError = transactionErrorHandler(error);
          setError(parsedError.message);

          Sentry.captureException({
            message: parsedError.message,
            error,
          });

          toast.error(<ErrorToast description={parsedError.message} />, {
            autoClose: 10000,
            hideProgressBar: false,
          });
        }
      }
    },
    [lendingContract]
  );

  return { deposit, response, error, transactionStatus };
};

export default useCancelWithdraw;
