import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
  BaseFlex,
  CoraLogo,
  DiscordIcon,
  MediumIcon,
  MirrorIcon,
  TwitterIcon,
} from "@components/common";
import LinkGroup, { LinksContainer } from "./FooterComponents";
import { externalLinks } from "@lib/constants";
import { hexToRgba } from "@lib/utils";

const Footer = (): JSX.Element => {
  return (
    <Fragment>
      <Divider
        sx={{
          borderTop: (theme) => `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
          width: "100vw",
          marginTop: "2rem",
        }}
      />

      <BaseFlex
        sx={{
          gap: "2em",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          height: {
            lg: "25vh",
          },
          width: "100%",
          minWidth: {
            lg: "1225px",
          },
          maxWidth: {
            xl: "1840px",
          },
          padding: {
            xs: "2em 1.5em",
            md: "1.75em 5.375em",
          },
          justifyContent: "space-between",
          alignItems: {
            xs: "start",
          },
        }}
      >
        <BaseFlex
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            width: "50%",
            gap: "1em",
          }}
        >
          <CoraLogo />

          <Typography
            variant="caption2"
            sx={{
              color: (theme) => theme.palette.secondary.main,
              whiteSpace: "nowrap",
            }}
          >
            © 2022 Cora Labs. All rights reserved.
          </Typography>
        </BaseFlex>

        <BaseFlex
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: "2em",
            width: {
              md: "50%",
            },
          }}
        >
          <LinkGroup
            title="Docs & Security"
            links={[
              { label: "Documentation", url: externalLinks.DOCS },
              {
                label: "Github",
                url: externalLinks.GITHUB,
              },
              {
                label: "Audit",
                url: externalLinks.AUDIT,
              },
            ]}
          />

          <LinkGroup
            title="Governance"
            links={[
              { label: "Forum", url: externalLinks.FORUM },
              {
                label: "Voting",
                url: externalLinks.VOTING,
              },
            ]}
          />

          <LinksContainer>
            <Typography
              variant="caption2"
              sx={{
                color: (theme) => theme.palette.primary[300],
                marginBottom: "0.2rem",
              }}
            >
              Community
            </Typography>
            <Box sx={{ display: "flex", gap: "1.15rem", alignItems: "center" }}>
              <MuiLink href={externalLinks.DISCORD} rel="noopener noreferrer" target="_blank">
                <DiscordIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
              </MuiLink>
              <MuiLink href={externalLinks.TWITTER} rel="noopener noreferrer" target="_blank">
                <TwitterIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
              </MuiLink>
              <MuiLink href={externalLinks.MEDIUM} rel="noopener noreferrer" target="_blank">
                <MediumIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
              </MuiLink>
              {/* Get better svg icon */}
              <MuiLink href={externalLinks.MIRROR} rel="noopener noreferrer" target="_blank">
                <MirrorIcon
                  sx={{ color: (theme) => theme.palette.secondary.main, height: "20px" }}
                />
              </MuiLink>
            </Box>
          </LinksContainer>
        </BaseFlex>
      </BaseFlex>
    </Fragment>
  );
};

export default Footer;
