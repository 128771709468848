import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import { Spinner } from "@components/common";
import { hexToRgba } from "@lib/utils";

export interface AccountButtonProps {
  address: string;
  pendingTransactions?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  dropdownIsOpen?: boolean;
}

const Account = ({
  address,
  pendingTransactions = 0,
  onClick,
  dropdownIsOpen = false,
}: AccountButtonProps): JSX.Element => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        transition: "background-color 0.4s ease-in-out",
        backgroundColor: (theme) =>
          dropdownIsOpen
            ? theme.palette.secondary[400]
            : hexToRgba(theme.palette.secondary.main, 0.1),
        borderRadius: "0.5rem",
        padding: "0.75rem 1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="buttonM"
          sx={{
            whiteSpace: "nowrap",
            color: (theme) =>
              dropdownIsOpen ? theme.palette.dark.main : theme.palette.common.white,

            transition: "color 0.4s ease-in-out",
          }}
        >
          {`${address?.substring(0, 5)}…${address?.substring(address?.length - 4)}`}
        </Typography>
      </Box>

      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: (theme) => hexToRgba(theme.palette.common.white, 0.15),
          backgroundColor: (theme) => theme.palette.primary.main,
          margin: "0 1rem",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexShrink: 1,
          gap: "0.7em",
        }}
      >
        {pendingTransactions > 0 && <Spinner dark={dropdownIsOpen} />}
        <Typography
          variant="buttonM"
          sx={{
            opacity: pendingTransactions > 0 ? 1 : 0.3,
            color: (theme) =>
              dropdownIsOpen ? theme.palette.dark.main : theme.palette.secondary.main,
            transition: "color 0.4s ease-in-out",
          }}
        >
          {pendingTransactions}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

export default Account;
