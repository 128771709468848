import createStore from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { formatUnits } from "ethers/lib/utils";
import { AddPoolsPayload, UpdatePoolPayload } from "./types";
import { nextRoundTimestamp, getUtilization } from "@lib/utils";
import { Chain, POOL_META } from "@lib/constants";
import { PoolI } from "@lib/types";

type StateDatum = PoolI & {
  poolLoading: boolean;
  poolError?: any;
};

type State = Record<PoolI["id"], StateDatum>;

interface PoolsStoreI {
  pools: State;
  chainId?: Chain;
  loading: boolean;
  error?: any;
  addPools: (pools: AddPoolsPayload, chainId: Chain) => void;
  updatePool: (pool: UpdatePoolPayload) => void;
}

export const usePoolsStore = createStore<
  PoolsStoreI,
  [["zustand/devtools", PoolsStoreI], ["zustand/immer", PoolsStoreI]]
>(
  devtools(
    immer((set, _get) => ({
      pools: {},
      loading: true,
      error: undefined,
      chainId: undefined,
      addPools: (pools, chainId) =>
        set(
          (state) => {
            if (!state.loading) return; // TODO: handle this better

            const transformedPools = Object.entries(pools).reduce<State>((acc, [key, value]) => {
              acc[key] = { ...value, poolLoading: true, poolError: undefined } as StateDatum;
              return acc;
            }, {});

            state.pools = transformedPools;
            state.chainId = chainId;
            state.loading = false;
            state.error = undefined;
          },
          undefined,
          "addPools"
        ),
      updatePool: (poolPayload) =>
        set(
          (state) => {
            const {
              totalBorrowed,
              totalStableCoin,
              debtTokenPrice,
              collateralTokenPrice,
              ltv,
              maxCap,
              ...pool
            } = poolPayload;

            const roundsTimeStamp = nextRoundTimestamp({
              createdAtTimestamp: state.pools[pool.id].createdAtTimestamp,
              roundDuration: state.pools[pool.id].roundDuration,
              genesisRoundDuration: state.pools[pool.id].genesisRoundDuration,
              roundIndex: pool.currentRoundIndex,
            });

            const utilization = getUtilization(totalBorrowed, totalStableCoin);

            state.pools[pool.id] = {
              ...state.pools[pool.id],
              ...pool,
              debtToken: {
                ...state.pools[pool.id].debtToken,
                price: debtTokenPrice,
                priceInUSD: debtTokenPrice
                  ? formatUnits(debtTokenPrice, state.pools[pool.id].debtToken.decimals)
                  : undefined,
              },
              collateralToken: {
                ...state.pools[pool.id].collateralToken,
                price: collateralTokenPrice,
                priceInUSD: collateralTokenPrice
                  ? formatUnits(collateralTokenPrice, state.pools[pool.id].collateralToken.decimals)
                  : undefined,
              },
              availableLiquidity: parseFloat(
                formatUnits(pool.availableLiquidity, state.pools[pool.id].debtToken.decimals)
              ),
              minAmountToBorrow: parseFloat(
                formatUnits(pool.minAmountToBorrow, state.pools[pool.id].debtToken.decimals)
              ),
              minAmountToDeposit: parseFloat(
                formatUnits(pool.minAmountToDeposit, state.pools[pool.id].debtToken.decimals)
              ),
              currentRoundIndex: pool.currentRoundIndex,
              ltv: parseFloat(formatUnits(ltv)),
              maxCap: parseInt(formatUnits(maxCap)),
              apy: 0.2, // TODO
              borrowingFee: POOL_META.borrowingFee, // TODO
              allowedLoanTerms: POOL_META.allowedLoanTerms,
              nextRoundTimestampRelative: roundsTimeStamp.nextRoundTimestampRelative,
              nextRoundTimestampRelativeWithoutSuffix:
                roundsTimeStamp.nextRoundTimestampRelativeWithoutSuffix,
              nextRoundTimestamp: roundsTimeStamp.nextRoundTimestamp,
              roundDurationInDays: roundsTimeStamp.roundDurationInDays,
              utilization,
              poolLoading: false,
              poolError: undefined,
            };
          },
          undefined,
          "updatePool"
        ),
    })),
    { name: "PoolsStore" }
  )
);
