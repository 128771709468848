import React from "react";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ActionButton, BaseFlex, InputSliderPair } from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";

export interface CancelPendingWithdrawalTransactionCardProps
  extends Pick<TransactionCardBaseProps, "fullScreen"> {
  totalPendingAmount: string;
  inputName: string;
  sliderName: string;
  /**true when at least one data fetch is still loading */
  loading: boolean;
  debtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
  activeLiquidity: string;
  willBeActiveIn: string;

  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const CancelPendingWithdrawal = ({
  totalPendingAmount,
  inputName,
  sliderName,
  loading,
  debtToken,
  activeLiquidity,
  willBeActiveIn,
  isSubmitting,
  onSubmit,
  isValid,
  fullScreen = false,
}: CancelPendingWithdrawalTransactionCardProps): JSX.Element => {
  const { watch, handleSubmit, formState } = useFormContext();

  const cancelAmount = watch(inputName);

  return (
    <BaseFlex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: "2em",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <BaseFlex sx={{ flexDirection: "column", width: "100%", marginTop: "0.5em" }}>
        <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
          <Box
            component="span"
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            <Typography
              variant="headline2"
              component="span"
              sx={{
                color: (theme) => theme.palette.common.white,
                whiteSpace: "pre-wrap",
              }}
            >
              Cancel Amount
            </Typography>
          </Box>

          <BaseFlex
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "0",
            }}
          >
            <Typography
              variant="caption2"
              component="span"
              sx={{ color: (theme) => theme.palette.primary[300] }}
            >
              Total Pending Amount:
            </Typography>
            <Typography
              variant="caption1"
              data-test="wallet-balance"
              component="span"
              sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
            >
              {loading || !totalPendingAmount
                ? "-"
                : `${commaSeparateNumbers(truncateDecimal(totalPendingAmount, 2), 2)} ${
                    debtToken.symbol
                  }`}
            </Typography>
          </BaseFlex>
        </BaseFlex>

        <InputSliderPair
          inputName={inputName}
          sliderName={sliderName}
          logo={debtToken.logo}
          max={totalPendingAmount}
          conversionRate={debtToken.price}
          scheme="secondary"
          disabled={loading}
        />
      </BaseFlex>

      <BaseFlex
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1em",
          marginTop: fullScreen ? "-1em" : 0,
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            paddingBottom: "1em",
          }}
        >
          New position
        </Typography>
        <DetailsWrapper>
          <Detail
            label="Active Liquidity"
            detail={`${commaSeparateNumbers(truncateDecimal(activeLiquidity))} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail
            label="Pending withdrawal"
            detail={`${commaSeparateNumbers(
              truncateDecimal(
                (parseFloat(totalPendingAmount) - parseFloat(cancelAmount || "0")).toString()
              )
            )} ${debtToken.symbol}`}
            loading={loading}
          />
          <Detail label="Will Be Available In" detail={willBeActiveIn} loading={loading} />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ width: "100%", flexDirection: "column" }}>
        <BaseFlex sx={{ width: "100%" }}>
          <ActionButton
            scheme="secondary"
            disabled={!isValid || isSubmitting || loading || !formState.isValid}
            pendingAction={isSubmitting}
            type="submit"
            fullWidth
          >
            {isSubmitting ? "Cancelling Withdraw" : "Cancel withdraw"}
          </ActionButton>
        </BaseFlex>
      </BaseFlex>
    </BaseFlex>
  );
};

export default CancelPendingWithdrawal;
