import React, { Fragment } from "react";
import { CellData, CellLoader } from "../TableComponents";
import { LineGrid } from "./UserLiquidityComponents";

const UserLiquidityLoader = (): JSX.Element => {
  return (
    <Fragment>
      {[...Array(2)].map((_, index) => (
        <LineGrid className="lineGrid" key={index}>
          <CellData sx={{ gridArea: "pool", justifyContent: "start" }}>
            <CellLoader width="80%" />
          </CellData>

          <CellData sx={{ gridArea: "rewardsEarned" }}>
            <CellLoader width="60%" />
          </CellData>

          <CellData sx={{ gridArea: "totalSupplied" }}>
            <CellLoader width="60%" />
          </CellData>

          <CellData sx={{ gridArea: "action", justifyContent: "flex-end" }}>
            <CellLoader width="60%" />
          </CellData>
        </LineGrid>
      ))}
    </Fragment>
  );
};

export default UserLiquidityLoader;
