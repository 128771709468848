import { useCallback, useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { formatUnits, isAddress } from "ethers/lib/utils";
import { useBlockNumber } from "@lib/providers";
import { useWeb3 } from "@lib/hooks";

import { ERC20 } from "@coraprotocol/core/typechain";
import ERC20ABI from "@coraprotocol/core/abis/IERC20.json";

interface UseBalanceProps {
  tokenAddress: string;
  decimals?: number;
}

interface FetchState {
  balance?: {
    value: BigNumber;
    formatted: string;
  };
  loading: boolean;
  error?: {
    name: string;
    message: string;
  };
}

/**
 * base hook for fetching user balance
 */
const useERCBalance = ({ tokenAddress, decimals }: UseBalanceProps): FetchState => {
  const { library, account } = useWeb3();
  const blockNumber = useBlockNumber();
  const [fetchState, setFetchState] = useState<FetchState>({ loading: true });

  const fetchBalance = useCallback(
    async ({
      tokenAddress,
      account,
      formatDecimal = 18,
    }: {
      tokenAddress: string;
      account: string;
      formatDecimal?: number;
    }) => {
      try {
        if (!isAddress(tokenAddress)) throw new Error("Invalid token address");

        setFetchState((state) => ({ ...state, loading: !state.balance?.value, error: undefined }));

        const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, library) as ERC20;
        const value = await tokenContract.balanceOf(account);

        setFetchState((state) => ({
          ...state,
          balance: {
            value,
            formatted: formatUnits(value, formatDecimal),
          },
          loading: false,
        }));
      } catch (error: any) {
        setFetchState({
          balance: undefined,
          loading: false,
          error: {
            name: error?.name || "useERCBalance error",
            message: error?.message || "Error while fetching balance",
          },
        });
      }
    },
    [tokenAddress, library, account]
  );

  useEffect(() => {
    if (!tokenAddress) return;
    if (!library) return;
    if (!account) return;

    let cancelled = false;

    if (cancelled) return;

    fetchBalance({ tokenAddress, account, formatDecimal: decimals });

    return () => {
      cancelled = true;
    };
  }, [account, tokenAddress]);

  useEffect(() => {
    if (!tokenAddress) return;
    if (!account) return;
    if (!library) return;
    if (!blockNumber) return;

    fetchBalance({ tokenAddress, account, formatDecimal: decimals });
  }, [blockNumber]);

  return fetchState;
};

export default useERCBalance;
