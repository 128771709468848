import React from "react";
import Typography from "@mui/material/Typography";
import { ActionButton, BaseFlex, ExclamationIcon, SpinnerTwo } from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";

export interface RepayTransactionCardProps extends Pick<TransactionCardBaseProps, "fullScreen"> {
  loading: boolean;
  walletBalance: string;
  repaymentAmount: string;
  debtExpirationDate: string;
  totalDebt: string;
  borrowingFeePaid: string;
  collateralLocked: string;
  approveButtonIsDisabled: boolean;
  approveButtonOnClick: () => void;
  isApproving: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
  error?: string;
}

const Repay = ({
  loading,
  walletBalance,
  repaymentAmount,
  debtExpirationDate,
  totalDebt,
  borrowingFeePaid,
  collateralLocked,
  approveButtonIsDisabled,
  approveButtonOnClick,
  isApproving,
  onSubmit,
  isSubmitting,
  isValid,
  error,
}: Omit<RepayTransactionCardProps, "input" | "slider">): JSX.Element => {
  return (
    <BaseFlex
      as="form"
      onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
        e.preventDefault();
        onSubmit();
      }}
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: error ? "1em" : "2em",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <DetailsWrapper
        sx={{
          marginTop: "0.5em",
        }}
      >
        <Detail
          label={
            <Typography
              variant="headline2"
              component="span"
              sx={{
                color: (theme) => theme.palette.common.white,
                whiteSpace: "pre-wrap",
              }}
            >
              Wallet Balance
            </Typography>
          }
          detail={
            <Typography
              variant="caption1"
              component="span"
              sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
            >
              {walletBalance}
            </Typography>
          }
          loading={loading}
          subDetail={
            error && (
              <BaseFlex
                role="alert"
                sx={(theme) => ({
                  ...theme.typography.caption1,
                  color: (theme) => theme.palette.error[300],
                  padding: "2px 0 0",
                  justifyContent: "flex-start",
                  textDecoration: "italic",
                  gap: 0,
                })}
              >
                <ExclamationIcon sx={{ color: (theme) => theme.palette.error[300] }} />
                {error}
              </BaseFlex>
            )
          }
        />
      </DetailsWrapper>

      <BaseFlex
        sx={{
          width: "100%",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <Typography
          variant="headline2"
          component="span"
          sx={{
            color: (theme) => theme.palette.common.white,
            whiteSpace: "pre-wrap",
            marginRight: "auto",
          }}
        >
          Summary
        </Typography>

        <DetailsWrapper>
          <Detail label="Repay Amount" detail={repaymentAmount} loading={loading} />
          <Detail label="Collateral Locked" detail={collateralLocked} loading={loading} />
          <Detail label="Debt Expiration" detail={debtExpirationDate} loading={loading} />
          <Detail label="Total Debt" detail={totalDebt} loading={loading} />
          <Detail label="Borrowing Fee Paid" detail={borrowingFeePaid} loading={loading} />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ width: "100%", flexDirection: "column", marginTop: "1em" }}>
        <BaseFlex sx={{ width: "100%" }}>
          <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
            <ActionButton
              data-test="approve-button"
              onClick={approveButtonOnClick}
              disabled={isApproving || approveButtonIsDisabled}
              pendingAction={isApproving}
            >
              {isApproving && <SpinnerTwo />}
              {isApproving ? "Approving" : "Approve"}
            </ActionButton>
          </BaseFlex>
          <ActionButton
            type="submit"
            data-test="repay-button"
            disabled={!isValid || isSubmitting || loading}
            pendingAction={isSubmitting}
          >
            {isSubmitting && <SpinnerTwo />}
            {isSubmitting ? "Repaying" : "Repay"}
          </ActionButton>
        </BaseFlex>

        {!approveButtonIsDisabled && !loading && (
          <BaseFlex sx={{ fontSize: "13px", width: "100%", justifyContent: "left" }}>
            Allow the Cora Protocol to use your token on your behalf.
          </BaseFlex>
        )}
      </BaseFlex>
    </BaseFlex>
  );
};

export default Repay;
