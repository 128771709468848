import { hexToRgba } from "@lib/utils";
import { styled } from "@mui/material";
import ToggleButton, { toggleButtonClasses, ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const TogglerGroup = styled(ToggleButtonGroup)({});

export const TogglerButton = styled(ToggleButton)<ToggleButtonProps>((props) => ({
  color: hexToRgba(props.theme.palette.common.white, 0.5),
  border: "none",
  borderRadius: 0,
  width: "100%",
  borderBottom: `4px solid ${hexToRgba(props.theme.palette.common.white, 0.5)}`,
  fontSize: "1rem",

  [`&.${toggleButtonClasses.selected}`]: {
    color: props.theme.palette.common.white,
    backgroundColor: "transparent",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    borderBottomColor: props.theme.palette.secondary.main,
  },
}));
