import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useContext,
} from "react";

interface ToggleI {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ToggleContext = createContext<ToggleI>({ open: false, setOpen: () => undefined });

const ToggleProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  return <ToggleContext.Provider value={{ open, setOpen }}>{children}</ToggleContext.Provider>;
};

export default ToggleProvider;

export const useToggle = (): ToggleI => {
  return useContext(ToggleContext);
};
