import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { BaseBox } from "@components/common";

interface ConnectWalletProps {
  onClick: any;
}

const ConnectWallet = ({ onClick }: ConnectWalletProps): JSX.Element => {
  return (
    <ButtonBase
      id="connect-wallet"
      onClick={onClick}
      sx={{
        padding: 0,
      }}
    >
      <BaseBox
        sx={(theme) => ({
          ...theme.typography.buttonM,
          color: (theme) => theme.palette.dark.main,
          backgroundColor: (theme) => theme.palette.secondary.main,
          borderRadius: "1rem",
        })}
      >
        Connect wallet
      </BaseBox>
    </ButtonBase>
  );
};

export default ConnectWallet;
