import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex } from "@components/common";
import { UpArrow } from "@components/common/Icons";
import { TableContainer } from "@components/common/Table/TableComponents";
import {
  Header,
  Wrapper,
  Message,
  UserLiquidityHeader,
} from "@components/common/Table/UserLiquidity/UserLiquidityComponents";

const UserLiquidity = ({ withLabel = true }: { withLabel?: boolean }): JSX.Element => {
  return (
    <TableContainer sx={{ paddingBottom: 0 }}>
      {withLabel ? (
        <Header sx={{ flexDirection: "column", alignItems: "flex-start" }}>
          <BaseFlex
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <BaseFlex className="apy">
              <UpArrow height="21px" width="21px" />
              Your Provided Liquidity
            </BaseFlex>
          </BaseFlex>
          <span className="total" data-test="user-total-supplied">
            $0.00
          </span>
        </Header>
      ) : (
        <Header>
          <span className="total" data-test="user-total-supplied">
            $0.00
          </span>
        </Header>
      )}

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <UserLiquidityHeader />

          <div>
            <Message message="There has been an error." />
          </div>
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default UserLiquidity;
