import { TransactionDetailBase, CancelDepositTransactionInfo } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingCancelDepositTransactionByPool = (
  poolId: string
): TransactionDetailBase<CancelDepositTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.CancelDeposit)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (cancelDeposit): cancelDeposit is TransactionDetailBase<CancelDepositTransactionInfo> =>
        !cancelDeposit.isMined
    )
    .filter((cancelDeposit) => cancelDeposit.transactionInfo.lendingPoolId === poolId)[0];
};

export const usePendingCancelDepositTransactions =
  (): TransactionDetailBase<CancelDepositTransactionInfo>[] => {
    const transactions = useAllTransactions();

    return transactions
      .filter((transaction) => transaction.transactionInfo.type === TransactionType.CancelDeposit)
      .filter((transaction) => !transaction.error)
      .filter((transaction) => !transaction.isCancelled)
      .filter(
        (cancelDeposit): cancelDeposit is TransactionDetailBase<CancelDepositTransactionInfo> =>
          !cancelDeposit.isMined
      );
  };
