import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { HintTooltip } from "@components/common";

export const LinksContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
});

interface LinksProps {
  title: string;
  links: Array<{
    label: string;
    url?: string;
  }>;
}

const LinkGroup = ({ title, links }: LinksProps): JSX.Element => {
  return (
    <LinksContainer>
      <Typography
        variant="caption2"
        sx={{
          color: (theme) => theme.palette.primary[300],
          marginBottom: "0.2rem",
        }}
      >
        {title}
      </Typography>

      {links.map((link, index) => {
        if (!link.url) {
          return (
            <HintTooltip key={`${index}-${link.label}`} title="Coming soon">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.palette.primary[300], cursor: "not-allowed" }}
              >
                {link.label}
              </Typography>
            </HintTooltip>
          );
        }

        return (
          <MuiLink key={link.url} href={link.url} rel="noopener noreferrer" target="_blank">
            <Typography variant="body1" sx={{ color: (theme) => theme.palette.common.white }}>
              {link.label}
            </Typography>
          </MuiLink>
        );
      })}
    </LinksContainer>
  );
};

export default LinkGroup;
