import { PoolMetaI } from "@lib/types";

export const POOL_META: PoolMetaI = {
  collateralTokenLogoUrl: "/assets/tokenImages/Ethereum-ETH-icon.png",
  stableCoinTokenlogoUrl: "/assets/tokenImages/usd-coin-usdc-logo.png",
  withdrawalFee: 1.34,
  strategyDetails: "This pool will sell options 20% OTM.",
  // the following should be from onchain data
  borrowingFee: "1.0% - 3.5%",
  allowedLoanTerms: [86400, 129600, 172800],
  // allowedLoanTerms: [604800, 1209600, 1814400, 2419200], // localhost
};
