import React, { ChangeEvent } from "react";
import { BaseComponentProps } from "styles/theme";
import { BaseFlex, SearchIcon } from "@components/common";
import { Wrapper, SearchInput } from "./SearchFilterComponents";

export interface SearchProps extends BaseComponentProps {
  inputPlaceholder: string;
  inputValue: string;
  onInputChange?: (event: ChangeEvent<HTMLInputElement> | string) => void;
}

/**
 * @dev for the MVP the token type filter will not be needed
 */
const SearchFilter = ({
  inputPlaceholder,
  inputValue,
  onInputChange,
}: SearchProps): JSX.Element => {
  return (
    <Wrapper>
      <BaseFlex sx={{ justifyContent: "space-between" }}>
        <BaseFlex sx={{ width: "100%", padding: "1rem 0" }}>
          <SearchIcon
            sx={{
              fontSize: "1.14rem",
            }}
          />
          <SearchInput value={inputValue} onChange={onInputChange} placeholder={inputPlaceholder} />
        </BaseFlex>
      </BaseFlex>
    </Wrapper>
  );
};

export default SearchFilter;
