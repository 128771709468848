import { usePoolsStore } from "@lib/providers";
import { PoolI } from "@lib/types";

interface UsePoolReturn {
  loading: boolean;
  error: any;
  lendingPools: Array<
    Pick<
      PoolI,
      | "id"
      | "name"
      | "debtToken"
      | "collateralToken"
      | "availableLiquidity"
      | "utilization"
      | "maxCap"
      | "apy"
      | "ltv"
      | "borrowingFee"
      | "collateralTokenLogoUrl"
      | "stableCoinTokenlogoUrl"
      | "priceFeed"
      | "allowedLoanTerms"
      | "nextRoundTimestamp"
      | "nextRoundTimestampRelativeWithoutSuffix"
    > & {
      // for easier sorting
      collateralName: string;
      stablecoinName: string;
    }
  >;
}

const usePools = (): UsePoolReturn => {
  const pools = usePoolsStore((state) => state.pools);
  const loading = usePoolsStore((state) => state.loading);
  const error = usePoolsStore((state) => state.error);

  return {
    lendingPools: Object.values(pools).map((pool) => ({
      ...pool,
      stablecoinName: pool.debtToken.name,
      collateralName: pool.collateralToken.name,
    })),
    error,
    loading,
  };
};

export default usePools;
