import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ErrorBoxIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="23" height="19" rx="2" stroke="white" fill="none" />
      <rect x="2.80005" y="2.80005" width="18.4" height="0.8" fill="white" />
      <rect
        x="8.80005"
        y="8.40015"
        width="1.2"
        height="8.4"
        transform="rotate(-45 8.80005 8.40015)"
        fill="#E34747"
      />
      <rect
        width="1.2"
        height="8.4"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.6001 8.40015)"
        fill="#E34747"
      />
    </SvgIcon>
  );
};

export default ErrorBoxIcon;
