import React, { SVGProps } from "react";

const UpArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.49998 10.4L11.2 7.70001M11.2 7.70001L13.9 10.4M11.2 7.70001L11.2 14.9M11.2 3.20001C15.6735 3.20001 19.3 6.82651 19.3 11.3C19.3 15.7735 15.6735 19.4 11.2 19.4C6.72647 19.4 3.09998 15.7735 3.09998 11.3C3.09998 6.82651 6.72647 3.20001 11.2 3.20001Z"
        stroke="#17CEB9"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpArrow;
