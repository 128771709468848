import React from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { BaseFlex } from "@components/common";
import {
  ExternalLinkIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ClockIcon,
  ExclamationIcon,
} from "@components/common/Icons";
import { DataContainer, TopContainer, BottomContainer } from "./TransactionBoxComponents";
import { TransactionStep, TransactionType } from "@lib/types";
import { commaSeparateNumbers, hexToRgba, truncateDecimal } from "@lib/utils";
import { Typography } from "@mui/material";

export interface TransactionBoxProps {
  transactionTitle: string;
  transactionType: TransactionType;
  status: TransactionStep;
  dateTime: string;
  tokenValue?: string;
  fiatValueUSD?: string;
  explorerLink: string;
  variant?: "dark" | "light";
}

const TransactionBox = ({
  transactionTitle,
  transactionType,
  dateTime,
  tokenValue,
  fiatValueUSD,
  explorerLink,
  status,
  variant = "light",
}: TransactionBoxProps): JSX.Element => {
  return (
    <BaseFlex sx={{ flexDirection: "column", gap: "1em", padding: "1em 0" }}>
      <TopContainer>
        <Box sx={{ height: "40px", width: "40px", flexGrow: 0 }}>
          {transactionType === TransactionType.Deposit ||
          transactionType === TransactionType.Repay ||
          transactionType === TransactionType.Approve ? (
            <PlusCircleIcon
              sx={{
                height: "40px",
                width: "40px",
                color: (theme) =>
                  variant === "light" ? theme.palette.success[100] : theme.palette.success[900],
              }}
            />
          ) : (
            <MinusCircleIcon
              sx={{
                height: "40px",
                width: "40px",
                color: (theme) =>
                  variant === "light" ? theme.palette.error[100] : theme.palette.error[900],
              }}
            />
          )}
        </Box>
        <BaseFlex
          style={{ justifyContent: "space-between", flexGrow: 1, gap: 0, alignItems: "flex-start" }}
        >
          <DataContainer>
            <Typography
              variant="body1"
              sx={{
                color: (theme) =>
                  variant === "light" ? theme.palette.dark.main : theme.palette.common.white,
              }}
            >
              {transactionTitle}
            </Typography>

            <Typography
              variant="caption2"
              sx={{
                color: (theme) =>
                  variant === "light" ? theme.palette.primary[400] : theme.palette.primary[300],
              }}
            >
              {transactionType} - {dateTime}
            </Typography>
          </DataContainer>

          <DataContainer sx={{ alignItems: "flex-end" }}>
            <BaseFlex style={{ display: "flex", gap: "0.5em" }}>
              <Typography
                variant="body1"
                sx={{
                  color: (theme) =>
                    variant === "light" ? theme.palette.primary[400] : theme.palette.primary[300],
                }}
              >
                {tokenValue}
              </Typography>
              <MuiLink
                href={explorerLink}
                rel="noopener noreferrer"
                target="_blank"
                sx={{ display: "flex" }}
              >
                <ExternalLinkIcon
                  sx={{
                    height: "17px",
                    width: "17px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </MuiLink>
            </BaseFlex>

            <Typography
              variant="caption2"
              sx={{
                color: (theme) =>
                  variant === "light" ? theme.palette.primary[400] : theme.palette.primary[300],
              }}
            >
              {fiatValueUSD ? `≈ ${commaSeparateNumbers(truncateDecimal(fiatValueUSD))} USD` : ""}
            </Typography>
          </DataContainer>
        </BaseFlex>
      </TopContainer>

      {status === TransactionStep.MINING && (
        <BottomContainer>
          <ClockIcon sx={{ height: "16px", color: (theme) => theme.palette.primary[100] }} />{" "}
          <span>Waiting for blockchain confirmation</span>
        </BottomContainer>
      )}

      {status === TransactionStep.INDEXING && (
        <BottomContainer>
          <ClockIcon sx={{ height: "16px" }} /> <span>Waiting for subgraph indexing</span>
        </BottomContainer>
      )}

      {status === TransactionStep.FAIL && (
        <BottomContainer sx={{ backgroundColor: "rgba(227, 71, 71, 0.7)", gap: 0 }}>
          <ExclamationIcon sx={{ height: "20px" }} />
          <span>Transaction failed</span>
        </BottomContainer>
      )}

      {status === TransactionStep.CANCELLED && (
        <BottomContainer
          sx={{
            backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.1),
            gap: 0,
            color: (theme) => hexToRgba(theme.palette.common.white, 0.5),
          }}
        >
          <ExclamationIcon sx={{ height: "20px" }} />
          <span>Transaction cancelled</span>
        </BottomContainer>
      )}
    </BaseFlex>
  );
};

export default TransactionBox;
