import React from "react";
import { styled } from "@mui/material";
import ButtonBase, { ButtonBaseProps, buttonBaseClasses } from "@mui/material/ButtonBase";

type MaxButtonProps = ButtonBaseProps;

export const MaxButton = (props: MaxButtonProps): JSX.Element => {
  return (
    <ButtonBase
      {...props}
      sx={(theme) => ({
        ...theme.typography.buttonM,
        backgroundColor: "transparent",
        cursor: "pointer",
        color: theme.palette.secondary.main,

        [`&.${buttonBaseClasses.disabled}`]: {
          pointerEvents: "auto",
          cursor: "not-allowed",
          opacity: 0.5,
        },
      })}
    >
      Max
    </ButtonBase>
  );
};

export const Wrapper = styled("div")(({ theme }) => ({
  ...theme.typography.caption1,
  width: "100%",
  borderRadius: "0.5rem",
  color: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
}));

export const CustomInput = styled("input")(({ theme }) => ({
  ...theme.typography.numbers2,
  width: "100%",
  backgroundColor: "transparent",
  color: theme.palette.common.white,
  border: "none",
  outline: "none",
  textOverflow: "ellipsis",

  "::placeholder": {
    color: theme.palette.primary.main,
  },

  "&:disabled": {
    cursor: "not-allowed",
  },
}));
