import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TransactionCardBase } from "@components/common";
import { TransactionCardBaseProps } from "@components/common/TransactionCard/TransactionCardBase";
import WithdrawAvailable from "./WithdrawAvailable/WithdrawAvailable";
import { gradient4 } from "styles/color";

export type WithdrawCollateralModalProps = Omit<TransactionCardBaseProps, "children"> & {
  poolAddress: string;
};

const WithdrawAvailableModal = ({
  poolAddress,
  open,
  onClose,
}: WithdrawCollateralModalProps): JSX.Element => {
  return (
    <TransactionCardBase open={open} onClose={onClose}>
      <Typography
        variant="headline1"
        sx={{
          textAlign: "center",
          width: "100%",
          color: (theme) => theme.palette.common.white,
          padding: "0.8em",
        }}
      >
        Withdraw
      </Typography>

      <Box sx={{ height: "4px", background: gradient4, width: "100%" }} />

      <WithdrawAvailable poolAddress={poolAddress} />
    </TransactionCardBase>
  );
};

export default WithdrawAvailableModal;
