import { UserLoan } from "@lib/types";
import { getCollateralRatio } from "@lib/utils";
import { formatUnits } from "ethers/lib/utils";
import { GetLoanReturn } from "./useUserLoans";

export interface LoanPayload {
  loans: GetLoanReturn;
  pool: UserLoan["pool"];
}

export const reshapeLoanList = ({ loans, pool }: LoanPayload): Record<UserLoan["id"], UserLoan> => {
  if (!loans) return {};

  const loanList = Object.keys(loans).reduce<Record<string, UserLoan>>((acc, loanId) => {
    const loan = loans[loanId];
    const key = `${loanId}-${loan.borrower}-${pool.id}`;

    const collateral = formatUnits(loan.collateral, pool.collateralToken.decimals);
    const netDebt = formatUnits(loan.netDebt, pool.debtToken.decimals);
    const borrowingFee = formatUnits(loan.borrowingFee, pool.debtToken.decimals);
    const expirationTime = parseInt(loan.expirationTime.toString());
    const isExpired = expirationTime * 1000 < Date.now();
    const collateralRatio = getCollateralRatio(
      parseFloat(collateral),
      parseFloat(netDebt),
      parseFloat(pool.collateralToken.priceInUSD as string)
    );
    const repaymentAmount = loan.netDebt.add(loan.borrowingFee);
    const repaymentAmountFormatted = formatUnits(repaymentAmount, pool.debtToken.decimals);

    acc[key] = {
      id: loanId,
      collateral,
      netDebt,
      collateralRatio,
      borrowingFee,
      expirationTime,
      isExpired,
      borrower: loan.borrower,
      paid: loan.paid,
      pool,
      repaymentAmount: repaymentAmountFormatted,
      raw: {
        collateral: loan.collateral,
        netDebt: loan.netDebt,
        borrowingFee: loan.borrowingFee,
        repaymentAmount,
      },
    };

    return acc;
  }, {});

  return loanList;
};
