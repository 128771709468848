import React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { hexToRgba } from "@lib/utils";

export type SpinnerProps = CircularProgressProps & { dark?: boolean };

const Spinner = (props: SpinnerProps): JSX.Element => {
  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => (props.dark ? hexToRgba(theme.palette.dark.main, 0.5) : "#FFF"),
          opacity: 0.2,
        }}
        size={21}
        thickness={5}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        value={25}
        disableShrink
        sx={{
          color: (theme) =>
            props.dark ? theme.palette.primary.main : theme.palette.secondary.main,
          animationDuration: "1550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
            strokeDasharray: "122.522px", //from browser inspection
            strokeDashoffset: "91.892px", //from browser inspection
          },
        }}
        size={21}
        thickness={5}
        {...props}
      />
    </Box>
  );
};

export default Spinner;
