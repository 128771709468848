import React from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: "0.5rem",
  padding: "0 0 0 1.2em",
  border: `1px solid ${theme.palette.primary[400]}`,
}));

export const SearchInput = styled("input")(({ theme }) => ({
  ...theme.typography.body1,
  width: "100%",
  backgroundColor: "transparent",
  color: theme.palette.common.white,
  border: "none",
  outline: "none",

  "::placeholder": {
    color: theme.palette.common.white,
  },
}));

export const FilterMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: "0.5rem",
    minWidth: "9.875rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.common.white,
    transform: "translateY(10%)",

    "& .MuiMenu-list": {
      padding: "0.3rem",
    },
  },
}));

export const FilterItem = styled((props: MenuItemProps & { active?: boolean }) => (
  <MenuItem disableRipple dense {...props} />
))(({ theme, active = false }) => ({
  ...theme.typography.body1,
  marginBottom: "0.2rem",
  padding: "0 1rem",
  borderRadius: "0.25rem",
  color: active ? theme.palette.common.white : theme.palette.primary.main,
  transition: "background-color 0.1s ease-in-out, color 0.1s ease-in-out",
  backgroundColor: active ? theme.palette.primary.main : "transparent",

  ":hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));
