import React from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import Box from "@mui/system/Box";
import MuiLink from "@mui/material/Link";
import { Navlink, ChainMenu, CoraLogo, BaseFlex } from "@components/common";
import { AccountNavButton } from "@components/ui";
import { pathNames } from "@lib/constants";
import { networksIncludedInChainMenu } from "@lib/connectors";

// NOTE: wrap/unwrap page is removed for v1
const DesktopNavbar = (): JSX.Element => {
  const router = useRouter();

  return (
    <BaseFlex
      as="nav"
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: {
          md: "0.9375rem 0.9375rem 0.9375rem 1.75rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: {
            xs: "25rem",
            lg: "36.25rem",
          },
          gap: {
            xs: "0.5rem",
            md: "2.5rem",
            lg: "5.75rem",
          },
          marginRight: {
            xs: "0.5rem",
            md: "2.5rem",
          },
        }}
      >
        <NextLink href={pathNames.supply} passHref>
          <MuiLink sx={{ display: "flex" }}>
            <CoraLogo />
          </MuiLink>
        </NextLink>

        <BaseFlex
          sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", gap: "2em" }}
        >
          <Navlink
            text="supply"
            url={pathNames.supply}
            selected={router.pathname === pathNames.supply}
          />
          <Navlink
            text="borrow"
            url={pathNames.borrow}
            selected={router.pathname === pathNames.borrow}
          />
          <Navlink
            text="portfolio"
            url={pathNames.portfolio}
            selected={
              router.pathname === pathNames.portfolio ||
              router.pathname === pathNames.managePortfolio
            }
          />
          {/* NOTE: hides unwrap/wrap link for v1 */}
          <Box sx={{ visibility: "hidden" }}>
            <Navlink
              text="wrap/unwrap eth"
              url={pathNames.wrapUnwrap}
              selected={router.pathname === pathNames.wrapUnwrap}
            />
          </Box>
        </BaseFlex>
      </Box>

      <Box sx={{ display: "flex", gap: "1.0625em" }}>
        <ChainMenu options={networksIncludedInChainMenu} />

        <AccountNavButton />
      </Box>
    </BaseFlex>
  );
};

export default DesktopNavbar;
