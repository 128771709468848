import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { fromSecondsToDays } from "@lib/utils/convertTime";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "Starts in %s",
    past: "Started %s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const nextRoundTimestamp = ({
  roundDuration,
  genesisRoundDuration,
  createdAtTimestamp,
  roundIndex,
}: {
  roundDuration: number;
  genesisRoundDuration: number;
  createdAtTimestamp: number;
  roundIndex: number;
}): number => {
  return createdAtTimestamp + genesisRoundDuration + roundIndex * roundDuration;
};

const nextRoundTimestampRelative = (nextRoundTimestamp: number): string => {
  return dayjs(dayjs.unix(nextRoundTimestamp)).fromNow();
};

const nextRoundTimestampRelativeWithoutSuffix = (nextRoundTimestamp: number): string => {
  return dayjs(dayjs.unix(nextRoundTimestamp)).fromNow(true);
};

const roundDurationInDays = (roundDuration: number): string => {
  return fromSecondsToDays(roundDuration);
};

const minimumLockUpPeriod = (nextRoundTimestamp: number): string => {
  return dayjs(dayjs.unix(nextRoundTimestamp)).fromNow(true);
};

const roundsTimeStamp = ({
  roundDuration,
  genesisRoundDuration,
  createdAtTimestamp,
  roundIndex,
}: {
  roundDuration: number;
  genesisRoundDuration: number;
  createdAtTimestamp: number;
  roundIndex: number;
}): {
  nextRoundTimestamp: number;
  nextRoundTimestampRelative: string;
  nextRoundTimestampRelativeWithoutSuffix: string;
  roundDurationInDays: string;
  minimumLockUpPeriod: string;
} => {
  const nextRoundTimestampConverted = nextRoundTimestamp({
    roundDuration,
    genesisRoundDuration,
    createdAtTimestamp,
    roundIndex,
  });
  return {
    nextRoundTimestamp: nextRoundTimestampConverted,
    nextRoundTimestampRelative: nextRoundTimestampRelative(nextRoundTimestampConverted),
    nextRoundTimestampRelativeWithoutSuffix: nextRoundTimestampRelativeWithoutSuffix(
      nextRoundTimestampConverted
    ),
    roundDurationInDays: roundDurationInDays(roundDuration),
    minimumLockUpPeriod: minimumLockUpPeriod(nextRoundTimestampConverted),
  };
};

export default roundsTimeStamp;
