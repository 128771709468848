import React from "react";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "@lib/createEmotionCache";
import { ToastContainer } from "react-toastify";
import { Web3Manager, BlockNumberProvider } from "@lib/providers";
import { Head } from "@components/common";
import { Layout, RootFallback } from "@components/ui";
import { UpdatePendingTransaction, ThirdPartyScripts } from "@components/helper";
import theme from "../styles/theme";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/inter";
import "react-toastify/dist/ReactToastify.min.css";
import "../styles/toastify.css";
import "../styles/global.css";
import { PoolsFetcher } from "@components/helper/PoolsFetcher";
import { Global, css } from "@emotion/react";

import { ErrorBoundary } from "@sentry/nextjs";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        font-size: 85%;
      }
    `}
  />
);

const MyApp = (props: MyAppProps): JSX.Element => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <Web3Manager>
      <CacheProvider value={emotionCache}>
        <ThirdPartyScripts />
        <GlobalStyles />
        <Head />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary showDialog fallback={RootFallback}>
            <BlockNumberProvider>
              <PoolsFetcher />
              <UpdatePendingTransaction />

              <Layout>
                <Component {...pageProps} />
              </Layout>

              <ToastContainer position="bottom-right" hideProgressBar limit={6} />
            </BlockNumberProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </CacheProvider>
    </Web3Manager>
  );
};

export default MyApp;
