import { useState, useEffect } from "react";
import { connector } from "@lib/connectors/";
import { useWeb3 } from "lib/hooks";

const useEagerConnect = (): boolean => {
  const { activate, active } = useWeb3();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    connector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(connector, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, []);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
};

export default useEagerConnect;
