import React, { Fragment, useEffect, useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { DownCaret } from "@components/common/Icons";
import { ConfirmMsg } from "@components/common/TransactionStatusMessage";
import { StyledMenu, StyledMenuItem } from "./ChainMenuComponents";
import { getChainInfo, switchNetworkHandler } from "@lib/utils";
import { ChainId } from "@lib/constants";
import { networksIncludedInChainMenu } from "@lib/connectors";
import { useChainSyncContext } from "@components/helper";
import { TransactionCardBase } from "../TransactionCard";

export interface ChainMenuProps {
  options: Array<ChainId>;
}

const ChainMenu = ({ options }: ChainMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedChain, setSelectedChain] = useState<ChainId | undefined>();
  const open = Boolean(anchorEl);
  const { chainIdSynced: chainId, manualSetChainIdHandle } = useChainSyncContext();

  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, chainId: ChainId) => {
    if (selectedChain === chainId) {
      return setAnchorEl(null);
    }

    if (!window?.ethereum?.isMetaMask) {
      manualSetChainIdHandle(chainId);
      setSelectedChain(chainId);
    } else {
      setShowConfirmMessage(true);

      switchNetworkHandler({ chainId })
        .then(() => {
          setSelectedChain(chainId);
          toast.success(`Connected to ${getChainInfo(chainId)?.name}`);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setShowConfirmMessage(false);
        });
    }

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (chainId) {
      setSelectedChain(networksIncludedInChainMenu.includes(chainId) ? chainId : undefined);
    } else {
      setSelectedChain(undefined);
    }
  }, [chainId]);

  return (
    <Fragment>
      <ButtonBase
        id="chain-menu"
        onClick={handleClick}
        sx={{
          marginRight: {
            xs: "1rem",
            lg: "1.937rem",
          },
          padding: {
            xs: "0.5rem 0rem 0.5rem 1rem",
            lg: "0.5rem 0 0.5rem 1.937rem",
          },
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          variant="buttonM"
          sx={{
            minWidth: "6.25rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "end",
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          {selectedChain ? getChainInfo(selectedChain)?.name : "Network"}
        </Typography>

        <DownCaret
          sx={{
            margin: "0 0 0 0.5rem",
            color: (theme) => theme.palette.secondary.main,
            transition: "transform 0.2s ease-in-out",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </ButtonBase>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((chainId) => {
          const { name, disabled } = getChainInfo(chainId);

          return (
            <StyledMenuItem
              id={`chain-menu-item-${chainId}`}
              key={chainId}
              onClick={(event) => handleMenuItemClick(event, chainId)}
              disabled={disabled}
              active={selectedChain === chainId}
            >
              {name}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>

      <TransactionCardBase onClose={() => setShowConfirmMessage(false)} open={showConfirmMessage}>
        <ConfirmMsg title="Confirm network change  on your wallet" show />
      </TransactionCardBase>
    </Fragment>
  );
};

export default ChainMenu;
