import {
  TransactionDetailBase,
  WithdrawAvailableTransactionInfo,
} from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";
import { useAllTransactions } from "./useLocalTransactions";

export const usePendingWithdrawAvailableTransactionByPool = (
  poolId: string
): TransactionDetailBase<WithdrawAvailableTransactionInfo> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.WithdrawAvailable)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter(
      (
        withdrawAvailable
      ): withdrawAvailable is TransactionDetailBase<WithdrawAvailableTransactionInfo> =>
        !withdrawAvailable.isMined
    )
    .filter((withdrawAvailable) => withdrawAvailable.transactionInfo.lendingPoolId === poolId)[0];
};

export const usePendingWithdrawAvailableTransactions =
  (): TransactionDetailBase<WithdrawAvailableTransactionInfo>[] => {
    const transactions = useAllTransactions();

    return transactions
      .filter(
        (transaction) => transaction.transactionInfo.type === TransactionType.WithdrawAvailable
      )
      .filter((transaction) => !transaction.error)
      .filter((transaction) => !transaction.isCancelled)
      .filter(
        (
          withdrawAvailable
        ): withdrawAvailable is TransactionDetailBase<WithdrawAvailableTransactionInfo> =>
          !withdrawAvailable.isMined
      );
  };
