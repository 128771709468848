import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { BaseButton, BaseFlex, SpinnerTwo } from "@components/common";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { hexToRgba } from "@lib/utils";

export const Wrapper = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "1rem",
        backgroundColor: (theme) => hexToRgba(theme.palette.primary.main, 0.4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1.9375rem 2rem",
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
      }}
    >
      {children}
    </Box>
  );
};

interface ActionButtonProps {
  name: string;
  handleClick: () => void;
  disabled?: boolean;
  isTransacting?: boolean;
  outline?: boolean;
}

export const ActionButton = ({
  name,
  handleClick,
  disabled,
  isTransacting = false,
  outline = false,
}: ActionButtonProps): JSX.Element => {
  return (
    <BaseButton
      onClick={handleClick}
      disabled={disabled || isTransacting}
      sx={(theme) => ({
        ...theme.typography.buttonL,
        display: "inline-flex",
        gap: "0.5rem",
        borderRadius: "0.5rem",
        padding: "1rem 1.5rem",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.dark.main,
        width: "100%",
        minWidth: "0px",
        transition:
          "background-color 0.4s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out",

        "&:disabled": {
          backgroundColor: hexToRgba(theme.palette.secondary.main, 0.2),
          color: hexToRgba(theme.palette.dark.main, 0.4),
        },

        ":hover": {
          backgroundColor: theme.palette.secondary[600],
          color: theme.palette.dark.main,

          ...(outline && {
            backgroundColor: hexToRgba(theme.palette.secondary.main, 0.2),
            color: theme.palette.secondary.main,
          }),
        },

        ...(outline && {
          backgroundColor: "transparent",
          color: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main}`,
        }),
      })}
    >
      {isTransacting && <SpinnerTwo />} {name}
    </BaseButton>
  );
};

interface DetailProps {
  label: string;
  detail?: string;
  loading?: boolean;
  actionButton?: ReactNode;
}

export const Detail = ({
  label,
  detail,
  loading = false,
  actionButton,
}: DetailProps): JSX.Element => {
  return (
    <Box
      className="detail"
      sx={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "1fr 0.8fr 0.8fr",
        padding: "0.9375rem 0",
        color: (theme) => theme.palette.common.white,
      }}
    >
      <Typography component="span" variant="body1">
        {label}:
      </Typography>
      <Box component="span">
        {loading ? (
          <Skeleton
            variant="text"
            sx={{
              backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.3),
              width: "7rem",
              marginLeft: "auto",
            }}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="headline2" component="span" sx={{ textAlign: "right" }}>
              {detail}
            </Typography>
          </Box>
        )}
      </Box>

      <BaseFlex
        sx={{
          justifyContent: "flex-end",
          marginLeft: "auto",
        }}
      >
        {actionButton}
      </BaseFlex>
    </Box>
  );
};

export const DetailActionButton = ({
  name,
  handleClick,
  disabled,
  isTransacting = false,
  outline = false,
}: ActionButtonProps): JSX.Element => {
  return (
    <BaseButton
      onClick={handleClick}
      disabled={disabled || isTransacting}
      sx={(theme) => ({
        ...theme.typography.buttonM,
        display: "inline-flex",
        gap: "0.5rem",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.dark.main,
        minWidth: "6.25rem",
        transition:
          "background-color 0.4s ease-in-out, outline-color 0.2s ease-in-out, color 0.2s ease-in-out",

        "&:disabled": {
          backgroundColor: hexToRgba(theme.palette.secondary.main, 0.2),
          color: hexToRgba(theme.palette.dark.main, 0.4),
        },

        ":hover": {
          backgroundColor: theme.palette.secondary[600],
          color: theme.palette.dark.main,

          ...(outline && {
            backgroundColor: theme.palette.secondary[600],
            color: theme.palette.dark.main,
          }),
        },

        ...(outline && {
          backgroundColor: "transparent",
          color: theme.palette.secondary.main,
          outline: `1px solid ${theme.palette.secondary.main}`,
        }),
      })}
    >
      {isTransacting && <SpinnerTwo />}
      {name}
    </BaseButton>
  );
};
