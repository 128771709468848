import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { BaseFlex, Hint } from "@components/common";
import { TokenI } from "@lib/types";
import { commaSeparateNumbers, hexToRgba } from "@lib/utils";

export interface UserLiquidityDetailsProps {
  loading: boolean;
  nft: ReactNode;
  providedLiquidity: string;
  pendingDeposit: string;
  activeLiquidity: string;
  token: TokenI;
}

const Detail = styled("div")(({ theme }) => ({
  color: theme.palette.common.white,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "right",
  textAlign: "right",

  "& .label": {
    color: theme.palette.primary[300],
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    gridArea: "label",
  },
}));

const Loader = styled(Skeleton)(({ theme }) => ({
  ...theme.typography.numbers2,
  width: "100%",
  backgroundColor: hexToRgba(theme.palette.common.white, 0.6),
  marginLeft: "auto",
}));

const UserLiquidityDetails = ({
  loading,
  nft,
  providedLiquidity,
  pendingDeposit,
  activeLiquidity,
  token,
}: UserLiquidityDetailsProps): JSX.Element => {
  return (
    <BaseFlex
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1em",
        width: "100%",
        marginBottom: "2em",
      }}
    >
      <Box sx={{ width: "128px", height: "128px" }}>{nft}</Box>

      <BaseFlex
        sx={{
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <Detail>
          <Typography variant="body2" className="label">
            Your Provided Liquidity
          </Typography>
          <Box component="span">
            {loading ? (
              <Loader />
            ) : (
              <Typography variant="numbers2" component="span">
                {commaSeparateNumbers(providedLiquidity)} {token?.symbol}
              </Typography>
            )}
          </Box>
        </Detail>

        <Detail>
          <Box className="label" component="span">
            <Typography variant="body2">Active Liquidity</Typography>
            <Hint title="This deposit is being used by the liquidity pool now." />
          </Box>
          <Box component="span">
            {loading ? (
              <Loader width="60%" />
            ) : (
              <Typography variant="numbers2" component="span">
                {commaSeparateNumbers(activeLiquidity)} {token?.symbol}
              </Typography>
            )}
          </Box>
        </Detail>

        <Detail>
          <Box className="label" component="span">
            <Typography variant="body2">Pending Deposit</Typography>
            <Hint title="This deposit will be active on the next round." />
          </Box>
          <Box component="span">
            {loading ? (
              <Loader />
            ) : (
              <Typography variant="numbers2" component="span">
                {commaSeparateNumbers(pendingDeposit)} {token?.symbol}
              </Typography>
            )}
          </Box>
        </Detail>
      </BaseFlex>
    </BaseFlex>
  );
};

export default UserLiquidityDetails;
