import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex, Search } from "@components/common";
import { ColumnHeader, TableContainer, SortDirectionAsc } from "../TableComponents";
import { LineGrid, Message, BorrowableItem } from "./CollateralComponents";
import { Wrapper } from "../Pool/PoolComponents";
import useSearch from "@lib/hooks/useSearch/";
import CollateralLoader from "./CollateralLoader";
import { ToggleProvider } from "@lib/providers";
import { PoolI } from "@lib/types";

export type CollateralParams = Pick<
  PoolI,
  | "id"
  | "collateralToken"
  | "borrowingFee"
  | "availableLiquidity"
  | "debtToken"
  | "ltv"
  | "collateralTokenLogoUrl"
  | "stableCoinTokenlogoUrl"
  | "allowedLoanTerms"
  | "nextRoundTimestamp"
  | "nextRoundTimestampRelativeWithoutSuffix"
> & { collateralName: string; stablecoinName: string };

export interface CollateralTableProps {
  error: boolean;
  loading: boolean;
  borrowableDataList: Array<CollateralParams>;
  onCollateralClick?: () => void;
  onStablecoinClick?: () => void;
  onBorrowingFeeClick?: () => void;
  onLiquidityClick?: () => void;
  onLTVClick?: () => void;
  sortedColumn?: CollateralColumns;
  sortDirection?: SortDirectionAsc;
  connected?: boolean;
}

export enum CollateralColumns {
  COLLATERAL = "collateralName",
  BORROWING_FEE = "borrowingFee",
  LIQUIDITY = "availableLiquidity",
  STABLECOIN = "stablecoinName",
  LTV = "ltv",
}

const borrowableSearch = (pool: CollateralParams, query: string): boolean =>
  pool.collateralName.toLowerCase().includes(query.toLowerCase());

const CollateralTable = ({
  error,
  loading,
  borrowableDataList,
  onCollateralClick,
  onStablecoinClick,
  onBorrowingFeeClick,
  onLiquidityClick,
  onLTVClick,
  sortedColumn,
  sortDirection,
  connected,
}: CollateralTableProps): JSX.Element => {
  const [searchedList, handleChange, queryString] = useSearch(borrowableDataList, borrowableSearch);

  const items =
    searchedList.length > 0 ? (
      searchedList.map((collateral, index) => {
        return (
          // this provider is used so that we can toggle close a parent modal from a child
          // like in this case the the modal should close when a transaction is sent
          // but the one calling the close function is a grandchild of this component
          <ToggleProvider key={collateral.id}>
            <BorrowableItem index={index} collateral={collateral} connected={connected} />
          </ToggleProvider>
        );
      })
    ) : (
      <Message message="No collaterals to show" />
    );

  return (
    <TableContainer sx={{ paddingBottom: 0 }}>
      <LineGrid
        sx={{
          marginBottom: 0,
          paddingTop: "0",
          paddingBottom: "2rem",
        }}
      >
        <BaseFlex sx={{ gridColumn: "collateral / action" }}>
          <Search
            inputPlaceholder="Search collateral"
            inputValue={queryString}
            onInputChange={handleChange}
          />
        </BaseFlex>
      </LineGrid>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid className="lineGrid">
            <ColumnHeader
              sx={{ padding: 0, gridArea: "collateral", justifyContent: "start" }}
              onClick={onCollateralClick}
            >
              Collateral
              {sortedColumn !== CollateralColumns.COLLATERAL || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "stablecoin" }} onClick={onStablecoinClick}>
              Stablecoin
              {sortedColumn !== CollateralColumns.STABLECOIN || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader
              sx={{ padding: 0, gridArea: "borrowingFee" }}
              onClick={onBorrowingFeeClick}
            >
              Borrowing Fee
              {sortedColumn !== CollateralColumns.BORROWING_FEE || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "liquidity" }} onClick={onLiquidityClick}>
              Liquidity
              {sortedColumn !== CollateralColumns.LIQUIDITY || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ padding: 0, gridArea: "ltv" }} onClick={onLTVClick}>
              LTV
              {sortedColumn !== CollateralColumns.LTV || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader
              sx={{
                padding: 0,
                gridArea: "wallet",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Wallet
            </ColumnHeader>
            <ColumnHeader
              sx={{
                padding: 0,
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          {!loading ? items : <CollateralLoader />}
          {error && !loading && <Message message="An error occured" />}
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default CollateralTable;
