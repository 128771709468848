const generateProjection = (
  startingDeposit: number,
  apy: number
): Array<{ month: string; value: number }> => {
  const projection = [];
  let currentDeposit = startingDeposit;
  for (let i = 0; i < 12; i++) {
    const month = new Date();
    month.setMonth(month.getMonth() + i);

    projection.push({
      month: month.toLocaleString("en-us", { month: "short" }),
      value: currentDeposit,
    });

    currentDeposit = currentDeposit + (apy * currentDeposit) / 12;
  }
  return projection;
};

export default generateProjection;
