import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { Chain, externalLinks } from "@lib/constants";
import { useWeb3 } from "@lib/hooks";

const FaucetMarquee = (): JSX.Element => {
  const { chainId } = useWeb3();
  const [faucetLink, setFaucetLink] = useState<string>(externalLinks.GOERLI_FAUCET);

  useEffect(() => {
    const faucetLink = (chainId?: Chain): string => {
      switch (chainId) {
        case Chain.MOONBASE_ALPHA_TESTNET:
          return externalLinks.MOONBASE_ALPHA_TESTNET_FAUCET;

        case Chain.GOERLI:
        default:
          return externalLinks.GOERLI_FAUCET;
      }
    };

    setFaucetLink(faucetLink(chainId));
  }, [chainId]);

  return (
    <Box
      sx={{
        backgroundColor: "#009E49",
        color: "white",
        fontWeight: 600,
        fontSize: "13px",
        padding: "0.9375em",
        textAlign: {
          xs: "left",
          md: "center",
        },
      }}
    >
      This is a testnet app. In order to use it you need to have testnet tokens. Please request
      testnet tokens from this{" "}
      <MuiLink
        className="label"
        href={faucetLink}
        rel="noopener noreferrer"
        target="_blank"
        sx={{
          color: (theme) => theme.palette.primary[700],
          textDecoration: "underline",
        }}
      >
        faucet.
      </MuiLink>
    </Box>
  );
};

export default FaucetMarquee;
