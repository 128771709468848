import React from "react";
import { useLPPositionsStore } from "@lib/providers/";
import {
  Detail,
  DetailActionButton,
} from "@components/common/ManageLiquidity/ManageLiquidityComponents";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { ManageLiquidityModalType, useModalManager } from "../modalManager/ModalManager";
import {
  usePendingCancelDepositTransactionByPool,
  usePendingCancelWithdrawTransactionByPool,
  usePendingWithdrawAvailableTransactionByPool,
} from "@lib/hooks";

const CancelPendingDepositUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolId]);
  const transaction = usePendingCancelDepositTransactionByPool(poolId);
  const { setOpen } = useModalManager();

  return (
    <Detail
      loading={!position?.pendingDeposit.formattedAmount}
      label="Pending Deposit"
      detail={
        position?.pendingDeposit.formattedAmount &&
        `${commaSeparateNumbers(truncateDecimal(position?.pendingDeposit.formattedAmount))} ${
          position?.poolDetails.debtToken?.symbol
        }`
      }
      actionButton={
        <DetailActionButton
          name={transaction ? "Cancelling" : "Cancel"}
          handleClick={() => setOpen(ManageLiquidityModalType.CANCEL_PENDING_DEPOSIT)}
          disabled={position?.pendingDeposit.amount?.isZero()}
          isTransacting={!!transaction}
        />
      }
    />
  );
};

const CancelPendingWithdrawUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const { pendingWithdrawal, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolId]
  );
  const transaction = usePendingCancelWithdrawTransactionByPool(poolId);
  const { setOpen } = useModalManager();

  return (
    <Detail
      loading={!pendingWithdrawal.formattedAmount}
      label="Pending Withdrawal"
      detail={
        pendingWithdrawal.formattedAmount &&
        `${commaSeparateNumbers(truncateDecimal(pendingWithdrawal.formattedAmount))} ${
          poolDetails.debtToken?.symbol
        }`
      }
      actionButton={
        <DetailActionButton
          name={transaction ? "Cancelling" : "Cancel"}
          handleClick={() => setOpen(ManageLiquidityModalType.CANCEL_PENDING_WITHDRAWAL)}
          disabled={
            !pendingWithdrawal.formattedAmount ||
            parseFloat(pendingWithdrawal.formattedAmount) === 0
          }
          isTransacting={!!transaction}
        />
      }
    />
  );
};

const AvailableWithdrawalUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolId]);
  const loading = false;
  const { setOpen } = useModalManager();
  const transaction = usePendingWithdrawAvailableTransactionByPool(poolId);

  return (
    <Detail
      loading={loading}
      label="Available Withdrawal"
      detail={
        position.availableWithdrawal.formattedAmount &&
        `${commaSeparateNumbers(truncateDecimal(position.availableWithdrawal.formattedAmount))} ${
          position.poolDetails.debtToken?.symbol
        }`
      }
      actionButton={
        <DetailActionButton
          name={transaction ? "Withdrawing" : "Withdraw"}
          handleClick={() => setOpen(ManageLiquidityModalType.WITHDRAW_AVAILABLE)}
          disabled={loading}
          isTransacting={!!transaction}
        />
      }
    />
  );
};

const LiquidityActionDetailUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolId]);
  if (!position.pendingWithdrawal.amount?.isZero() && position.pendingDeposit.amount?.isZero()) {
    return <CancelPendingWithdrawUI poolId={poolId} />;
  }

  if (
    !position.availableWithdrawal.amount?.isZero() &&
    position.availableWithdrawal.amount !== undefined
  ) {
    return <AvailableWithdrawalUI poolId={poolId} />;
  }

  return <CancelPendingDepositUI poolId={poolId} />;
};

export default LiquidityActionDetailUI;
