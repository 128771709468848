import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { RepayTransactionCard } from "@components/common";
import {
  useRepay,
  useApprove,
  useWeb3,
  usePriceFeed,
  useERCBalance,
  useShouldAppoveToken,
} from "@lib/hooks";
import { useToggle } from "@lib/providers";
import { convertTime, commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { UserLoan, TransactionStatus, TransactionType, TokenI } from "@lib/types";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { parseUnits } from "ethers/lib/utils";

const Repay = ({ userLoan }: { userLoan: UserLoan }): JSX.Element => {
  const { netDebt, pool, expirationTime, collateral, borrowingFee, repaymentAmount, raw } =
    userLoan;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [repayStatusSync, setRepayStatusSync] = useState<TransactionStatus | "form">("form");

  const { shouldApprove, isApproving } = useShouldAppoveToken({
    token: pool.debtToken as TokenI,
    spender: pool.id,
    balance: parseUnits(netDebt, pool.debtToken.decimals),
  });

  const [isBalanceEnough, setIsBalanceEnough] = useState<boolean>(false);

  const { active, chainId } = useWeb3();

  const { setOpen: setOpenParent } = useToggle();

  const {
    repay,
    transactionStatus: repayStatus,
    error: repayError,
    response: repayResponse,
  } = useRepay(pool.id);

  const { approve } = useApprove({
    tokenAddress: pool.debtToken.id,
    spender: pool.id,
  });

  const { balance: debtTokenBalance, loading } = useERCBalance({
    tokenAddress: pool.debtToken.id as string,
    decimals: pool?.debtToken.decimals,
  });

  const { price: DEBTTOKEN_USD_PRICE } = usePriceFeed({
    priceFeedAddress: pool.priceFeed,
    token: {
      id: pool.debtToken.id,
      decimals: pool.debtToken.decimals,
    },
  });

  const handleSubmit = () => {
    repay(userLoan.id, {
      poolName: pool.name,
      collateralToken: {
        id: pool.collateralToken.id,
        symbol: pool.collateralToken.symbol,
        amount: collateral,
      },
      debtToken: {
        id: pool.debtToken.id,
        symbol: pool.debtToken.symbol,
        amount: repaymentAmount,
        amountUnitPriceInUSD: DEBTTOKEN_USD_PRICE?.formatted,
      },
    });
  };

  useEffect(
    function checkIfBalanceIsEnough() {
      if (debtTokenBalance?.value.gte(raw.repaymentAmount)) {
        setIsBalanceEnough(true);
      } else {
        setIsBalanceEnough(false);
      }
    },
    [active, debtTokenBalance?.value, raw.repaymentAmount]
  );

  useEffect(() => {
    if (repayStatus) {
      setRepayStatusSync(repayStatus);
    }
  }, [repayStatus]);

  return repayStatusSync === "form" ? (
    <RepayTransactionCard
      loading={loading}
      walletBalance={`${commaSeparateNumbers(
        truncateDecimal(debtTokenBalance?.formatted ?? "0", 4),
        2
      )} ${pool.debtToken.symbol}`}
      repaymentAmount={`${commaSeparateNumbers(truncateDecimal(repaymentAmount))} ${
        pool.debtToken.symbol
      }`}
      debtExpirationDate={convertTime(expirationTime).monthDayYear}
      totalDebt={`${commaSeparateNumbers(truncateDecimal(netDebt))} ${pool.debtToken.symbol}`}
      borrowingFeePaid={`${commaSeparateNumbers(truncateDecimal(borrowingFee))} ${
        pool.debtToken.symbol
      }`}
      collateralLocked={`${truncateDecimal(collateral, 4)} ${pool.collateralToken.symbol}`}
      approveButtonIsDisabled={!shouldApprove}
      isApproving={isApproving}
      approveButtonOnClick={() =>
        approve({ token: { id: pool.debtToken.id, symbol: pool.debtToken.symbol } })
      }
      isSubmitting={repayStatus === "confirm" || repayStatus === "submitted"}
      fullScreen={fullScreen}
      onSubmit={handleSubmit}
      isValid={active && !shouldApprove && !isApproving && isBalanceEnough}
      error={isBalanceEnough ? undefined : "Insufficient balance"}
    />
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.Repay}
      status={repayStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(false)}
      onBackToForm={() => setRepayStatusSync("form")}
      transactionHash={repayResponse?.hash as string}
      errorMessage={repayError}
      confirmMessage={`Repaying the amount of ${commaSeparateNumbers(
        truncateDecimal(repaymentAmount)
      )} ${pool.debtToken.symbol}.`}
    />
  );
};

export default Repay;
