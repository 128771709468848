import React from "react";
import {
  Detail,
  DetailActionButton,
} from "@components/common/ManageLiquidity/ManageLiquidityComponents";
import { useLPPositionsStore } from "@lib/providers/";
import { ManageLiquidityModalType, useModalManager } from "../modalManager/ModalManager";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { usePendingWithdrawCollateralTransactionByPool } from "@lib/hooks";

const CollateralUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const { pendingCollateral, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolId]
  );
  const transaction = usePendingWithdrawCollateralTransactionByPool(poolId);

  const { setOpen } = useModalManager();

  return (
    <Detail
      loading={!pendingCollateral.formattedAmount}
      label="Collateral"
      detail={
        pendingCollateral.formattedAmount
          ? `${commaSeparateNumbers(truncateDecimal(pendingCollateral.formattedAmount, 4), 4)} ${
              poolDetails.collateralToken?.symbol
            }`
          : undefined
      }
      actionButton={
        <DetailActionButton
          name={transaction ? "Withdrawing" : "Withdraw"}
          handleClick={() => setOpen(ManageLiquidityModalType.WITHDRAW_COLLATERAL)}
          disabled={pendingCollateral.amount?.isZero()}
          isTransacting={!!transaction}
        />
      }
    />
  );
};

export default CollateralUI;
