import React from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { BaseFlex, ExternalLinkIcon } from "@components/common";
import { getExplorerLink, HashType } from "@lib/utils";
import { TransactionDetailBase } from "@lib/providers/Store";

type SuccessProps = Pick<TransactionDetailBase, "hash" | "chainId" | "description">;

const Success = ({ hash, description, chainId }: SuccessProps): JSX.Element => {
  return (
    <BaseFlex
      data-test="success-toast"
      sx={{ flexDirection: "column", fontSize: "0.8125rem", alignItems: "flex-start" }}
    >
      <div data-test="description">{description}</div>
      <MuiLink
        href={getExplorerLink(chainId as number, hash, HashType.TRANSACTION)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            color: "white",
            textDecoration: "underline",
            fontSize: "0.8125rem",
            lineHeight: 1.5,
            fontWeight: 500,
          }}
        >
          View on Explorer
          <div style={{ height: "16px", width: "16px" }}>
            <ExternalLinkIcon />
          </div>
        </Box>
      </MuiLink>
    </BaseFlex>
  );
};

export default Success;
