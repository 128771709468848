import { useAllTransactions } from "@lib/hooks";
import { TransactionDetailBase, RepayTransactionInfo } from "@lib/providers/Store/types";
import { TransactionType } from "@lib/types";

export const useUnindexedRepayTransactions = (): Array<
  TransactionDetailBase<RepayTransactionInfo>
> => {
  const transactions = useAllTransactions();

  return transactions
    .filter((transaction) => transaction.transactionInfo.type === TransactionType.Repay)
    .filter((transaction) => !transaction.error)
    .filter((transaction) => !transaction.isCancelled)
    .filter((repay): repay is TransactionDetailBase<RepayTransactionInfo> => !repay.isMined);
};

export const useGetRepayTransactionByLoanId = (
  loanId: string
): TransactionDetailBase<RepayTransactionInfo> | undefined => {
  const transactions = useUnindexedRepayTransactions();

  return transactions.filter(
    (transaction) => transaction.transactionInfo.loanId.toLowerCase() === loanId
  )[0];
};
