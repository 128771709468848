import React from "react";
import { keyframes, styled } from "@mui/system";

const StyledPollingDot = styled("div")(({ theme }) => ({
  width: "6px",
  height: "6px",
  minHeight: "6px",
  minWidth: "6px",
  borderRadius: "50%",
  position: "relative",
  backgroundColor: theme.palette.secondary.main,
}));

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled("div")({
  animation: `${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite`,
  transform: "translateZ(0)",
  borderTop: "1px solid transparent",
  borderRight: "1px solid transparent",
  borderBottom: "1px solid transparent",
  borderLeft: "2px solid white",
  background: "transparent",
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  position: "relative",
  transition: "250ms ease border-color",
  left: "-4px",
  top: "-4px",
});

const SpinnerTwo = (): JSX.Element => {
  return (
    <StyledPollingDot>
      <Spinner />
    </StyledPollingDot>
  );
};

export default SpinnerTwo;
