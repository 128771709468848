import React, { useState, useEffect } from "react";
import { parseUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { FormProvider, useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SupplyBasicTransactionCard } from "@components/common";
import {
  useDeposit,
  useERCBalance,
  useApprove,
  useWeb3,
  usePriceFeed,
  usePool,
  useShouldAppoveToken,
} from "@lib/hooks";
import { useLPPositionsStore } from "@lib/providers";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI, TransactionType } from "@lib/types";
import { TransactionStatus } from "@lib/types";
import { TransactionStatusMessage } from "@components/ui";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";

type Props = {
  poolAddress: string;
};

const INPUT_NAME = "depositAmount";
const SLIDER_NAME = "sliderAmount";

const SupplyBasic = ({ poolAddress }: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [supplyStatusSync, setSupplyStatusSync] = useState<TransactionStatus | "form">("form");
  const methods = useForm({
    defaultValues: { [INPUT_NAME]: "", [SLIDER_NAME]: 0 },
    mode: "onChange",
  });

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useModalManager();

  const { lendingPool, loading } = usePool(poolAddress);
  const { activeLiquidity, pendingDeposit, pendingWithdrawal } = useLPPositionsStore(
    (state) => state.positions[poolAddress]
  );

  const { price: DEBTTOKEN_USD_PRICE } = usePriceFeed({
    priceFeedAddress: lendingPool?.priceFeed as string,
    token: {
      id: lendingPool?.debtToken.id,
      decimals: lendingPool?.debtToken.decimals,
    },
  });

  const { balance: debtTokenBalance, loading: loadingBalance } = useERCBalance({
    tokenAddress: lendingPool?.debtToken.id as string,
    decimals: lendingPool?.debtToken.decimals,
  });

  const { shouldApprove, isApproving } = useShouldAppoveToken({
    token: lendingPool?.debtToken as TokenI,
    spender: poolAddress,
    balance: debtTokenBalance?.value,
  });

  const {
    deposit,
    transactionStatus: depositStatus,
    error: depositError,
    response: depositResponse,
  } = useDeposit(poolAddress);

  const { approve } = useApprove({
    tokenAddress: lendingPool?.debtToken.id as string,
    spender: poolAddress,
  });

  const handleSubmit = () => {
    deposit(
      parseUnits(methods.getValues(INPUT_NAME), lendingPool?.debtToken.decimals).add(
        pendingWithdrawal.amount || BigNumber.from(0)
      ),
      {
        poolName: lendingPool?.name as string,
        debtoken: {
          id: lendingPool?.debtToken.id as string,
          symbol: lendingPool?.debtToken.symbol as string,
          amount: methods.getValues(INPUT_NAME),
          amountUnitPriceInUSD: DEBTTOKEN_USD_PRICE?.formatted,
        },
      }
    );
  };

  useEffect(() => {
    if (depositStatus) {
      setSupplyStatusSync(depositStatus);
    }
  }, [depositStatus]);

  return supplyStatusSync === "form" ? (
    <FormProvider {...methods}>
      <SupplyBasicTransactionCard
        inputName={INPUT_NAME}
        sliderName={SLIDER_NAME}
        loading={
          loading ||
          loadingBalance ||
          !pendingDeposit.formattedAmount ||
          !activeLiquidity.formattedAmount ||
          !pendingWithdrawal.formattedAmount
        }
        walletBalance={debtTokenBalance?.formatted as string}
        poolName={lendingPool?.name as string}
        activeLiquidity={activeLiquidity.formattedAmount ?? "0"}
        pendingDeposit={pendingDeposit.formattedAmount ?? "0"}
        willBeActiveIn={lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string}
        poolUrl="#"
        debtToken={{
          ...(lendingPool?.debtToken as TokenI),
          price: DEBTTOKEN_USD_PRICE?.formatted,
          logo: lendingPool?.stableCoinTokenlogoUrl as string,
        }}
        minAmountToDeposit={lendingPool?.minAmountToDeposit as number}
        approveButtonIsDisabled={!shouldApprove}
        isApproving={isApproving}
        approveButtonOnClick={() =>
          approve({
            token: {
              id: lendingPool?.debtToken.id as string,
              symbol: lendingPool?.debtToken.symbol as string,
            },
          })
        }
        isSubmitting={depositStatus === "confirm" || depositStatus === "submitted"}
        fullScreen={fullScreen}
        onSubmit={handleSubmit}
        isValid={active && !shouldApprove && !isApproving}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.Deposit}
      status={depositStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setSupplyStatusSync("form")}
      transactionHash={depositResponse?.hash as string}
      errorMessage={depositError}
      confirmMessage={`Supplying the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(INPUT_NAME))
      )} ${lendingPool?.debtToken.symbol} to the ${lendingPool?.name} pool.`}
    />
  );
};

export default SupplyBasic;
