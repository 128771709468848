import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CurvyCheck = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke="#17CEB9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6112 8.19226L16.2779 11.8589M16.2779 11.8589L12.6112 15.5256M16.2779 11.8589L8.19238 11.8589"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CurvyCheck;
