import React, { ReactElement } from "react";
import { styled } from "@mui/system";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface BorrowingFeeTooltipProps {
  children: ReactElement;
  open?: boolean;
}

const HintTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#e3474779",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    top: "-10px",
    backgroundColor: "#000",
    fontSize: "11.7px",
    padding: "1em 1em",
    border: "1px solid #e3474779",
  },
});

const BorrowingFeeTooltip = ({ children, open = false }: BorrowingFeeTooltipProps): JSX.Element => {
  return (
    <HintTooltip
      title="Note: Cora needs a borrowing fee of more than zero to be able to make a loan. In order
  to increase the borrowing fee you need to increase your borrow amount first."
      open={open}
    >
      {children}
    </HintTooltip>
  );
};

export default BorrowingFeeTooltip;
