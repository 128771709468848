import { usePoolsStore } from "@lib/providers";
import { PoolI } from "@lib/types";

export type LendingPoolReturn = {
  lendingPool: Pick<
    PoolI,
    | "id"
    | "name"
    | "collateralToken"
    | "debtToken"
    | "availableLiquidity"
    | "utilization"
    | "apy"
    | "ltv"
    | "collateralTokenLogoUrl"
    | "stableCoinTokenlogoUrl"
    | "strategyDetails"
    | "priceFeed"
    | "minAmountToBorrow"
    | "minAmountToDeposit"
    | "nextRoundTimestamp"
    | "nextRoundTimestampRelative"
    | "nextRoundTimestampRelativeWithoutSuffix"
    | "roundDurationInDays"
    | "allowedLoanTerms"
  >;
  loading: boolean;
  error?: any;
};

const usePool = (poolAddress: string): LendingPoolReturn => {
  const pool = usePoolsStore((state) => state.pools[poolAddress]);

  return { lendingPool: pool, loading: pool?.poolLoading, error: pool?.poolError };
};

export default usePool;
