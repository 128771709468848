import React, { Fragment } from "react";
import Script from "next/script";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ThirdPartyScripts = () => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return (
    <Fragment>
      <Script
        id="hotjar"
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3263141,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />

      <Script
        id="google-tagmanager"
        src={`[https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}](https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS})`}
      />
      <Script id="google-analytics">{`
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		
		gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
		`}</Script>
    </Fragment>
  );
};

export default ThirdPartyScripts;
