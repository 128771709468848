import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const TwitterIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      // width="24"
      // height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.5889 7.48105C20.602 7.67634 20.602 7.87163 20.602 8.06872C20.602 14.074 16.1443 21 7.99303 21V20.9964C5.58513 21 3.22725 20.2926 1.2002 18.9589C1.55032 19.0021 1.90221 19.0237 2.25497 19.0246C4.25043 19.0264 6.18886 18.3398 7.75873 17.0753C5.86243 17.0384 4.19954 15.7704 3.61862 13.9192C4.2829 14.0506 4.96736 14.0236 5.61935 13.841C3.55193 13.4126 2.06455 11.5497 2.06455 9.38623V9.32864C2.68056 9.68051 3.37029 9.8758 4.07581 9.8974C2.1286 8.56279 1.52839 5.90615 2.70425 3.82908C4.9542 6.6684 8.27383 8.3945 11.8374 8.57718C11.4803 6.99868 11.9682 5.34459 13.1195 4.23496C14.9043 2.51427 17.7115 2.60246 19.3893 4.43205C20.3818 4.23136 21.333 3.85788 22.2035 3.32872C21.8727 4.38075 21.1803 5.27439 20.2554 5.84226C21.1338 5.73606 21.992 5.49488 22.8002 5.1268C22.2052 6.04114 21.4558 6.83759 20.5889 7.48105Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default TwitterIcon;
