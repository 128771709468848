import { TransactionStep } from "@lib/types";

const generateTransactionStatus = ({
  isMined,
  error,
  isCancelled,
}: {
  isMined: boolean;
  error: boolean;
  isCancelled: boolean;
}): TransactionStep => {
  if (error) {
    return TransactionStep.FAIL;
  } else if (isCancelled) {
    return TransactionStep.CANCELLED;
  } else if (isMined) {
    return TransactionStep.DONE;
  } else {
    return TransactionStep.MINING;
  }
};

export default generateTransactionStatus;
