import React from "react";
import { UserLiquidityItem } from "@components/common/Table/UserLiquidity/UserLiquidityComponents";
import { useLPPositionsStore } from "@lib/providers/";
import { formatUnits } from "ethers/lib/utils";
import { getRewardsPercentage } from "@lib/utils";

const UserLiquidityItemUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolId]);

  return (
    <UserLiquidityItem
      pool={{
        id: poolId,
        name: position?.poolDetails.name,
        debtToken: position?.poolDetails.debtToken,
      }}
      totalLiquidity={
        position?.activeLiquidity.amount &&
        position?.pendingDeposit.amount &&
        formatUnits(
          position?.activeLiquidity.amount.add(position?.pendingDeposit.amount),
          position?.poolDetails.debtToken.decimals
        )
      }
      feesEarned={position?.feesEarned.formattedAmount}
      rewardsEarnedPercentage={getRewardsPercentage(
        position?.feesEarned.amount,
        position?.activeLiquidity.amount,
        position?.poolDetails.debtToken.decimals
      )}
      loading={
        !position?.feesEarned.formattedAmount ||
        !position?.activeLiquidity.formattedAmount ||
        !position?.pendingDeposit.formattedAmount
      }
      isActionButtonDisabled={
        position?.activeLiquidity.amount?.isZero() &&
        position?.pendingDeposit.amount?.isZero() &&
        position?.feesEarned.amount?.isZero() &&
        position?.pendingCollateral.amount?.isZero() &&
        position?.pendingWithdrawal.amount?.isZero() &&
        position?.availableWithdrawal.amount?.isZero()
      }
    />
  );
};

export default UserLiquidityItemUI;
