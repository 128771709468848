import React from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { BaseFlex, ExternalLinkIcon } from "@components/common";
import { getExplorerLink, HashType } from "@lib/utils";

interface ErrorProps {
  description: string;
  hash?: string;
  chainId?: number;
}

const Error = ({ hash, description, chainId }: ErrorProps): JSX.Element => {
  return (
    <BaseFlex sx={{ flexDirection: "column", fontSize: "0.8125rem", alignItems: "flex-start" }}>
      <div>Transaction failed!</div>
      <div>{description}</div>
      {chainId && hash && (
        <MuiLink
          href={getExplorerLink(chainId as number, hash, HashType.TRANSACTION)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              color: "white",
              textDecoration: "underline",
              fontSize: "0.8125rem",
              lineHeight: 1.5,
              fontWeight: 500,
            }}
          >
            View on Explorer
            <div style={{ height: "16px", width: "16px" }}>
              <ExternalLinkIcon />
            </div>
          </Box>
        </MuiLink>
      )}
    </BaseFlex>
  );
};

export default Error;
