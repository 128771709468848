import { Chain } from "./chainOptions";

/**
 * This environment means that we are using specific subgraphs and contracts for every testnet
 * example: Degenscore is exclusively using the Goerli testnet and the app does not support any other testnets
 */
export enum ENVIRONMENTS {
  LOCAL = "local",
  DEVELOPMENT = "development",
  DEGENSCORE = "degenscore",
  AURORA_TESTNET = "aurora-testnet",
  MOONBASE_ALPHA_TESTNET = "moonbase-alpha-testnet",
  E2ETEST = "e2e-test",
}

export const environmentInformation = (environment: ENVIRONMENTS): Array<Chain> => {
  switch (environment) {
    case ENVIRONMENTS.LOCAL:
      return [Chain.LOCALHOST];
    case ENVIRONMENTS.DEVELOPMENT:
      return [Chain.GOERLI, Chain.MOONBASE_ALPHA_TESTNET];
    case ENVIRONMENTS.E2ETEST:
      return [];
    case ENVIRONMENTS.DEGENSCORE: {
      return [Chain.GOERLI];
    }
    case ENVIRONMENTS.AURORA_TESTNET: {
      return [Chain.AURORA_TESTNET];
    }
    case ENVIRONMENTS.MOONBASE_ALPHA_TESTNET: {
      return [Chain.MOONBASE_ALPHA_TESTNET];
    }
    default: {
      return [Chain.GOERLI];
    }
  }
};
