import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CancelPendingDepositTransactionCard } from "@components/common";
import { useCancelDeposit, usePool, useWeb3 } from "@lib/hooks";
import { TransactionStatus, TransactionType } from "@lib/types";
import { useLPPositionsStore } from "@lib/providers/";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { parseUnits } from "ethers/lib/utils";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";

type Props = {
  poolAddress: string;
};

const INPUT_NAME = "cancelAmount";
const SLIDER_NAME = "sliderAmount";

const CancelPendingDeposit = ({ poolAddress }: Props): JSX.Element => {
  const [cancelStatusSync, setCancelStatusSync] = useState<TransactionStatus | "form">("form");
  const methods = useForm({
    defaultValues: { [INPUT_NAME]: "", [SLIDER_NAME]: 0 },
    mode: "onChange",
  });

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useModalManager();

  const { activeLiquidity, pendingDeposit, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolAddress]
  );
  const { lendingPool, loading } = usePool(poolAddress);

  const {
    signalWithdraw,
    transactionStatus: cancelDepositStatus,
    error: cancelDepositError,
    response: cancelDepositResponse,
  } = useCancelDeposit(poolAddress);

  const handleSubmit = () => {
    signalWithdraw(parseUnits(methods.getValues(INPUT_NAME), poolDetails?.debtToken.decimals), {
      poolName: poolDetails?.name as string,
      debtToken: {
        id: poolDetails?.debtToken.id as string,
        symbol: poolDetails?.debtToken.symbol as string,
        amount: methods.getValues(INPUT_NAME),
        amountUnitPriceInUSD: poolDetails?.debtToken.priceInUSD as string,
      },
    });
  };

  useEffect(() => {
    if (cancelDepositStatus) {
      setCancelStatusSync(cancelDepositStatus);
    }
  }, [cancelDepositStatus]);

  return cancelStatusSync === "form" ? (
    <FormProvider {...methods}>
      <CancelPendingDepositTransactionCard
        inputName={INPUT_NAME}
        sliderName={SLIDER_NAME}
        loading={loading}
        totalPendingAmount={pendingDeposit.formattedAmount as string}
        activeLiquidity={activeLiquidity.formattedAmount as string}
        willBeActiveIn={lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string}
        debtToken={{
          ...poolDetails.debtToken,
          price: poolDetails.debtToken.priceInUSD,
          logo: "/assets/tokenImages/usd-coin-usdc-logo.png",
        }}
        approveButtonIsDisabled={true}
        isApproving={false}
        approveButtonOnClick={() => undefined}
        isSubmitting={cancelDepositStatus === "confirm" || cancelDepositStatus === "submitted"}
        onSubmit={handleSubmit}
        isValid={active}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.CancelDeposit}
      status={cancelDepositStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setCancelStatusSync("form")}
      transactionHash={cancelDepositResponse?.hash as string}
      errorMessage={cancelDepositError}
      confirmMessage={`Cancel the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(INPUT_NAME))
      )} ${poolDetails?.debtToken.symbol} to the ${poolDetails?.name} pool.`}
    />
  );
};

export default CancelPendingDeposit;
