import React, { useState, useEffect } from "react";
import { parseUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { FormProvider, useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { NFTCoin, SupplyAgainTransactionCard } from "@components/common";
import {
  useDeposit,
  useERCBalance,
  useApprove,
  useWeb3,
  useShouldAppoveToken,
  usePool,
} from "@lib/hooks";
import { useLPPositionsStore, useToggle } from "@lib/providers";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";
import { TransactionStatus, TransactionType } from "@lib/types";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";

type Props = {
  poolAddress: string;
};

const INPUT_NAME = "depositAmount";
const SLIDER_NAME = "sliderAmount";

const Supply = ({ poolAddress }: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [supplyStatusSync, setSupplyStatusSync] = useState<TransactionStatus | "form">("form");
  const methods = useForm({
    defaultValues: { [INPUT_NAME]: "", [SLIDER_NAME]: 0 },
    mode: "onChange",
  });

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useToggle();

  const { lendingPool, loading } = usePool(poolAddress);

  const { activeLiquidity, pendingDeposit, pendingWithdrawal, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolAddress]
  );

  const { balance: debtTokenBalance, loading: loadingBalance } = useERCBalance({
    tokenAddress: lendingPool?.debtToken.id as string,
    decimals: lendingPool?.debtToken.decimals,
  });

  const { shouldApprove, isApproving } = useShouldAppoveToken({
    token: lendingPool?.debtToken as TokenI,
    spender: poolAddress,
    balance: debtTokenBalance?.value,
  });

  const {
    deposit,
    transactionStatus: depositStatus,
    error: depositError,
    response: depositResponse,
  } = useDeposit(poolAddress);

  const { approve } = useApprove({
    tokenAddress: lendingPool?.debtToken.id as string,
    spender: poolAddress,
  });

  const handleSubmit = () => {
    deposit(
      parseUnits(methods.getValues(INPUT_NAME), lendingPool?.debtToken.decimals).add(
        pendingWithdrawal.amount || BigNumber.from(0)
      ),
      {
        poolName: lendingPool?.name as string,
        debtoken: {
          id: lendingPool?.debtToken.id as string,
          symbol: lendingPool?.debtToken.symbol as string,
          amount: methods.getValues(INPUT_NAME),
          amountUnitPriceInUSD: poolDetails.debtToken.priceInUSD,
        },
      }
    );
  };

  useEffect(() => {
    if (depositStatus) {
      setSupplyStatusSync(depositStatus);
    }
  }, [depositStatus]);

  return supplyStatusSync === "form" ? (
    <FormProvider {...methods}>
      <SupplyAgainTransactionCard
        inputName={INPUT_NAME}
        sliderName={SLIDER_NAME}
        loading={loading || loadingBalance}
        walletBalance={debtTokenBalance?.formatted as string}
        walletBalanceSymbol={lendingPool?.debtToken.symbol as string}
        poolDetails={{
          poolName: lendingPool?.name as string,
          poolTotalDeposits: lendingPool?.availableLiquidity as number,
          poolUtilization: lendingPool?.utilization as number,
          poolAPY: lendingPool?.apy as number,
          poolLTV: lendingPool?.ltv as number,
          minimumLockupPeriod: lendingPool?.roundDurationInDays as string,
          nextRoundTimestamp: lendingPool?.nextRoundTimestamp as number,
          nextRoundTimestampRelative:
            lendingPool?.nextRoundTimestampRelativeWithoutSuffix as string,
          poolDebtToken: {
            ...(lendingPool?.debtToken as TokenI),
            price: poolDetails.debtToken.priceInUSD,
            logo: lendingPool?.stableCoinTokenlogoUrl as string,
          },
        }}
        userLiquidityDetails={{
          nft: <NFTCoin />,
          providedLiquidity: (
            parseFloat(activeLiquidity?.formattedAmount || "0") +
            parseFloat(pendingDeposit?.formattedAmount || "0")
          ).toString(),
          pendingDeposit: pendingDeposit.formattedAmount as string,
          activeLiquidity: activeLiquidity.formattedAmount as string,
          pendingWithdrawal: pendingWithdrawal.formattedAmount as string,
          token: lendingPool?.debtToken as TokenI,
        }}
        roundDurationInDays={lendingPool?.roundDurationInDays as string}
        minAmountToDeposit={lendingPool?.minAmountToDeposit as number}
        approveButtonIsDisabled={!shouldApprove}
        isApproving={isApproving}
        approveButtonOnClick={() =>
          approve({
            token: {
              id: lendingPool?.debtToken.id as string,
              symbol: lendingPool?.debtToken.symbol as string,
            },
          })
        }
        isSubmitting={depositStatus === "confirm" || depositStatus === "submitted"}
        fullScreen={fullScreen}
        onSubmit={handleSubmit}
        isValid={active && !shouldApprove && !isApproving}
      />
    </FormProvider>
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.Deposit}
      status={depositStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(false)}
      onBackToForm={() => setSupplyStatusSync("form")}
      transactionHash={depositResponse?.hash as string}
      errorMessage={depositError}
      confirmMessage={`Supplying the amount of ${commaSeparateNumbers(
        truncateDecimal(methods.getValues(INPUT_NAME))
      )} ${lendingPool?.debtToken.symbol} to the ${lendingPool?.name} pool.`}
    />
  );
};

export default Supply;
