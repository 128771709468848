import { useCallback, useEffect, useMemo, useState } from "react";
import { BigNumber } from "ethers";
import { useBlockNumber } from "@lib/providers";
import { useMultiCall, useWeb3, useUserLoansLength } from "@lib/hooks";
import { UseMultiCallAggregateProps } from "@lib/hooks/useMultiCall";

import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";

interface UseUserLoanIdsReturn {
  data: Record<string, Array<BigNumber>>;
  loading: boolean;
  error?: boolean;
}

const callData = (length: Record<string, number>, account: string): UseMultiCallAggregateProps => {
  return Object.keys(length).flatMap((poolAddress) => {
    const loanLength = length[poolAddress];
    return Array.from({ length: loanLength }, (_, index) => {
      return {
        target: poolAddress,
        abi: LendingPoolAbi,
        reference: `${poolAddress}-userLoanIds-${index}`,
        method: "userLoans",
        args: [account, index],
      };
    });
  });
};

/**
 * transform
 * {
 * "0x39c204B0b0A1270A3c09372f3069afBB42634499-userLoanIds-0": data0,
 * "0x39c204B0b0A1270A3c09372f3069afBB42634499-userLoanIds-1": data1,
 * }
 *
 * to
 *
 *  { '0x39c204B0b0A1270A3c09372f3069afBB42634499': [ data0, data1, ] }
 */
export const reshapeData = (data: Record<string, BigNumber>): Record<string, Array<BigNumber>> => {
  const output: Record<string, Array<BigNumber>> = {};

  for (const key in data) {
    const pool = key.split("-")[0];
    const value = data[key];
    if (!output[pool]) {
      output[pool] = [];
    }
    output[pool].push(value);
  }
  return output;
};

const useUserLoanIds = (pools: Array<string>): UseUserLoanIdsReturn => {
  const { account } = useWeb3();
  const { data: length, loading: lengthLoading } = useUserLoansLength(pools);
  const {
    data,
    call,
    loading: callLoading,
    error: callError,
  } = useMultiCall<Record<string, BigNumber>>();
  const blockNumber = useBlockNumber();
  const [loanIds, setLoanIds] = useState<Record<string, Array<BigNumber>>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const getUserLoanIds = useCallback(
    async (length: Record<string, number>) => {
      if (!account) return;

      call(callData(length, account));
    },
    [account]
  );

  useEffect(() => {
    if (!account) return;
    if (lengthLoading) return;

    // if pool lengths are all zero then convert to empty array
    // then set to state then bail
    if (Object.values(length).every((l) => l === 0)) {
      const result = {} as Record<string, []>;

      for (const key in length) {
        result[key] = [];
      }

      setLoanIds(result);
      setLoading(false);
      return;
    }

    getUserLoanIds(length);
  }, [blockNumber, account, JSON.stringify(pools), JSON.stringify(length)]);

  useEffect(() => {
    if (callLoading) return;

    setLoanIds(reshapeData(data));
    setLoading(false);
  }, [JSON.stringify(data)]);

  return useMemo(() => {
    return {
      data: loanIds,
      loading,
      error: callError,
    };
  }, [JSON.stringify(loanIds), callError]);
};

export default useUserLoanIds;
