// https://usehooks.com/useOnScreen/
import { MutableRefObject, useLayoutEffect, useState } from "react";

const useOnScreen = <T extends Element>(ref: MutableRefObject<T>, rootMargin = "0px"): boolean => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []);

  return isIntersecting;
};

export default useOnScreen;
