import React from "react";
import NextLink from "next/link";
import { drawerClasses } from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/system";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import { hexToRgba } from "@lib/utils";

export const DrawerPaper = styled(SwipeableDrawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    backgroundColor: theme.palette.primary[600],
    width: "100vw",
    padding: "1em",
  },
}));

interface DrawerLinkProps {
  external?: boolean;
  url?: string;
  label: string;
  onClick?: () => void;
}

export const DrawerLink = ({
  external = true,
  url,
  label,
  onClick,
}: DrawerLinkProps): JSX.Element => {
  return external ? (
    <MuiLink href={url} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      <Box
        sx={{
          color: (theme) => hexToRgba(theme.palette.common.white, 0.8),
          fontWeight: 700,
          fontSize: "1.2rem",
        }}
      >
        {label}
      </Box>
    </MuiLink>
  ) : url ? (
    <NextLink href={url} passHref>
      <MuiLink onClick={onClick}>
        <Box
          sx={{
            color: (theme) => hexToRgba(theme.palette.common.white, 0.8),
            fontWeight: 700,
            fontSize: "1.2rem",
          }}
        >
          {label}
        </Box>
      </MuiLink>
    </NextLink>
  ) : (
    <Box
      sx={{
        color: (theme) => hexToRgba(theme.palette.common.white, 0.8),
        fontWeight: 700,
        fontSize: "1.2rem",
      }}
    >
      {label}
    </Box>
  );
};
