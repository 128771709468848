import React from "react";
import Box from "@mui/material/Box";
import { BaseFlex, DownArrowCircle } from "@components/common";
import { ColumnHeader, TableContainer } from "@components/common/Table/TableComponents";
import {
  Header,
  Wrapper,
  LineGrid,
  Message,
} from "@components/common/Table/Borrowed/BorrowedComponents";
import { convertToCurrency } from "@lib/utils";

const BorrowedTable = ({ withLabel = true }: { withLabel?: boolean }): JSX.Element => {
  return (
    <TableContainer sx={{ paddingBottom: 0 }}>
      <Header sx={{ flexDirection: "column", alignItems: "flex-start" }}>
        {withLabel && (
          <BaseFlex className="label">
            <DownArrowCircle height="21px" width="21px" />
            Your Borrowed Balance
          </BaseFlex>
        )}

        <BaseFlex sx={{ justifyContent: "space-between", width: "100%" }}>
          <span data-test="user-total-borrowed" className="total">
            {convertToCurrency(0)}
          </span>
        </BaseFlex>
      </Header>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid className="lineGrid" sx={{ padding: 0 }}>
            <ColumnHeader sx={{ gridArea: "collateral", justifyContent: "start" }}>
              Collateral
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "locked" }}>Locked</ColumnHeader>
            <ColumnHeader sx={{ gridArea: "borrowed" }}>Borrowed</ColumnHeader>
            <ColumnHeader sx={{ gridArea: "borrowingFee" }}>Borrowing Fee</ColumnHeader>
            <ColumnHeader sx={{ gridArea: "collateralRatio" }}>Coll. Ratio</ColumnHeader>
            <ColumnHeader sx={{ gridArea: "expiration" }}>Expiration</ColumnHeader>

            <ColumnHeader
              sx={{
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          <div>
            <Message message="An error occured." />
          </div>
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

export default BorrowedTable;
