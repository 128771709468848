import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog, { dialogClasses, DialogProps } from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material";
import { BaseFlex, CloseIcon } from "@components/common";
import { hexToRgba } from "@lib/utils";

export interface TransactionCardBaseProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  fullScreen?: boolean;
}

const TransactioncardBaseModal = styled((props: DialogProps) => <Dialog {...props} />)(
  ({ theme }) => ({
    [`& .${dialogClasses.paper}`]: {
      background: "transparent",
      boxShadow: "none",
      transform: "translateX(20px)",
      overflowY: "visible",

      [theme.breakpoints.down("md")]: {
        transform: "none",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  })
);

export const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "10px",
  backgroundColor: hexToRgba(theme.palette.primary[600], 0.7),
  paddingBottom: "1em",
  border: `1px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const TransactionCardBase = ({
  open,
  onClose,
  children,
  fullScreen = false,
}: TransactionCardBaseProps): JSX.Element => {
  return (
    <TransactioncardBaseModal
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="lg"
      scroll="body"
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "fixed",
          top: fullScreen ? "5px" : 0,
          right: {
            xs: "5px",
            md: "-35px",
          },
          padding: fullScreen ? "2px" : 0,
          backgroundColor: (theme) => (fullScreen ? theme.palette.primary[800] : "none"),
          zIndex: 1302, // modal zindex is 1300, togglerbutton is 1301
        }}
      >
        <CloseIcon />
      </IconButton>
      <ContentWrapper>{children}</ContentWrapper>
    </TransactioncardBaseModal>
  );
};

export const DetailsWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  "& div:first-of-type": {
    paddingTop: 0,
  },
  "& div": {
    borderBottom: `1px solid ${theme.palette.primary[600]}`,
  },
  "& div:nth-last-of-type(1)": {
    borderBottom: "none",
    paddingBottom: 0,
  },
}));

interface DetailProps {
  label: ReactNode;
  detail: ReactNode;
  subDetail?: ReactNode;
  loading?: boolean;
}

export const Detail = ({ label, detail, subDetail, loading = false }: DetailProps): JSX.Element => {
  return (
    <BaseFlex
      sx={{
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "1em 0",
        color: (theme) => theme.palette.common.white,
      }}
    >
      <Typography
        variant="caption2"
        component="span"
        sx={{
          textTransform: "capitalize",
        }}
      >
        {label}
      </Typography>

      <Box component="span">
        {loading ? (
          <Skeleton
            variant="text"
            sx={(theme) => ({
              backgroundColor: hexToRgba(theme.palette.common.white, 0.3),
              width: "3em",
              ...theme.typography.caption1,
            })}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption1" component="span" sx={{ textAlign: "right" }}>
              {detail}
            </Typography>

            {subDetail && (
              <Typography
                variant="caption2"
                component="span"
                sx={{
                  color: (theme) => theme.palette.primary[300],
                  textAlign: "right",
                }}
              >
                {subDetail}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </BaseFlex>
  );
};
