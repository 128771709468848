import React, { Fragment } from "react";
import Image from "next/image";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import {
  ActionButton,
  BaseFlex,
  Hint,
  SpinnerTwo,
  InputSliderPair,
  HintTooltip,
} from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, convertTime, hexToRgba, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";
import { LendingPoolInfo } from "./LendingPoolInfo";
import { useIsMobile } from "@lib/hooks";
import { externalLinks } from "@lib/constants";

export interface SupplyTransactionCardProps extends Pick<TransactionCardBaseProps, "fullScreen"> {
  inputName: string;
  sliderName: string;
  loading: boolean;
  walletBalance: string;
  walletBalanceSymbol: string;
  poolName: string;
  poolTotalDeposits: number;
  poolUtilization: number;
  poolAPY: number;
  poolLTV: number;
  minimumLockupPeriod: string;
  nextRoundTimestamp: number;
  minAmountToDeposit: number;
  poolDebtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
  roundDurationInDays: string;
  approveButtonIsDisabled: boolean;
  approveButtonOnClick: () => void;
  isApproving: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
}

const Supply = ({
  inputName,
  sliderName,
  loading,
  walletBalance,
  walletBalanceSymbol,
  poolName,
  poolTotalDeposits,
  poolUtilization,
  poolAPY,
  poolLTV,
  minimumLockupPeriod,
  minAmountToDeposit,
  nextRoundTimestamp,
  poolDebtToken,
  roundDurationInDays,
  approveButtonIsDisabled,
  approveButtonOnClick,
  isApproving,
  onSubmit,
  isSubmitting,
  isValid,
  fullScreen = false,
}: SupplyTransactionCardProps): JSX.Element => {
  const { handleSubmit, formState } = useFormContext();
  const { isValid: formStateIsValid } = formState;
  const { isMobile } = useIsMobile();

  return (
    <BaseFlex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ gap: 0, padding: "0.5rem 0 1.5rem" }}
    >
      <BaseFlex sx={{ flexDirection: "column" }}>
        <BaseFlex sx={{ alignItems: "start" }}>
          <BaseFlex
            sx={{
              padding: "1rem 2rem",
              flexDirection: "column",
              gap: "2rem",
              color: "white",
              width: {
                xs: "100%",
                md: "28.75rem",
              },
            }}
          >
            <BaseFlex sx={{ flexDirection: "column", width: "100%", marginTop: "0.5em" }}>
              <BaseFlex
                sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}
              >
                <Typography
                  variant="headline2"
                  component="span"
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Supply Amount
                </Typography>
                <BaseFlex
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    gap: "0",
                  }}
                >
                  <Typography
                    variant="caption2"
                    component="span"
                    sx={{ color: (theme) => theme.palette.primary[300] }}
                  >
                    Wallet Balance:
                  </Typography>
                  <Typography
                    variant="caption1"
                    data-test="wallet-balance"
                    component="span"
                    sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
                  >
                    {loading || !walletBalance || walletBalance === ""
                      ? "-"
                      : `${commaSeparateNumbers(truncateDecimal(walletBalance))}
                ${walletBalanceSymbol}`}
                  </Typography>
                </BaseFlex>
              </BaseFlex>
              <InputSliderPair
                inputName={inputName}
                logo={poolDebtToken.logo}
                disabled={loading}
                sliderName={sliderName}
                max={walletBalance}
                conversionRate={poolDebtToken.price}
                moreValidationRules={{
                  min: (value: number) => {
                    if (value < minAmountToDeposit) {
                      return `Minimum supply amount: ${minAmountToDeposit} ${poolDebtToken.symbol}.`;
                    }
                  },
                }}
              />
            </BaseFlex>

            <BaseFlex
              sx={{
                width: "100%",
                flexDirection: "column",
                gap: "1em",
                marginTop: fullScreen ? "-1em" : 0,
              }}
            >
              {fullScreen && (
                <BaseFlex
                  sx={{
                    position: "relative",
                    width: "100%",
                    gap: 0,
                  }}
                >
                  <Box sx={{ transform: "translateX(5px)" }}>
                    <Image
                      src={"/assets/tokenImages/usd-coin-usdc-logo.png"}
                      height={36}
                      width={36}
                    />
                  </Box>

                  <Box sx={{ transform: "translateX(-5px)" }}>
                    <Image
                      src={"/assets/tokenImages/Ethereum-ETH-icon.png"}
                      height={36}
                      width={36}
                    />
                  </Box>
                </BaseFlex>
              )}

              <DetailsWrapper>
                <Detail label="Pool Name" detail={poolName} loading={loading} />
                <Detail
                  label="Total Deposits"
                  detail={
                    poolTotalDeposits &&
                    `${commaSeparateNumbers(poolTotalDeposits.toFixed(2))} ${poolDebtToken.symbol}`
                  }
                  loading={loading}
                />
                <Detail
                  label={
                    <BaseFlex sx={{ gap: "1em" }}>
                      Utilization
                      <Hint title="The percentage of the pool that is currently being utilized / lent." />
                    </BaseFlex>
                  }
                  detail={`${poolUtilization && (poolUtilization * 100)?.toFixed(2)}%`}
                  loading={loading}
                />
                <Detail
                  label={<BaseFlex>Projected APY</BaseFlex>}
                  detail={`${poolAPY && (poolAPY * 100)?.toFixed(2)}%`}
                  loading={loading}
                />

                <Detail
                  label={
                    <BaseFlex sx={{ gap: "1em" }}>
                      LTV
                      <Hint title="LTV (Loan To Value) is the maximum percentage of the collateral's spot price that this Lending Pool will allow borrowers to borrow." />
                    </BaseFlex>
                  }
                  detail={`${poolLTV && (poolLTV * 100)?.toFixed(2)}%`}
                  loading={loading}
                />

                <Detail
                  label={
                    <BaseFlex sx={{ gap: "1em" }}>
                      Pricer Model
                      <Hint
                        title={
                          <div>
                            <span>
                              <MuiLink
                                href={externalLinks.PRICING_MODELS}
                                rel="noopener noreferrer"
                                target="_blank"
                                sx={{ color: "white", textDecoration: "underline" }}
                              >
                                Click here{" "}
                              </MuiLink>
                            </span>
                            to learn more about what pricing models Cora uses.
                          </div>
                        }
                      />
                    </BaseFlex>
                  }
                  detail={
                    <MuiLink
                      href={externalLinks.PRICING_MODELS}
                      rel="noopener noreferrer"
                      target="_blank"
                      sx={{ color: "white", textDecoration: "underline" }}
                    >
                      Black-Scholes
                    </MuiLink>
                  }
                  loading={loading}
                />

                <Detail
                  label={
                    <BaseFlex sx={{ gap: "1em" }}>
                      Minimum Lockup Period
                      <Hint
                        title={
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                            <span>
                              This is the minimum amount of time your liquidity will be locked.
                            </span>

                            <MuiLink
                              sx={{ color: "inherit" }}
                              href="https://docs.cora.money/protocol-concepts/lending-pools/rounds"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Box component="span" sx={{ textDecoration: "underline" }}>
                                Learn more.
                              </Box>
                            </MuiLink>
                          </Box>
                        }
                      />
                    </BaseFlex>
                  }
                  detail={minimumLockupPeriod}
                  loading={loading}
                />

                <Detail
                  label="Next Round"
                  detail={
                    <HintTooltip title={convertTime(nextRoundTimestamp).full} disableFocusListener>
                      <span>{convertTime(nextRoundTimestamp).monthDayTime}</span>
                    </HintTooltip>
                  }
                  loading={loading}
                />
              </DetailsWrapper>
            </BaseFlex>
          </BaseFlex>

          {!isMobile && (
            <Fragment>
              <Box
                sx={{
                  alignSelf: "stretch",
                  padding: "1em 0",
                }}
              >
                <Box
                  sx={{
                    borderRight: (theme) =>
                      `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
                    height: "100%",
                  }}
                />
              </Box>

              <LendingPoolInfo
                depositValueInputName={inputName}
                apy={poolAPY}
                nextRoundIn={roundDurationInDays}
              />
            </Fragment>
          )}
        </BaseFlex>

        <BaseFlex
          sx={{
            marginRight: "auto",
            flexDirection: "column",
            padding: "0em 2rem",
            width: {
              xs: "100%",
              md: "28.75rem",
            },
          }}
        >
          <BaseFlex sx={{ width: "100%" }}>
            <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
              <ActionButton
                data-test="approve-button"
                onClick={approveButtonOnClick}
                disabled={isApproving || approveButtonIsDisabled || loading}
                pendingAction={isApproving}
              >
                {isApproving && <SpinnerTwo />}
                {isApproving ? "Approving" : "Approve"}
              </ActionButton>
            </BaseFlex>

            <ActionButton
              type="submit"
              data-test="supply-button"
              disabled={!isValid || isSubmitting || loading || !formStateIsValid}
              pendingAction={isSubmitting}
            >
              {isSubmitting ? "Supplying" : "Supply"}
            </ActionButton>
          </BaseFlex>
          {!approveButtonIsDisabled && !loading && (
            <BaseFlex
              sx={{ fontSize: "13px", width: "100%", justifyContent: "left", color: "white" }}
            >
              Allow the Cora Protocol to use your token on your behalf.
            </BaseFlex>
          )}
        </BaseFlex>
      </BaseFlex>
    </BaseFlex>
  );
};

export default Supply;
