enum TransactionType {
  Deposit = "Deposit",
  Approve = "Approve",
  WithdrawAvailable = "WithdrawAvailable",
  Borrow = "Borrow",
  Repay = "Repay",
  CancelDeposit = "CancelDeposit",
  CancelWithdraw = "CancelWithdraw",
  WithdrawCollateral = "WithdrawCollateral",
  SignalWithdraw = "SignalWithdraw",
}

export default TransactionType;
