import React, { Fragment } from "react";
import { TransitionGroup } from "react-transition-group";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { ColumnHeader, SortDirectionAsc, TableContainer } from "../TableComponents";
import { BaseFlex, Filter } from "@components/common";
import { Header, Wrapper, LineGrid, Message, BorrowedListItem } from "./BorrowedComponents";
import BorrowedLoader from "./BorrowedLoader";
import { convertToCurrency, hexToRgba } from "@lib/utils";
import { ToggleProvider } from "@lib/providers";
import PendingBorrowList from "./PendingBorrowList";
import { FilterProps } from "@components/common/SearchFilter";
import { BORROWED_FILTER_OPTIONS } from "@components/ui/Table/BorrowedAssets/BorrowedAssets";
import { UserLoan } from "@lib/types";

export interface BorrowedList extends Omit<BorrowedListItemsProps, "loans">, FilterProps {
  /**
   * @dev
   * withLabel: will put a label to the table = "Your Borrow Balance"
   * Default:false
   */
  withLabel?: boolean;
  allTotalBorrowed?: number;
  borrowedList: Array<UserLoan>;
  collateralClick?: () => void;
  lockedClick?: () => void;
  borrowedClick?: () => void;
  borrowingFeeClick?: () => void;
  collRatioClick?: () => void;
  expiratioClick?: () => void;
  sortedColumn?: BorrowedColumns;
  sortDirection?: SortDirectionAsc;
  /**
   * user has a connected wallet
   */
  isUserActive?: boolean;
}

export enum BorrowedColumns {
  COLLATERAL = "collateralToken",
  LOCKED = "collateral",
  BORROWED = "netDebt",
  BORROWING_FEE = "borrowingFee",
  COLLRATIO = "collateralRatio",
  EXPIRATION = "expirationTime",
}

const BorrowedTable = ({
  loading,
  error,
  withLabel = false,
  allTotalBorrowed = 0,
  borrowedList,
  collateralClick,
  lockedClick,
  borrowedClick,
  borrowingFeeClick,
  collRatioClick,
  expiratioClick,
  sortedColumn,
  sortDirection,
  isUserActive = false,
  activeFilter,
  filterOptions,
  setFilter,
}: BorrowedList): JSX.Element => {
  return (
    <TableContainer sx={{ paddingBottom: "0.5rem" }}>
      <Header sx={{ flexDirection: "column", alignItems: "flex-start" }}>
        {withLabel && <BaseFlex className="label">Your Borrowed Balance</BaseFlex>}

        <BaseFlex sx={{ justifyContent: "space-between", width: "100%" }}>
          <Typography
            variant="numbers1"
            data-test="user-total-borrowed"
            sx={{ color: (theme) => theme.palette.common.white }}
          >
            {convertToCurrency(allTotalBorrowed)}
          </Typography>

          <Filter activeFilter={activeFilter} filterOptions={filterOptions} setFilter={setFilter} />
        </BaseFlex>
      </Header>

      <Box
        sx={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Wrapper>
          <LineGrid
            className="lineGrid"
            sx={(theme) => ({
              padding: 0,
              borderBottom: `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
            })}
          >
            <ColumnHeader
              sx={{ gridArea: "collateral", justifyContent: "start" }}
              onClick={collateralClick}
            >
              Collateral
              {sortedColumn !== BorrowedColumns.COLLATERAL || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "locked" }} onClick={lockedClick}>
              Locked
              {sortedColumn !== BorrowedColumns.LOCKED || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "borrowed" }} onClick={borrowedClick}>
              Borrowed
              {sortedColumn !== BorrowedColumns.BORROWED || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "borrowingFee" }} onClick={borrowingFeeClick}>
              Borrowing Fee
              {sortedColumn !== BorrowedColumns.BORROWING_FEE || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "collateralRatio" }} onClick={collRatioClick}>
              Coll. Ratio
              {sortedColumn !== BorrowedColumns.COLLRATIO || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>
            <ColumnHeader sx={{ gridArea: "expiration" }} onClick={expiratioClick}>
              Expiration
              {sortedColumn !== BorrowedColumns.EXPIRATION || sortDirection === undefined
                ? ""
                : sortDirection
                ? " ↑ "
                : " ↓ "}
            </ColumnHeader>

            <ColumnHeader
              sx={{
                gridArea: "action",
                justifyContent: "flex-end",
                "&:hover": {
                  cursor: "default",
                  opacity: 1,
                },
              }}
            >
              Action
            </ColumnHeader>
          </LineGrid>

          {isUserActive ? (
            <Fragment>
              <BorrowedListItems
                loans={{ activeFilter, list: borrowedList }}
                loading={loading}
                error={error}
              />
              <PendingBorrowList />
            </Fragment>
          ) : (
            <div>
              <Message message="Your wallet is not connected" />
            </div>
          )}
        </Wrapper>
      </Box>
    </TableContainer>
  );
};

interface BorrowedListItemsProps {
  loading: boolean;
  error: boolean;
  loans: {
    activeFilter: BORROWED_FILTER_OPTIONS;
    list: Array<UserLoan>;
  };
}

const BorrowedListItems = ({ loading, error, loans }: BorrowedListItemsProps): JSX.Element => {
  if (loading) {
    return (
      <div>
        <BorrowedLoader />
      </div>
    );
  }

  if (error && loans.list.length === 0) {
    return (
      <div>
        <Message message="An error occured" />
      </div>
    );
  }

  if (loans.list.length === 0) {
    return (
      <div>
        <Message
          message={
            loans.activeFilter === BORROWED_FILTER_OPTIONS.UNPAID
              ? "You have no unpaid loans"
              : loans.activeFilter === BORROWED_FILTER_OPTIONS.PAID
              ? "You have no repaid loans"
              : loans.activeFilter === BORROWED_FILTER_OPTIONS.EXPIRED
              ? "You have no expired loans"
              : "You have no loans"
          }
        />
      </div>
    );
  }

  return (
    <TransitionGroup component={null}>
      {loans.list.map((borrowed, index) => {
        return (
          // this provider is used so that we can toggle close a parent modal from a child
          // like in this case the the modal should close when a transaction is sent
          // but the one calling the close function is a grandchild of this component
          <Collapse component="div" key={borrowed.id}>
            <ToggleProvider key={borrowed.id}>
              <BorrowedListItem index={index} borrowed={borrowed} />
            </ToggleProvider>
          </Collapse>
        );
      })}
    </TransitionGroup>
  );
};

export default BorrowedTable;
