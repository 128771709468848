import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PeriodSelection } from "@components/common";
import { PeriodSelectionProps } from "@components/common/PeriodSelection/PeriodSelection";

export interface ControlledPeriodSelectionProps
  extends Pick<
    PeriodSelectionProps,
    "periods" | "nextRoundTimeStamp" | "nextRoundTimestampReadable"
  > {
  periodSelectorName: string;
}

const ControlledPeriodSelection = ({
  periodSelectorName,
  periods,
  nextRoundTimeStamp,
  nextRoundTimestampReadable,
}: ControlledPeriodSelectionProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={periodSelectorName}
      control={control}
      rules={{
        validate: {
          isNotBlank: (value) => {
            if (value === null) {
              return "This is required.";
            }
          },
          isNotZero: (value) => {
            if (parseFloat(value) === 0) {
              return "This is required.";
            }
          },
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <PeriodSelection
          value={field.value}
          periods={periods}
          nextRoundTimeStamp={nextRoundTimeStamp}
          nextRoundTimestampReadable={nextRoundTimestampReadable}
          onChange={(e, value) => {
            field.onChange(value);
          }}
          error={error?.message}
        />
      )}
    />
  );
};

export default ControlledPeriodSelection;
