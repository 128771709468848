import React from "react";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ActionButton, CurvyCheck, ExternalLinkIcon } from "@components/common";
import { Content, MessageTitle, SubMessage } from "../TransactionStatusMessageComponents";
import { TransactionStatusMessageBaseProps } from "../types";

export interface MessageSubmittedProps extends TransactionStatusMessageBaseProps {
  transactionURL: string;
}

const SubmittedMsg = ({
  transactionURL,
  message,
  onClose,
  show = false,
}: MessageSubmittedProps): JSX.Element | null => {
  if (!show) return null;

  return (
    <Content data-test="transaction-submitted-message">
      <CurvyCheck sx={{ height: "56px", width: "56px" }} />

      <Box sx={{ display: "flex", gap: "0.4375rem", flexDirection: "column" }}>
        <MessageTitle>Transaction submitted</MessageTitle>
        {message && <SubMessage>{message}</SubMessage>}
      </Box>

      <MuiLink
        href={transactionURL}
        rel="noopener noreferrer"
        target="_blank"
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: "0.5em",
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <Typography variant="caption1">View on Explorer</Typography>
        <ExternalLinkIcon />
      </MuiLink>

      <ActionButton data-test="close-message" onClick={onClose} className="actionButton">
        Close
      </ActionButton>
    </Content>
  );
};

export default SubmittedMsg;
