import { useCallback, useEffect } from "react";
import { useContract, useMultiCall, useWeb3 } from "@lib/hooks";

import { LendingPool } from "@coraprotocol/core/typechain";
import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import IPriceFeedAbi from "@coraprotocol/core/abis/IPriceFeed.json";
import { useLPPositionsStore } from "@lib/providers/";
import { useBlockNumber } from "@lib/providers";
import { BigNumber } from "ethers";
import { PoolI } from "@lib/types";

const getConfig = ({
  user,
  poolId,
  priceFeedAddress,
  debtTokenAddress,
  collateralTokenAddress,
}: {
  user: string;
  poolId: string;
  priceFeedAddress: string;
  debtTokenAddress: string;
  collateralTokenAddress: string;
}) => [
  {
    target: poolId,
    reference: "getCurrentRoundIndex",
    method: "getCurrentRoundIndex",
    abi: LendingPoolAbi,
  },
  {
    target: poolId,
    reference: "getPendingDeposit",
    method: "getPendingDeposit",
    abi: LendingPoolAbi,
    args: [user],
  },
  {
    target: poolId,
    reference: "getPendingWithdrawal",
    method: "getPendingWithdrawal",
    abi: LendingPoolAbi,
    args: [user],
  },
  {
    target: priceFeedAddress,
    reference: "getAssetPriceDebtToken",
    method: "getAssetPrice",
    abi: IPriceFeedAbi,
    args: [debtTokenAddress],
  },
  {
    target: priceFeedAddress,
    reference: "getAssetPriceCollateralToken",
    method: "getAssetPrice",
    abi: IPriceFeedAbi,
    args: [collateralTokenAddress],
  },
];

const Callbacks = ({
  pool,
}: {
  pool: Pick<PoolI, "id" | "collateralToken" | "debtToken" | "priceFeed">;
}): null => {
  const coraContract = useContract<LendingPool>(pool?.id, LendingPoolAbi);
  const { data: someLPData, call: callSomeLPData } = useMultiCall<{
    getCurrentRoundIndex: BigNumber;
    getPendingDeposit: { amount: BigNumber; roundIndex: BigNumber };
    getPendingWithdrawal: { amount: BigNumber; roundIndex: BigNumber };
    getAssetPriceDebtToken: BigNumber;
    getAssetPriceCollateralToken: BigNumber;
  }>();
  const updateAdjustLPDeposit = useLPPositionsStore((state) => state.updateAdjustLPDeposit);
  const updatePendingDeposit = useLPPositionsStore((state) => state.updatePendingDeposit);
  const updatePendingWithdrawal = useLPPositionsStore((state) => state.updatePendingWithdrawal);
  const updateAvailableWithdrawal = useLPPositionsStore((state) => state.updateAvailableWithdrawal);
  const updateCurrentIndex = useLPPositionsStore((state) => state.updateCurrentIndex);
  const updatePrices = useLPPositionsStore((state) => state.updatePrices);
  const blockNumber = useBlockNumber();
  const { account } = useWeb3();

  const fetchLPPositions = useCallback(
    async (poolId: string) => {
      try {
        const data = await coraContract?.callStatic.adjustLpDeposit();

        if (!data) return;

        updateAdjustLPDeposit({
          poolId,
          activeLiquidity: { amount: data[0] },
          pendingCollateral: { amount: data[1] },
          feesEarned: { amount: data[2] },
        });
      } catch (error) {
        updateAdjustLPDeposit({
          poolId,
          activeLiquidity: { amount: undefined },
          pendingCollateral: { amount: undefined },
          feesEarned: { amount: undefined },
        });
      }
    },
    [coraContract]
  );

  const fetchAvailableWithdraw = useCallback(
    async (poolId: string) => {
      try {
        const data = await coraContract?.callStatic.withdraw();

        if (!data) return;

        updateAvailableWithdrawal({
          poolId,
          amount: data,
        });
      } catch (error) {
        updateAvailableWithdrawal({
          poolId,
          amount: undefined,
        });
      }
    },
    [coraContract]
  );

  useEffect(
    function fetchDataFromBlockchain() {
      if (!blockNumber) return;
      if (!coraContract) return;
      if (!account) return;

      fetchLPPositions(pool.id);
      callSomeLPData(
        getConfig({
          user: account,
          poolId: pool.id,
          priceFeedAddress: pool.priceFeed,
          debtTokenAddress: pool.debtToken.id,
          collateralTokenAddress: pool.collateralToken.id,
        })
      );
    },
    [blockNumber, coraContract, account]
  );

  useEffect(
    function fetchAvailableWithdrawEffect() {
      if (!coraContract) return;
      if (!account) return;

      fetchAvailableWithdraw(pool.id);
    },
    [
      coraContract,
      account,
      JSON.stringify(someLPData?.getCurrentRoundIndex),
      JSON.stringify(someLPData?.getPendingWithdrawal?.amount),
    ]
  );

  useEffect(() => {
    if (!someLPData?.getCurrentRoundIndex) return;

    updateCurrentIndex({ poolId: pool.id, index: someLPData.getCurrentRoundIndex.toNumber() });
  }, [someLPData?.getCurrentRoundIndex]);

  useEffect(() => {
    if (!someLPData?.getPendingDeposit?.amount) return;

    updatePendingDeposit({
      poolId: pool.id,
      amount: someLPData.getPendingDeposit.amount,
      roundIndex: someLPData.getPendingDeposit.roundIndex.toNumber(),
    });
  }, [someLPData?.getPendingDeposit]);

  useEffect(() => {
    if (!someLPData?.getPendingWithdrawal?.amount) return;

    updatePendingWithdrawal({
      poolId: pool.id,
      amount: someLPData.getPendingWithdrawal.amount,
      roundIndex: someLPData.getPendingWithdrawal.roundIndex.toNumber(),
    });
  }, [someLPData?.getPendingWithdrawal]);

  useEffect(() => {
    if (!someLPData?.getAssetPriceCollateralToken) return;
    if (!someLPData?.getAssetPriceDebtToken) return;

    updatePrices({
      poolId: pool.id,
      debtTokenPrice: someLPData?.getAssetPriceDebtToken,
      collateralTokenPrice: someLPData?.getAssetPriceCollateralToken,
    });
  }, [someLPData?.getAssetPriceCollateralToken, someLPData?.getAssetPriceDebtToken]);

  return null;
};

export default Callbacks;
