import React, { Fragment } from "react";
import UserLiquidityItemUI from "./UserLiquidityItem";
import { Message } from "@components/common/Table/UserLiquidity/UserLiquidityComponents";
import UserLiquidityLoader from "@components/common/Table/UserLiquidity/UserLiquidityLoader";
import { useLPPositionsStore } from "@lib/providers/";
import PendingDepositList from "@components/common/Table/UserLiquidity/PendingDepositList";
import PendingCancelDepositList from "@components/common/Table/UserLiquidity/PendingCancelDepositList";
import PendingCancelWithdrawalList from "@components/common/Table/UserLiquidity/PendingCancelWithdrawalList";
import PendingWithdrawAvailableList from "@components/common/Table/UserLiquidity/PendingWithdrawAvailableList";
import PendingSignalWithdrawList from "@components/common/Table/UserLiquidity/PendingSignalWithdrawList";
import PendingWithdrawCollateralList from "@components/common/Table/UserLiquidity/PendingWithdrawCollateralList";

const UserLiquidityItemsUI = (): JSX.Element => {
  const positions = useLPPositionsStore((state) => state.positions);
  const { error, loading } = useLPPositionsStore((state) => state.status);

  if (loading) {
    return (
      <div>
        <UserLiquidityLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Message message="An error occured" />
      </div>
    );
  }

  return (
    <Fragment>
      {Object.keys(positions).map((lendingPool) => {
        return <UserLiquidityItemUI key={lendingPool} poolId={lendingPool} />;
      })}
      <PendingDepositList />
      <PendingCancelDepositList />
      <PendingCancelWithdrawalList />
      <PendingWithdrawAvailableList />
      <PendingSignalWithdrawList />
      <PendingWithdrawCollateralList />
    </Fragment>
  );
};

export default UserLiquidityItemsUI;
