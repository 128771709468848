import MultiCallABi from "@lib/contracts/abi/MultiCallABI.json";
import { MultiCallABI as MultiCallType } from "@lib/contracts/abi/types/MultiCallABI";
import { useContract } from "@lib/hooks";
import { Interface } from "ethers/lib/utils";
import { useState, useCallback } from "react";

export type UseMultiCallAggregateProps = Array<{
  target: string;
  abi: any;
  reference: string;
  method: string;
  args?: any[];
}>;

type MultiCallAggregateResult = {
  [key: string]: any;
};

type MultiCallAggregate3Result = Array<{
  success: boolean;
  returnData: string;
}>;

const useMultiCallAggregate = <T extends MultiCallAggregateResult>(): {
  data: T;
  call: (config: UseMultiCallAggregateProps) => Promise<void>;
  loading: boolean;
  error?: boolean;
} => {
  const multiCallContract = useContract<MultiCallType>(
    "0xcA11bde05977b3631167028862bE2a173976CA11",
    MultiCallABi
  );
  const [data, setData] = useState<T>({} as T);
  const [status, setStatus] = useState<{ loading: boolean; error?: boolean }>({ loading: true });

  const call = useCallback(
    async (config: UseMultiCallAggregateProps) => {
      if (!multiCallContract) return;
      const configForCall = config.map(({ target, abi, method, args }) => {
        return {
          target,
          callData: new Interface(abi).encodeFunctionData(method, args),
          // TODO: allow failure, change this when the this hook can differentiate config changes
          allowFailure: true,
        };
      });

      try {
        let data: T = {} as T;

        const results = (await multiCallContract.aggregate3(
          configForCall
        )) as MultiCallAggregate3Result;

        results.map(({ success, returnData }, index) => {
          if (success) {
            const decodeData = new Interface(config[index].abi).decodeFunctionResult(
              config[index].method,
              returnData
            );

            const result =
              Array.isArray(decodeData) && decodeData.length === 1 ? decodeData[0] : decodeData;

            data = { ...data, [config[index].reference]: result };
          }
        });
        setData(data);
      } catch (error) {
        setStatus((prev) => ({ ...prev, error: true }));
        console.error(error);
      } finally {
        setStatus((prev) => ({ ...prev, loading: false }));
      }
    },
    [multiCallContract]
  );

  return { data, call, ...status };
};

export default useMultiCallAggregate;
