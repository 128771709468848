import React, { useEffect, useState } from "react";
import { useWeb3, useWithdrawAvailable } from "@lib/hooks";
import { WithdrawAvailableTransactionCard } from "@components/common";
import { useLPPositionsStore } from "@lib/providers";
import { TransactionStatusMessage } from "@components/ui/TransactionStatusMessage";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TransactionStatus, TransactionType } from "@lib/types";
import { useModalManager } from "@components/ui/ManageLiquidity/modalManager/ModalManager";

const WithdrawAvailable = ({ poolAddress }: { poolAddress: string }): JSX.Element => {
  const [withdrawStatusSync, setWithdrawStatusSync] = useState<TransactionStatus | "form">("form");

  const { active, chainId } = useWeb3();
  const { setOpen: setOpenParent } = useModalManager();

  const { availableWithdrawal, poolDetails } = useLPPositionsStore(
    (state) => state.positions[poolAddress]
  );
  const {
    withdrawAvailable,
    transactionStatus: withdrawAvailableStatus,
    error: withdrawAvailableError,
    response: withdrawAvailableResponse,
  } = useWithdrawAvailable(poolAddress);

  const handleSubmit = () => {
    withdrawAvailable({
      poolName: poolDetails.name,
      debtToken: {
        ...poolDetails.debtToken,
        amount: availableWithdrawal.formattedAmount as string,
        amountUnitPriceInUSD: poolDetails.debtToken.priceInUSD,
      },
    });
  };

  useEffect(() => {
    if (withdrawAvailableStatus) {
      setWithdrawStatusSync(withdrawAvailableStatus);
    }
  }, [withdrawAvailableStatus]);

  return withdrawStatusSync === "form" ? (
    <WithdrawAvailableTransactionCard
      loading={!availableWithdrawal?.formattedAmount}
      withdrawAvailable={availableWithdrawal?.formattedAmount as string}
      debtToken={{ ...poolDetails.debtToken }}
      isSubmitting={
        withdrawAvailableStatus === "confirm" || withdrawAvailableStatus === "submitted"
      }
      onSubmit={handleSubmit}
      isValid={active}
    />
  ) : (
    <TransactionStatusMessage
      transactionType={TransactionType.WithdrawCollateral}
      status={withdrawAvailableStatus}
      chainId={chainId as number}
      onClose={() => setOpenParent(null)}
      onBackToForm={() => setWithdrawStatusSync("form")}
      transactionHash={withdrawAvailableResponse?.hash as string}
      errorMessage={withdrawAvailableError}
      confirmMessage={`Withdrawing the amount of ${commaSeparateNumbers(
        truncateDecimal(availableWithdrawal?.formattedAmount || "0") //  this is needed because the amount goes to undefined when withdrawal is finished
      )} ${poolDetails.debtToken.symbol}.`}
    />
  );
};

export default WithdrawAvailable;
