import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import { Wrapper } from "./ManageLiquidityComponents";
import { hexToRgba } from "@lib/utils";

const SubDetails = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Wrapper>
      <Box
        sx={{
          width: "100%",
          "& .detail": {
            borderBottom: (theme) => `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
          },
          "& .detail:first-of-type": {
            paddingTop: 0,
          },
          "& .detail:nth-last-of-type(1)": {
            borderBottom: "none",
            paddingBottom: 0,
          },
        }}
      >
        {children}
      </Box>
    </Wrapper>
  );
};

export default SubDetails;
