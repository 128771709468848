import React from "react";
import Typography from "@mui/material/Typography";
import { BaseFlex } from "@components/common";
import { Detail, DetailsWrapper } from "../TransactionCardBase";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";
import { TokenI } from "@lib/types";

export interface NewPositionDetailsProps {
  loading: boolean;
  activeLiquidity: string;
  pendingDeposit: string;
  additionalDeposit: string;
  willBeActiveIn: string;
  token: TokenI;
}

const NewPositionDetails = ({
  loading,
  activeLiquidity,
  pendingDeposit,
  additionalDeposit,
  willBeActiveIn,
  token,
}: NewPositionDetailsProps): JSX.Element => {
  return (
    <BaseFlex
      sx={{
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.5rem",
      }}
    >
      <Typography
        variant="headline2"
        sx={{
          color: (theme) => theme.palette.common.white,
        }}
      >
        New Position
      </Typography>

      <DetailsWrapper>
        <Detail
          label="Provided Liquidity"
          detail={`${commaSeparateNumbers(
            parseFloat(activeLiquidity) + parseFloat(pendingDeposit) + parseFloat(additionalDeposit)
          )} ${token?.symbol}`}
          loading={loading}
        />
        <Detail
          label="Pending Deposit"
          detail={`${commaSeparateNumbers(
            truncateDecimal((parseFloat(additionalDeposit) + parseFloat(pendingDeposit)).toString())
          )} ${token?.symbol}`}
          loading={loading}
        />
        <Detail label="Pending Withdrawal" detail={`0.00 ${token?.symbol}`} loading={loading} />
        <Detail label="Will be active in" detail={willBeActiveIn} loading={loading} />
      </DetailsWrapper>
    </BaseFlex>
  );
};

export default NewPositionDetails;
