import React, { Fragment } from "react";
import { styled } from "@mui/material";
import Image from "next/image";
import { useToggle } from "@lib/providers";
import { BaseFlex, Hint } from "@components/common";
import { SupplyModal } from "@components/ui";
import { CellData, CellLoader, LogoWrapper, RowActionButton } from "../TableComponents";
import { abbrNumber, decimalToPercentage, hexToRgba } from "@lib/utils";
import { PoolParams } from ".";

export const LineGrid = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 0.7fr 0.7fr 0.7fr 0.6fr",
  gridTemplateAreas: `'debtToken collateralToken totalDeposits apy utilization maxCap action'`,
  padding: "1.54rem 0",
});

export const Wrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    minWidth: "34.375rem",
    maxWidth: "50rem",
  },

  "& .lineGrid": {
    borderBottom: `1px solid ${hexToRgba(theme.palette.common.white, 0.2)}`,
  },
  "& .lineGrid:nth-last-of-type(1)": {
    borderBottom: "none",
  },

  "& .lineGrid:nth-of-type(1)": {
    padding: "0 0 1rem",
    marginTop: 0,
  },
}));

export const Message = ({ message }: { message: string }): JSX.Element => {
  return (
    <LineGrid
      sx={{
        display: "flex",
        justifyContent: {
          xs: "flex-start",
          sm: "center",
        },
        color: (theme) => hexToRgba(theme.palette.common.white, 0.6),
      }}
    >
      {message}
    </LineGrid>
  );
};

interface PoolItemProps {
  index: number;
  pool: PoolParams;
  connected?: boolean;
}

export const PoolItem = ({ index, pool, connected = false }: PoolItemProps): JSX.Element => {
  const {
    id,
    debtToken,
    collateralToken,
    apy,
    utilization,
    availableLiquidity,
    maxCap,
    collateralTokenLogoUrl,
    stableCoinTokenlogoUrl,
  } = pool;
  const { open: showModal, setOpen: setShowModal } = useToggle();

  return (
    <Fragment>
      <LineGrid data-test={`poolItem-${index}`} className="lineGrid">
        <CellData data-test="debtToken" sx={{ gridArea: "debtToken", justifyContent: "start" }}>
          <BaseFlex>
            <LogoWrapper>
              {/* @ todo */}
              {stableCoinTokenlogoUrl ? (
                <Image src={stableCoinTokenlogoUrl} height={18} width={18} />
              ) : (
                <Image src="/assets/tokenImages/usd-coin-usdc-logo.png" height={18} width={18} />
              )}
            </LogoWrapper>
            {debtToken.name} &nbsp;
            {debtToken.name && collateralToken.name && (
              <Hint
                title={`Provide ${debtToken.name} for collateralized ${collateralToken.name} loans.`}
              />
            )}
          </BaseFlex>
        </CellData>

        <CellData data-test="collateralToken" sx={{ gridArea: "collateralToken" }}>
          <BaseFlex>
            <LogoWrapper>
              {/* @ todo */}
              {collateralTokenLogoUrl ? (
                <Image src={collateralTokenLogoUrl} height={18} width={18} />
              ) : (
                <Image src="/assets/tokenImages/Ethereum-ETH-icon.png" height={18} width={18} />
              )}
            </LogoWrapper>
            {collateralToken.name} &nbsp;
            {collateralToken.name && (
              <Hint
                title={`Loans taken from this pool would require ${collateralToken.name} as collateral`}
              />
            )}
          </BaseFlex>
        </CellData>

        <CellData data-test="total-deposits" sx={{ gridArea: "totalDeposits" }}>
          {abbrNumber(availableLiquidity)} {debtToken.symbol}
        </CellData>

        <CellData data-test="apy" sx={{ gridArea: "apy" }}>
          {decimalToPercentage(apy)}
        </CellData>

        <CellData data-test="utilization" sx={{ gridArea: "utilization" }}>
          {utilization !== undefined ? (
            decimalToPercentage(utilization)
          ) : (
            <CellLoader variant="text" width="30%" />
          )}
        </CellData>

        <CellData data-test="maxCap" sx={{ gridArea: "maxCap" }}>
          {abbrNumber(maxCap)}
        </CellData>

        <CellData data-test="action" sx={{ gridArea: "action", justifyContent: "flex-end" }}>
          <RowActionButton
            name="Supply"
            handleClick={() => setShowModal(true)}
            disabled={!connected}
          />
        </CellData>
      </LineGrid>

      <SupplyModal open={showModal} onClose={() => setShowModal(false)} poolAddress={id} />
    </Fragment>
  );
};
