import React, {
  createContext,
  Dispatch,
  forwardRef,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BaseFlex, Stepper, Hint } from "@components/common";
import { useDebounce, useVisible } from "@lib/hooks";

interface DetailsProps {
  id: string;
  title: string;
  diagram?: string;
  description: ReactNode;
}
const Detail = forwardRef<HTMLDivElement, DetailsProps>(
  ({ id, title, diagram, description }, ref): JSX.Element => {
    const anchor = useRef(null) as unknown as MutableRefObject<HTMLInputElement>;
    const isVisible = useVisible(anchor);
    const { setIndex } = useContext(StrategyContext);
    const isVisibleBuffered = useDebounce(isVisible, 300);

    useEffect(() => {
      if (isVisibleBuffered) {
        setIndex(parseInt(id));
      }
    }, [isVisibleBuffered]);

    return (
      <BaseFlex
        ref={ref}
        sx={{
          flexShrink: 0,

          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "start",
          padding: 0,
          alignContent: "space-between",

          transformOrigin: "center center",
          transform: "scale(1)",
          scrollSnapAlign: "center",
        }}
      >
        <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "start" }}>
          <Typography variant="buttonM" sx={{ color: (theme) => theme.palette.secondary.main }}>
            {title}
          </Typography>
          <Typography variant="caption2" sx={{ color: (theme) => theme.palette.common.white }}>
            {description}
          </Typography>

          <Box
            sx={{
              flexShrink: 0,
              height: "70px",
              width: "100px",
              position: "relative",
              display: "flex",
              justifyContent: "start",
              margin: "0.5em 0",
            }}
          >
            {diagram && <Image src={diagram} layout="fill" objectFit="scale-down" />}
          </Box>
          <div ref={anchor} style={{ position: "absolute", right: "50%" }} />
        </BaseFlex>
      </BaseFlex>
    );
  }
);

interface DetailSliderProps {
  detailList: Array<DetailsProps>;
}

const DetailSlider = ({ detailList }: DetailSliderProps): JSX.Element => {
  const { index } = useContext(StrategyContext);
  const refs: Record<string, any> = detailList.reduce<Record<string, any>>((acc, curr) => {
    acc[curr.id] = useRef(null);
    return acc;
  }, {});

  useEffect(() => {
    refs[index].current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [index, refs]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            overflowX: "scroll",
            position: "relative",
            scrollBehavior: "smooth",
            scrollSnapType: "x mandatory",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {detailList.map((detail, index) => (
            <Detail
              ref={refs[index]}
              key={detail.id}
              id={detail.id}
              title={detail.title}
              diagram={detail.diagram}
              description={detail.description}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const StrategyContext = createContext<{
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
}>({
  index: 0,
  setIndex: () => undefined,
});

type StrategyDetailsProps = DetailSliderProps;

const StrategyDetails = ({ detailList }: StrategyDetailsProps): JSX.Element => {
  const [index, setIndex] = useState(0);

  const incrementIndex = () => {
    if (index < 3) {
      setIndex(index + 1);
    }
  };

  const decrementIndex = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <StrategyContext.Provider value={{ index, setIndex }}>
      <BaseFlex
        sx={{
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            alignSelf: "start",
            marginBottom: "0.75em",
          }}
        >
          <BaseFlex sx={{ gap: "1em", justifyContent: "start" }}>
            <Typography
              variant="headline2"
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
            >
              Strategy Details
            </Typography>
            <Hint title="Learn the lifecycle of the Cora Lending Pools" />
          </BaseFlex>
        </Box>

        <Box sx={{ width: "100%" }}>
          <DetailSlider detailList={detailList} />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Stepper
            max={3}
            activeIndex={index}
            setIndex={(index) => setIndex(index)}
            increment={incrementIndex}
            decrement={decrementIndex}
          />
        </Box>
      </BaseFlex>
    </StrategyContext.Provider>
  );
};

export default StrategyDetails;
