import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CopyIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.08 2.04a1.44 1.44 0 00-1.44 1.44v5.76c0 .795.645 1.44 1.44 1.44h4.32a1.44 1.44 0 001.44-1.44V5.218a1.44 1.44 0 00-.422-1.018L10.68 2.462a1.44 1.44 0 00-1.018-.422H7.08z"
        fill="currentColor"
      />
      <path
        d="M2.76 6.36c0-.795.645-1.44 1.44-1.44v7.2h5.76a1.44 1.44 0 01-1.44 1.44H4.2a1.44 1.44 0 01-1.44-1.44V6.36z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CopyIcon;
