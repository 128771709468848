import React from "react";
import { Detail } from "@components/common/ManageLiquidity/ManageLiquidityComponents";
import { useLPPositionsStore } from "@lib/providers/";
import { commaSeparateNumbers, truncateDecimal } from "@lib/utils";

const FeesEarnedUI = ({ poolId }: { poolId: string }): JSX.Element => {
  const position = useLPPositionsStore((state) => state.positions[poolId]);

  return (
    <Detail
      loading={!position?.feesEarned.formattedAmount}
      label="Fees"
      detail={
        position?.feesEarned.formattedAmount
          ? `${commaSeparateNumbers(truncateDecimal(position?.feesEarned.formattedAmount))} ${
              position?.poolDetails.debtToken.symbol
            }`
          : undefined
      }
    />
  );
};

export default FeesEarnedUI;
