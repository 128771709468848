/**
 * calculate if a loan term is allowable
 * for example a loan is not allowed when the end of term is happening during the next round
 *
 * @param loanTerm the loan term in seconds
 * @param nextRoundTimeStamp the unix timestamp of the next round
 */
const isLoanTermAllowable = (loanTerm: number, nextRoundTimeStamp: number): boolean => {
  const endOfLoanTerm = loanTerm + Date.now() / 1000;
  return endOfLoanTerm < nextRoundTimeStamp;
};

export default isLoanTermAllowable;
