import React from "react";
import Box from "@mui/material/Box";
import { HintTooltip } from "@components/common";
import { RowActionButton, RowActionButtonProps } from "../TableComponents";
import { convertTime, isLoanTermAllowable } from "@lib/utils";

interface BorrowActionButtonProps extends Omit<RowActionButtonProps, "name" | "disabled"> {
  isConnected: boolean;
  leastLoanTerm: number;
  nextRoundTimestamp: number;
  nextRoundTimestampReadable: string;
}

const BorrowActionButton = ({
  isConnected,
  leastLoanTerm,
  nextRoundTimestamp,
  nextRoundTimestampReadable,
  ...props
}: BorrowActionButtonProps): JSX.Element => {
  if (!isLoanTermAllowable(leastLoanTerm, nextRoundTimestamp)) {
    return (
      <HintTooltip
        title={`The next round opens at ${convertTime(nextRoundTimestamp).full}`}
        disableFocusListener
      >
        <Box
          sx={(theme) => ({
            ...theme.typography.buttonM,
            borderRadius: "0.5rem",
            padding: "0.25rem",
            backgroundColor: (theme) => theme.palette.secondary[600],
            color: (theme) => theme.palette.dark.main,
            minWidth: "0px",
          })}
        >
          Opens in {nextRoundTimestampReadable}
        </Box>
      </HintTooltip>
    );
  }

  return <RowActionButton name="Borrow" disabled={!isConnected} {...props} />;
};

export default BorrowActionButton;
