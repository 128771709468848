import MuiSlider, { sliderClasses, SliderProps } from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { gradient3, gradient4 } from "styles/color";

const DEFAULT_MARKS = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.25,
    label: "25%",
  },
  {
    value: 0.5,
    label: "50%",
  },
  {
    value: 0.75,
    label: "75%",
  },
  {
    value: 1,
    label: "100%",
  },
];

export type CustomSliderProps = SliderProps;

const Slider = styled(
  (props: CustomSliderProps) => (
    <Box sx={{ padding: "0 5px", width: "100%" }}>
      <MuiSlider step={0.01} max={1} marks={DEFAULT_MARKS} {...props} />
    </Box>
  ),
  {
    shouldForwardProp: (prop) => prop !== "color",
  }
)(({ theme }) => ({
  [`& .${sliderClasses.thumb}`]: {
    background: gradient3,
    "::before": {
      content: "''",
      background: theme.palette.common.white,
      width: "9px",
      height: "9px",
      boxShadow: "none",
    },

    width: "15px",
    height: "15px",
    boxShadow: "none",

    ":hover": {
      boxShadow: "none",
    },
  },
  [`& .${sliderClasses.track}`]: {
    background: gradient4,

    height: "3px",
    border: "none",
  },
  [`& .${sliderClasses.rail}`]: {
    color: theme.palette.primary[800],
    height: "4px",
  },
  [`& .${sliderClasses.mark}`]: {
    width: "8px",
    height: "8px",
    borderRadius: "999px",
    backgroundColor: theme.palette.common.white,
    transform: "translate(-4px, -50%)",
    opacity: 1,
  },
  [`& .${sliderClasses.markActive}`]: {
    width: "8px",
    height: "8px",
    borderRadius: "999px",
    background: gradient3,
    transform: "translate(-4px, -50%)",
  },
  [`& .${sliderClasses.markLabel}`]: {
    ...theme.typography.caption1,
    color: theme.palette.primary[300],
  },

  [`span.${sliderClasses.markLabel}:nth-of-type(4)`]: {
    transform: "translateX(-20%)",
  },
  [`span.${sliderClasses.markLabel}:nth-last-of-type(2)`]: {
    transform: "translateX(-80%)",
  },
}));

export default Slider;
