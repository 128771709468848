import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { BaseFlex, Hint } from "@components/common";
import { Wrapper } from "./ManageLiquidityComponents";
import { hexToRgba } from "@lib/utils";

export interface MainDetailsProps {
  activeLiquidity: string | undefined;
  withdrawActionButton: ReactNode;
  supplyActionButton: ReactNode;
  loading?: boolean;
}

const MainDetails = ({
  activeLiquidity,
  loading,
  withdrawActionButton,
  supplyActionButton,
}: MainDetailsProps): JSX.Element => {
  return (
    <Wrapper>
      <BaseFlex
        sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start", gap: "2.9375rem" }}
      >
        <BaseFlex sx={{ flexDirection: "column", alignItems: "flex-start", gap: "1.6875rem" }}>
          <BaseFlex
            sx={{
              gap: "1em",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="body1" sx={{ color: (theme) => theme.palette.common.white }}>
              Active Liquidity
            </Typography>
            <Hint title="Active Liquidity = Active deposits + Fees Accumulated" />
          </BaseFlex>

          <Box>
            {loading ? (
              <Skeleton
                sx={(theme) => ({
                  ...theme.typography.numbers1,
                  backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.3),
                  width: "20rem",
                })}
              />
            ) : (
              <Typography variant="numbers1" sx={{ color: (theme) => theme.palette.common.white }}>
                {activeLiquidity}
              </Typography>
            )}
          </Box>
        </BaseFlex>

        <BaseFlex sx={{ width: "100%", justifyContent: "flex-start", gap: "1em" }}>
          {withdrawActionButton}
          {supplyActionButton}
        </BaseFlex>
      </BaseFlex>
    </Wrapper>
  );
};

export default MainDetails;
