import createStore from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { getTotalLiquidityThatCanBeSignaledForWithdrawal } from "./utils";
import { LPPositions } from "@lib/types";

interface LPPositionState {
  positions: Record<string, LPPositions>;
  status: { loading: boolean; error?: boolean };
  currentIndex: Record<string, number | undefined>;
}

const initialState: LPPositionState = {
  positions: {},
  status: { loading: true, error: false },
  currentIndex: {},
};

interface LocalPositionsI extends LPPositionState {
  statusUpdate: (payload: LPPositionState["status"]) => void;
  updateCurrentIndex: (payload: { poolId: string; index: number | undefined }) => void;
  addPosition: (payload: Pick<LPPositions, "poolDetails"> & { poolId: string }) => void;
  updatePendingDeposit: (
    payload: { poolId: string } & Omit<LPPositions["pendingDeposit"], "formattedAmount">
  ) => void;
  updateAvailableWithdrawal: (
    payload: { poolId: string } & Omit<LPPositions["availableWithdrawal"], "formattedAmount">
  ) => void;
  updatePendingWithdrawal: (
    payload: { poolId: string } & Omit<LPPositions["pendingWithdrawal"], "formattedAmount">
  ) => void;
  updatePrices: ({
    poolId,
    debtTokenPrice,
    collateralTokenPrice,
  }: {
    poolId: string;
    debtTokenPrice?: BigNumber;
    collateralTokenPrice?: BigNumber;
  }) => void;
  updateAdjustLPDeposit: (payload: {
    poolId: string;
    activeLiquidity: Omit<LPPositions["activeLiquidity"], "formattedAmount">;
    pendingCollateral: Omit<LPPositions["pendingCollateral"], "formattedAmount">;
    feesEarned: Omit<LPPositions["feesEarned"], "formattedAmount">;
  }) => void;
}

export const useLPPositionsStore = createStore<
  LocalPositionsI,
  [["zustand/devtools", LocalPositionsI], ["zustand/immer", LocalPositionsI]]
>(
  devtools(
    immer((set, _get) => ({
      ...initialState,
      updateCurrentIndex: ({ poolId, index }) =>
        set(
          (state) => {
            state.currentIndex[poolId] = index;
          },
          false,
          "updateCurrentIndex"
        ),
      statusUpdate: (payload) =>
        set((state) => {
          state.status = payload;
        }),
      addPosition: ({ poolId, poolDetails }) =>
        set(
          (state) => {
            state.positions[poolId] = {
              pendingDeposit: { amount: undefined, formattedAmount: undefined },
              pendingWithdrawal: { amount: undefined, formattedAmount: undefined },
              pendingCollateral: { amount: undefined, formattedAmount: undefined },
              feesEarned: { amount: undefined, formattedAmount: undefined },
              availableWithdrawal: { amount: undefined, formattedAmount: undefined },
              activeLiquidity: { amount: undefined, formattedAmount: undefined },
              totalLiquidityThatCanBeSignaledForWithdrawal: {
                amount: undefined,
                formattedAmount: undefined,
              },
              poolDetails,
            };
          },
          false,
          "addPosition"
        ),
      updatePendingDeposit: ({ poolId, ...rest }) => {
        set(
          (state) => {
            // force amount to 0 when round index for deposit is smaller or equal to current round index
            const currentIndex = state.currentIndex[poolId];
            const amount =
              currentIndex !== undefined && rest.roundIndex !== undefined
                ? currentIndex >= rest.roundIndex
                  ? BigNumber.from(0)
                  : rest.amount
                : undefined;

            state.positions[poolId].pendingDeposit = {
              roundIndex: rest.roundIndex,
              amount,
              formattedAmount:
                amount && state.positions[poolId].poolDetails.debtToken.decimals
                  ? formatUnits(amount, state.positions[poolId].poolDetails.debtToken.decimals)
                  : undefined,
            };

            const totalLiquidityThatCanBeSignaledForWithdrawal =
              getTotalLiquidityThatCanBeSignaledForWithdrawal({
                activeLiquidity: state.positions[poolId].activeLiquidity.amount,
                pendingWithdrawal: state.positions[poolId].pendingWithdrawal.amount,
              });

            state.positions[poolId].totalLiquidityThatCanBeSignaledForWithdrawal = {
              amount: totalLiquidityThatCanBeSignaledForWithdrawal,
              formattedAmount: totalLiquidityThatCanBeSignaledForWithdrawal
                ? formatUnits(
                    totalLiquidityThatCanBeSignaledForWithdrawal,
                    state.positions[poolId].poolDetails.debtToken.decimals
                  )
                : undefined,
            };
          },
          false,
          "updatePendingDeposit"
        );
      },
      updatePendingWithdrawal: ({ poolId, ...rest }) => {
        set(
          (state) => {
            // force amount to 0 when round index for withdraw is smaller to current round index
            const currentIndex = state.currentIndex[poolId];
            const amount =
              currentIndex !== undefined && rest.roundIndex !== undefined
                ? currentIndex > rest.roundIndex
                  ? BigNumber.from(0)
                  : rest.amount
                : undefined;

            state.positions[poolId].pendingWithdrawal = {
              ...rest,
              amount,
              formattedAmount:
                amount && state.positions[poolId].poolDetails.debtToken.decimals
                  ? formatUnits(amount, state.positions[poolId].poolDetails.debtToken.decimals)
                  : undefined,
            };

            const totalLiquidityThatCanBeSignaledForWithdrawal =
              getTotalLiquidityThatCanBeSignaledForWithdrawal({
                activeLiquidity: state.positions[poolId].activeLiquidity.amount,
                pendingWithdrawal: state.positions[poolId].pendingWithdrawal.amount,
              });

            state.positions[poolId].totalLiquidityThatCanBeSignaledForWithdrawal = {
              amount: totalLiquidityThatCanBeSignaledForWithdrawal,
              formattedAmount: totalLiquidityThatCanBeSignaledForWithdrawal
                ? formatUnits(
                    totalLiquidityThatCanBeSignaledForWithdrawal,
                    state.positions[poolId].poolDetails.debtToken.decimals
                  )
                : undefined,
            };
          },
          false,
          "updatePendingWithdrawal"
        );
      },
      updateAvailableWithdrawal: ({ poolId, ...rest }) => {
        set(
          (state) => {
            state.positions[poolId].availableWithdrawal = {
              ...rest,
              formattedAmount:
                rest.amount && state.positions[poolId].poolDetails.debtToken.decimals
                  ? formatUnits(rest.amount, state.positions[poolId].poolDetails.debtToken.decimals)
                  : undefined,
            };
          },
          false,
          "updateAvailableWithdrawal"
        );
      },
      updateAdjustLPDeposit: ({ poolId, activeLiquidity, feesEarned, pendingCollateral }) => {
        set(
          (state) => {
            state.positions[poolId].activeLiquidity = {
              ...activeLiquidity,
              formattedAmount:
                activeLiquidity.amount && state.positions[poolId].poolDetails.debtToken.decimals
                  ? formatUnits(
                      activeLiquidity.amount,
                      state.positions[poolId].poolDetails.debtToken.decimals
                    )
                  : undefined,
            };
            state.positions[poolId].feesEarned = {
              ...feesEarned,
              formattedAmount:
                feesEarned.amount && state.positions[poolId].poolDetails.debtToken.decimals
                  ? formatUnits(
                      feesEarned.amount,
                      state.positions[poolId].poolDetails.debtToken.decimals
                    )
                  : undefined,
            };
            state.positions[poolId].pendingCollateral = {
              ...pendingCollateral,
              formattedAmount:
                pendingCollateral.amount &&
                state.positions[poolId].poolDetails.collateralToken.decimals
                  ? formatUnits(
                      pendingCollateral.amount,
                      state.positions[poolId].poolDetails.collateralToken.decimals
                    )
                  : undefined,
            };

            const totalLiquidityThatCanBeSignaledForWithdrawal =
              getTotalLiquidityThatCanBeSignaledForWithdrawal({
                activeLiquidity: activeLiquidity.amount,
                pendingWithdrawal: state.positions[poolId].pendingWithdrawal.amount,
              });

            state.positions[poolId].totalLiquidityThatCanBeSignaledForWithdrawal = {
              amount: totalLiquidityThatCanBeSignaledForWithdrawal,
              formattedAmount: totalLiquidityThatCanBeSignaledForWithdrawal
                ? formatUnits(
                    totalLiquidityThatCanBeSignaledForWithdrawal,
                    state.positions[poolId].poolDetails.debtToken.decimals
                  )
                : undefined,
            };
          },
          false,
          "updateAdjustLPDeposit"
        );
      },
      updatePrices: ({ poolId, debtTokenPrice, collateralTokenPrice }) => {
        set(
          (state) => {
            state.positions[poolId].poolDetails = {
              ...state.positions[poolId].poolDetails,
              debtToken: {
                ...state.positions[poolId].poolDetails.debtToken,
                price: debtTokenPrice,
                priceInUSD: debtTokenPrice
                  ? formatUnits(
                      debtTokenPrice,
                      state.positions[poolId].poolDetails.debtToken.decimals
                    )
                  : undefined,
              },
              collateralToken: {
                ...state.positions[poolId].poolDetails.collateralToken,
                price: collateralTokenPrice,
                priceInUSD: collateralTokenPrice
                  ? formatUnits(
                      collateralTokenPrice,
                      state.positions[poolId].poolDetails.collateralToken.decimals
                    )
                  : undefined,
              },
            };
          },
          false,
          "updatePrices"
        );
      },
    })),
    { name: "LPPositionStore" }
  )
);
