import React from "react";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { ActionButton, BaseFlex, Hint, SpinnerTwo, InputSliderPair } from "@components/common";
import { Detail, DetailsWrapper, TransactionCardBaseProps } from "../TransactionCardBase";
import { commaSeparateNumbers, hexToRgba, truncateDecimal } from "@lib/utils";
import { ControlledPeriodSelection } from "@components/ui";
import BorrowingFeeTooltip from "./BorrowingFeeTooltip";
import { TokenI } from "@lib/types";

export interface BorrowTransactionCardProps extends Pick<TransactionCardBaseProps, "fullScreen"> {
  collateralBalance: string;
  collateralInputName: string;
  collateralSliderName: string;
  borrowInputName: string;
  borrowSliderName: string;
  periodSelectorName: string;
  /**true when at least one data fetch is still loading */
  loading: boolean;
  /** the conversion between the collateral and the debt tokens ie. between cWETH and cUSDC */
  collateralDebtTokenConversion: number;
  collateralToken: TokenI & {
    /** the price of collateral token in USD */
    price: string | undefined;
    logo: string;
  };
  debtToken: TokenI & {
    /** the price of debt token in USD */
    price: string | undefined;
    logo: string;
  };
  poolLiquidity: number;
  periods: Array<number> | undefined;
  borrowingFee: number | undefined;
  minAmountToBorrow: number;
  nextRoundTimeStamp: number;
  nextRoundTimestampReadable: string;
  approveButtonIsDisabled: boolean;
  approveButtonOnClick: () => void;
  isApproving: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  isValid: boolean;
  isUpdatingBorrowingFee: boolean;
}

const Borrow = ({
  collateralBalance,
  collateralInputName,
  collateralSliderName,
  borrowInputName,
  borrowSliderName,
  periodSelectorName,
  loading,
  collateralDebtTokenConversion,
  collateralToken,
  debtToken,
  poolLiquidity,
  periods,
  borrowingFee,
  minAmountToBorrow,
  nextRoundTimeStamp,
  nextRoundTimestampReadable,
  isUpdatingBorrowingFee,
  approveButtonIsDisabled,
  approveButtonOnClick,
  isApproving,
  isSubmitting,
  onSubmit,
  isValid,
  fullScreen = false,
}: BorrowTransactionCardProps): JSX.Element => {
  const { watch, handleSubmit, formState } = useFormContext();
  const { isValid: formStateIsValid } = formState;

  const collateralValue = watch(collateralInputName);
  const borrowValue = watch(borrowInputName);

  // -----------------
  // TODO: FIX POST TESTNET -
  // for now the borrow limit is fixed at 6 decimals
  const borrowLimit = (
    parseFloat(collateralValue || "0") *
    collateralDebtTokenConversion *
    0.96
  ).toFixed(6);
  // -----------------

  const allowedToBorrowInTermsOfBorrowingFee =
    commaSeparateNumbers(truncateDecimal(borrowingFee ? borrowingFee?.toString() : "0")) !== "0.00"; //TODO: the contracts is having a minimum amount to loan

  return (
    <BaseFlex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        padding: "1em 2em",
        flexDirection: "column",
        gap: "2em",
        color: (theme) => theme.palette.common.white,
        width: "28.75rem",
        maxWidth: "100%",
      }}
    >
      <BaseFlex sx={{ flexDirection: "column", width: "100%", marginTop: "0.5em" }}>
        <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
          <Typography
            variant="headline2"
            component="span"
            sx={{
              color: (theme) => theme.palette.common.white,
              whiteSpace: "pre-wrap",
            }}
          >
            Collateral
          </Typography>
          <BaseFlex
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "0",
            }}
          >
            <Typography
              variant="caption2"
              component="span"
              sx={{ color: (theme) => theme.palette.primary[300] }}
            >
              Wallet Balance:
            </Typography>
            <Typography
              variant="caption1"
              component="span"
              data-test="walletBalance"
              aria-label="borrowLimit"
              sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
            >
              {loading || !collateralBalance
                ? "-"
                : `${commaSeparateNumbers(truncateDecimal(collateralBalance, 4), 4)} ${
                    collateralToken.symbol
                  }`}
            </Typography>
          </BaseFlex>
        </BaseFlex>

        <InputSliderPair
          inputName={collateralInputName}
          sliderName={collateralSliderName}
          logo={collateralToken.logo}
          max={collateralBalance}
          conversionRate={collateralToken.price}
          scheme="secondary"
          disabled={loading}
        />
      </BaseFlex>

      <BaseFlex sx={{ flexDirection: "column", width: "100%", marginTop: "0.5em" }}>
        <BaseFlex sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "nowrap" }}>
          <Typography
            variant="headline2"
            component="span"
            sx={{
              color: (theme) => theme.palette.common.white,
              whiteSpace: "pre-wrap",
            }}
          >
            Borrow Amount
          </Typography>
          <BaseFlex
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "0",
            }}
          >
            <Typography
              variant="caption2"
              component="span"
              sx={{ color: (theme) => theme.palette.primary[300] }}
            >
              Borrow Limit:
            </Typography>

            <Typography
              variant="caption1"
              data-test="borrowLimit"
              component="span"
              sx={{ color: (theme) => theme.palette.common.white, marginLeft: "0.5em" }}
            >
              {loading
                ? "-"
                : `${commaSeparateNumbers(truncateDecimal(borrowLimit))} ${debtToken.symbol}`}
            </Typography>
          </BaseFlex>
        </BaseFlex>

        <InputSliderPair
          inputName={borrowInputName}
          sliderName={borrowSliderName}
          logo={debtToken.logo}
          max={borrowLimit}
          variableMax
          conversionRate={debtToken.price}
          disabled={loading}
          scheme="secondary"
          moreValidationRules={{
            min: (value: number) => {
              if (value < minAmountToBorrow) {
                return `Minimum borrow amount: ${minAmountToBorrow} ${debtToken.symbol}.`;
              }
            },
            max: (value: number) => {
              if (value > parseFloat(borrowLimit)) {
                return "Borrow limit is exceeded.";
              }
            },
            notToExceedLiquidity: (value: string) => {
              if (parseFloat(value) > poolLiquidity) {
                return `Exceeds the pool liquidity: ${commaSeparateNumbers(
                  truncateDecimal(poolLiquidity.toString())
                )} ${debtToken.symbol}`;
              }
            },
          }}
        />
      </BaseFlex>

      <BaseFlex
        sx={{
          flexDirection: "column",
          width: "100%",
          marginTop: "0.5em",
          alignItems: "flex-start",
        }}
      >
        <BaseFlex sx={{ gap: "1em" }}>
          <Typography
            variant="headline2"
            component="span"
            sx={{
              color: (theme) => theme.palette.common.white,
            }}
          >
            Period
          </Typography>
          <Hint title="The duration of your loan." />
        </BaseFlex>

        <ControlledPeriodSelection
          periodSelectorName={periodSelectorName}
          periods={periods}
          nextRoundTimeStamp={nextRoundTimeStamp}
          nextRoundTimestampReadable={nextRoundTimestampReadable}
        />
      </BaseFlex>

      <BaseFlex
        sx={{
          width: "100%",
          flexDirection: "column",
          gap: "1em",
          marginTop: fullScreen ? "-1em" : 0,
        }}
      >
        <Typography
          variant="headline2"
          sx={{
            color: (theme) => theme.palette.common.white,
            marginBottom: "0.5rem",
            marginRight: "auto",
          }}
        >
          Summary
        </Typography>

        <DetailsWrapper>
          <Detail
            label="Borrow Amount"
            detail={`${commaSeparateNumbers(truncateDecimal(borrowValue))} ${debtToken.symbol}`}
            subDetail={
              debtToken.price
                ? `≈ ${commaSeparateNumbers(
                    truncateDecimal(
                      (isNaN(parseFloat(borrowValue))
                        ? 0
                        : parseFloat(borrowValue) * parseFloat(debtToken.price)
                      ).toString()
                    )
                  )} USD`
                : ""
            }
            loading={loading}
          />
          <Detail
            label={
              <BaseFlex sx={{ gap: "1.3em" }}>
                <Box component="span" sx={{ display: "flex", gap: "1em" }}>
                  Borrowing Fee
                  <Hint title="One time payment fee that you need to pay in order to recover your collateral." />
                </Box>
              </BaseFlex>
            }
            detail={
              isUpdatingBorrowingFee ? (
                <Skeleton
                  variant="text"
                  sx={{
                    backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.3),
                    width: "6em",
                  }}
                />
              ) : (
                `${commaSeparateNumbers(
                  truncateDecimal(borrowingFee ? borrowingFee.toString() : "0")
                )} ${debtToken.symbol}`
              )
            }
            subDetail={
              <BorrowingFeeTooltip
                open={
                  borrowingFee !== undefined &&
                  !allowedToBorrowInTermsOfBorrowingFee &&
                  parseFloat(collateralValue) > 0 &&
                  parseFloat(borrowValue) > 0
                }
              >
                <span>
                  {isUpdatingBorrowingFee ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.3),
                        width: "4em",
                        marginLeft: "auto",
                      }}
                    />
                  ) : (
                    `≈ ${commaSeparateNumbers(
                      truncateDecimal(
                        borrowingFee && debtToken.price
                          ? (borrowingFee * parseFloat(debtToken.price)).toString()
                          : "0"
                      )
                    )} USD`
                  )}
                </span>
              </BorrowingFeeTooltip>
            }
            loading={loading}
          />
          <Detail
            label="You will get"
            detail={
              <span aria-label="netBorrow">
                {borrowingFee && borrowValue
                  ? `${commaSeparateNumbers(
                      truncateDecimal((parseFloat(borrowValue) - borrowingFee).toString())
                    )}  ${debtToken.symbol}`
                  : `0.00 ${debtToken.symbol}`}
              </span>
            }
            subDetail={
              <span>
                ≈{" "}
                {borrowingFee && borrowValue && debtToken.price
                  ? `${commaSeparateNumbers(
                      truncateDecimal(
                        (
                          (parseFloat(borrowValue) - borrowingFee) *
                          parseFloat(debtToken.price)
                        ).toString()
                      )
                    )} USD`
                  : `0.00 USD`}
              </span>
            }
            loading={loading}
          />
        </DetailsWrapper>
      </BaseFlex>

      <BaseFlex sx={{ width: "100%", flexDirection: "column" }}>
        <BaseFlex sx={{ width: "100%" }}>
          <BaseFlex sx={{ flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
            <ActionButton
              data-test="approve-button"
              scheme="secondary"
              onClick={approveButtonOnClick}
              disabled={isApproving || approveButtonIsDisabled || loading}
              pendingAction={isApproving}
            >
              {isApproving && <SpinnerTwo />}
              {isApproving ? "Approving" : "Approve"}
            </ActionButton>
          </BaseFlex>

          <ActionButton
            data-test="borrow-button"
            scheme="secondary"
            disabled={
              !isValid ||
              isSubmitting ||
              isUpdatingBorrowingFee ||
              !allowedToBorrowInTermsOfBorrowingFee ||
              loading ||
              !formStateIsValid
            }
            pendingAction={isSubmitting}
            type="submit"
            fullWidth
          >
            {isSubmitting ? "Borrowing" : "Borrow"}
          </ActionButton>
        </BaseFlex>

        {!approveButtonIsDisabled && !loading && (
          <BaseFlex sx={{ fontSize: "13px", width: "100%", justifyContent: "left" }}>
            Allow the Cora Protocol to use your token on your behalf.
          </BaseFlex>
        )}
      </BaseFlex>
    </BaseFlex>
  );
};

export default Borrow;
