import { ChainId } from "@lib/constants";
import { addNetwork } from "@lib/utils";

interface ChangeNetworkArgs {
  chainId: ChainId;
}

const switchNetworkHandler = async ({ chainId }: ChangeNetworkArgs): Promise<void> => {
  // throw when metamask is not installed
  if (!window?.ethereum) {
    throw { message: "Could not find a wallet to connect to. Please download metamask." };
  }

  const chainIdHex = "0x" + chainId.toString(16);

  try {
    await window?.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainIdHex }],
    });
  } catch (err) {
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    // This error code indicates that the chain has not been added to MetaMask.
    if ((err as any).code === 4902 || (err as any).data?.originalError.code === 4902) {
      return await addNetwork({ chainId });
    } else {
      throw err;
    }
  }
};

export default switchNetworkHandler;
