import { BigNumber } from "ethers";
import { TokenI } from "./currency";

export interface PoolI extends PoolMetaI {
  id: string;
  name: string;
  collateralToken: TokenI & { price?: BigNumber; priceInUSD?: string };
  debtToken: TokenI & { price?: BigNumber; priceInUSD?: string };
  maxCap: number;
  priceFeed: string;
  ltv: number;
  roundDuration: number;
  genesisRoundDuration: number;
  createdAtTimestamp: number;

  utilization: number | undefined;
  apy: number;
  availableLiquidity: number;
  minAmountToBorrow: number;
  minAmountToDeposit: number;
  currentRoundIndex: number;

  nextRoundTimestampRelative: string;
  nextRoundTimestampRelativeWithoutSuffix: string;
  nextRoundTimestamp: number;
  roundDurationInDays: string;
  // allowedLoanTerms: Array<number>; // TODO: transferring to PoolMetaI
}

export enum PoolType {
  MAJOR = "Major",
  STABLE = "Stable",
}

export interface PoolMetaI {
  // token type won't be used on the MVP
  collateralTokenLogoUrl?: string;
  stableCoinTokenlogoUrl?: string;
  chainId?: number;
  strategyDetails?: string;
  // the following should be from onchain data
  withdrawalFee?: number;
  borrowingFee?: number | string;
  allowedLoanTerms?: Array<number>;
}
