import { useCallback, useEffect, useMemo } from "react";
import { useBlockNumber } from "@lib/providers";
import { useMultiCall, useWeb3 } from "@lib/hooks";

import LendingPoolAbi from "@coraprotocol/core/abis/LendingPool.json";
import { BigNumber } from "ethers";

interface UseUserLoansLengthReturn {
  data: Record<string, number>;
  loading: boolean;
  error?: boolean;
}

const callData = (pools: Array<string>, account: string) => {
  return pools.map((poolAddress) => ({
    target: poolAddress,
    abi: LendingPoolAbi,
    reference: poolAddress,
    method: "userLoansLength",
    args: [account],
  }));
};

const useUserLoansLength = (pools: Array<string>): UseUserLoansLengthReturn => {
  const { account } = useWeb3();

  const { data, call, loading, error } = useMultiCall<Record<string, BigNumber>>();
  const blockNumber = useBlockNumber();

  const getUserLoansLength = useCallback(
    (pools: Array<string>, account: string) => {
      call(callData(pools, account));
    },
    [pools]
  );

  useEffect(() => {
    if (!account) return;
    if (!pools) return;
    if (pools.length === 0) return;

    getUserLoansLength(pools, account);
  }, [blockNumber, account, JSON.stringify(pools)]);

  return useMemo(() => {
    const parsedData = Object.keys(data).reduce<Record<string, number>>((acc, key) => {
      acc[key] = data[key] ? parseInt(data[key].toString()) : 0;
      return acc;
    }, {});

    return {
      data: parsedData,
      loading,
      error,
    };
  }, [JSON.stringify(data), loading, error]);
};

export default useUserLoansLength;
