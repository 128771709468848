import React, { Fragment } from "react";
import { styled } from "@mui/system";
import Image from "next/image";
import { useToggle } from "@lib/providers";
import { BaseFlex } from "@components/common";
import { CellData, LogoWrapper } from "../TableComponents";
import { abbrNumber, commaSeparateNumbers, decimalToPercentage, hexToRgba } from "@lib/utils";
import { CollateralParams } from ".";
import { BorrowModal } from "@components/ui";
import { useERCBalance } from "@lib/hooks";
import BorrowActionButton from "./BorrowActionButton";

export const LineGrid = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "0.8fr 1fr 1fr 1fr 0.6fr 1fr 0.7fr",
  gridTemplateAreas: `'collateral stablecoin borrowingFee liquidity ltv wallet action'`,
  padding: "1.54rem 0",
});

export const Message = ({ message }: { message: string }): JSX.Element => {
  return (
    <LineGrid
      sx={{
        display: "flex",
        justifyContent: {
          xs: "flex-start",
          sm: "center",
        },
        color: (theme) => hexToRgba(theme.palette.common.white, 0.6),
      }}
    >
      {message}
    </LineGrid>
  );
};

interface BorrowableItemProps {
  index: number;
  collateral: CollateralParams;
  connected?: boolean;
}

export const BorrowableItem = ({
  index,
  collateral,
  connected = false,
}: BorrowableItemProps): JSX.Element => {
  const {
    id,
    collateralName,
    stablecoinName,
    borrowingFee, //@todo harcoded for now
    availableLiquidity,
    ltv,
    collateralTokenLogoUrl,
    stableCoinTokenlogoUrl,
    collateralToken,
    debtToken,
    nextRoundTimestamp,
    nextRoundTimestampRelativeWithoutSuffix,
  } = collateral;
  const { open: showModal, setOpen: setShowModal } = useToggle();

  const { balance, loading } = useERCBalance({
    tokenAddress: collateralToken.id,
    decimals: collateralToken.decimals,
  });

  return (
    <Fragment>
      <LineGrid data-test={`borrowItem-${index}`} className="lineGrid">
        <CellData
          data-test="collateral"
          sx={{ gridArea: "collateral", justifyContent: "start", fontWeight: 600 }}
        >
          <BaseFlex>
            <LogoWrapper>
              {/* @ todo */}
              {collateralTokenLogoUrl ? (
                <Image src={collateralTokenLogoUrl} height={18} width={18} />
              ) : (
                <Image src="/assets/tokenImages/Ethereum-ETH-icon.png" height={18} width={18} />
              )}
            </LogoWrapper>
            {collateralName}
          </BaseFlex>
        </CellData>
        <CellData data-test="stablecoin" sx={{ gridArea: "stablecoin" }}>
          <BaseFlex>
            <LogoWrapper>
              {/* @ todo */}
              {stableCoinTokenlogoUrl ? (
                <Image src={stableCoinTokenlogoUrl} height={18} width={18} />
              ) : (
                <Image src="/assets/tokenImages/usd-coin-usdc-logo.png" height={18} width={18} />
              )}
            </LogoWrapper>
            {stablecoinName}
          </BaseFlex>
        </CellData>
        <CellData data-test="borrowingFee" sx={{ gridArea: "borrowingFee" }}>
          {borrowingFee}
        </CellData>
        <CellData data-test="liquidity" sx={{ gridArea: "liquidity" }}>
          {abbrNumber(availableLiquidity ?? 0)} {debtToken.symbol}
        </CellData>
        <CellData data-test="ltv" sx={{ gridArea: "ltv" }}>
          {decimalToPercentage(ltv)}
        </CellData>
        <CellData data-test="wallet" sx={{ gridArea: "wallet", textAlign: "end" }}>
          {!loading && balance?.formatted
            ? `${commaSeparateNumbers(balance.formatted)} ${collateralToken.symbol}`
            : "-"}
        </CellData>

        <CellData data-test="action" sx={{ gridArea: "action", justifyContent: "flex-end" }}>
          <BorrowActionButton
            handleClick={() => setShowModal(true)}
            isConnected={connected}
            leastLoanTerm={86400} // TODO: get from contract
            nextRoundTimestamp={nextRoundTimestamp}
            nextRoundTimestampReadable={nextRoundTimestampRelativeWithoutSuffix}
          />
        </CellData>
      </LineGrid>

      <BorrowModal open={showModal} onClose={() => setShowModal(false)} poolAddress={id} />
    </Fragment>
  );
};
