import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const PlusCircleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={20.2} cy={20.6} r={19.8} fill="currentColor" />
      <path
        d="M20.2 17.9v2.7m0 0v2.7m0-2.7h2.7m-2.7 0h-2.7m10.8 0a8.1 8.1 0 11-16.2 0 8.1 8.1 0 0116.2 0z"
        stroke="rgb(68, 195, 129)"
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default PlusCircleIcon;
