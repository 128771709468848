import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import decimalToPercentage from "../toPercentage";

export const getRewardsPercentage = (
  rewards?: BigNumber,
  activeLiquidity?: BigNumber,
  decimals = 18
): string | undefined => {
  if (rewards && activeLiquidity && !activeLiquidity.isZero()) {
    const rewardsPerc =
      parseFloat(formatUnits(rewards, decimals)) /
      parseFloat(formatUnits(activeLiquidity, decimals));

    return decimalToPercentage(rewardsPerc);
  }
  return undefined;
};
