import React from "react";
import ToggleButton, { toggleButtonClasses, ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { hexToRgba } from "@lib/utils";

export const PeriodTogglerGroup = styled(ToggleButtonGroup)({
  width: "100%",
  borderRadius: "5px",
  overflow: "clip",
  gap: "2px",

  button: {
    borderRadius: "0px",
  },

  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: "none",

    ":first-of-type": {
      borderRadius: "5px 0px 0px 5px",
    },
  },
  "& span.not-allowed-term": {
    width: "100%",

    "& button": {
      borderRadius: "0px",
      pointerEvents: "none",
    },
  },

  "& span.not-allowed-term:last-child > button": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
});

export const PeriodTogglerButton = styled((props: ToggleButtonProps & { error?: boolean }) => (
  <ToggleButton disableRipple {...props} />
))(({ theme, error }) => ({
  ...theme.typography.headline2,
  color: theme.palette.primary[300],
  width: "100%",

  [`&.${toggleButtonClasses.root}`]: {
    transition: "background-color 0.2s ease-in-out, border-color 0.2s ease-in-out",
    backgroundColor: error ? "rgba(255, 57, 114, 0.2)" : hexToRgba(theme.palette.common.white, 0.1),
  },

  "&:hover": {
    color: theme.palette.dark.main,
    backgroundColor: theme.palette.secondary.main,
  },

  [`&.${toggleButtonClasses.selected}`]: {
    color: theme.palette.dark.main,
    backgroundColor: theme.palette.secondary.main,

    "&:hover": {
      color: theme.palette.dark.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  [`&.${toggleButtonClasses.disabled}`]: {
    color: hexToRgba(theme.palette.common.white, 0.1),
    cursor: "not-allowed",
    pointerEvents: "auto",

    "&:hover": {
      backgroundColor: theme.palette.dark.main,
    },
  },
}));

export const PeriodToggleLoader = (): JSX.Element => {
  return (
    <PeriodTogglerGroup>
      {[...new Array(3)].map((_, index) => {
        return (
          <PeriodTogglerButton key={index} value={index} disabled>
            <Skeleton
              variant="text"
              width="60%"
              sx={{
                backgroundColor: (theme) => hexToRgba(theme.palette.common.white, 0.3),
                width: "100%",
              }}
            />
          </PeriodTogglerButton>
        );
      })}
    </PeriodTogglerGroup>
  );
};
