import React, { Fragment } from "react";
import { CellData, CellLoader } from "../TableComponents";
import { LineGrid } from "./PoolComponents";

const PoolLoader = (): JSX.Element => {
  return (
    <Fragment>
      {[...Array(2)].map((_, index) => (
        <LineGrid className="lineGrid" key={index}>
          <CellData sx={{ gridArea: "debtToken", justifyContent: "flex-start" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "collateralToken" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "totalDeposits" }}>
            <CellLoader variant="text" width="40%" />
          </CellData>

          <CellData sx={{ gridArea: "apy" }}>
            <CellLoader variant="text" width="50%" />
          </CellData>

          <CellData sx={{ gridArea: "utilization" }}>
            <CellLoader variant="text" width="30%" />
          </CellData>

          <CellData sx={{ gridArea: "maxCap" }}>
            <CellLoader variant="text" width="30%" />
          </CellData>

          <CellData sx={{ gridArea: "action", justifyContent: "flex-end" }}>
            <CellLoader variant="text" width="60%" />
          </CellData>
        </LineGrid>
      ))}
    </Fragment>
  );
};

export default PoolLoader;
