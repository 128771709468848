import React, { useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { BaseFlex, DownCaret } from "@components/common";
import { FilterMenu, FilterItem } from "./SearchFilterComponents";
import { PoolType } from "@lib/types";
import { Typography } from "@mui/material";

export interface FilterProps {
  filterOptions: Array<any>;
  activeFilter: any;
  setFilter: (val: any) => void;
}

const Filter = ({ filterOptions, activeFilter, setFilter }: FilterProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    option: PoolType | string
  ) => {
    setFilter(option);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseFlex>
      <ButtonBase
        onClick={handleMenuClick}
        sx={{
          width: {
            xs: "100%",
            lg: "14.625rem",
          },
          padding: "0.75rem 1rem",
          border: (theme) => `1px solid ${theme.palette.primary[400]}`,
          borderRadius: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">{activeFilter}</Typography>
        <DownCaret
          sx={{
            color: (theme) => theme.palette.common.white,
          }}
        />
      </ButtonBase>

      <FilterMenu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {filterOptions.map((option, index) => {
          return (
            <FilterItem
              key={index}
              onClick={(event) => handleMenuItemClick(event, option)}
              active={activeFilter === option}
            >
              {option}
            </FilterItem>
          );
        })}
      </FilterMenu>
    </BaseFlex>
  );
};

export default Filter;
