import React, { useState } from "react";
import { PoolTable, TABLE_LOADER_MIN_DURATION } from "@components/common/Table";
import { useDebounce, usePools, useWeb3 } from "@lib/hooks";
import { PoolColumns, SortDirectionAsc } from "@components/common/Table";

const Pool = (): JSX.Element => {
  const { active } = useWeb3();
  const { lendingPools, error, loading } = usePools();

  const debouncedLoading = useDebounce(loading, TABLE_LOADER_MIN_DURATION);

  const [sortedColumn, setSortedColumn] = useState<PoolColumns>(PoolColumns.DEBTTOKEN);
  const [sortDirection, setSortDirection] = useState<SortDirectionAsc | undefined>(undefined);

  // const [pools, setPools] = useState<Array<PoolParams>>([]);

  // useEffect(() => {
  //   if (!lendingPools) {
  //     return;
  //   }

  //   // copy the list before sorting so there will be no mutation in the state
  //   const list = [...lendingPools].sort((a, b) => {
  //     if (sortedColumn === PoolColumns.COLLATERALTOKEN || sortedColumn === PoolColumns.DEBTTOKEN) {
  //       return a[sortedColumn].name > b[sortedColumn].name
  //         ? (sortDirection ? -1 : 1) * -1
  //         : sortDirection
  //         ? -1
  //         : 1;
  //     } else {
  //       // TODO: fix sorting when the protocol has multiple pools
  //       return a[sortedColumn] > b[sortedColumn]
  //         ? (sortDirection ? -1 : 1) * -1
  //         : sortDirection
  //         ? -1
  //         : 1;
  //     }
  //   });

  //   setPools(list);
  // }, [lendingPools, sortedColumn, sortDirection]);

  return (
    <PoolTable
      loading={debouncedLoading}
      error={!!error}
      poolDataList={lendingPools}
      onDebtTokenClick={() => {
        setSortedColumn(PoolColumns.DEBTTOKEN);
        setSortDirection(sortedColumn !== PoolColumns.DEBTTOKEN ? true : !sortDirection);
      }}
      onCollateralTokenClick={() => {
        setSortedColumn(PoolColumns.COLLATERALTOKEN);
        setSortDirection(sortedColumn !== PoolColumns.COLLATERALTOKEN ? true : !sortDirection);
      }}
      onAPYClick={() => {
        setSortedColumn(PoolColumns.APY);
        setSortDirection(sortedColumn !== PoolColumns.APY ? true : !sortDirection);
      }}
      onUtilizationClick={() => {
        setSortedColumn(PoolColumns.UTILIZATION);
        setSortDirection(sortedColumn !== PoolColumns.UTILIZATION ? true : !sortDirection);
      }}
      onMaxCapClick={() => {
        setSortedColumn(PoolColumns.MAXCAP);
        setSortDirection(sortedColumn !== PoolColumns.MAXCAP ? true : !sortDirection);
      }}
      onPoolSizeClick={() => {
        setSortedColumn(PoolColumns.TOTALDEPOSIT);
        setSortDirection(sortedColumn !== PoolColumns.TOTALDEPOSIT ? true : !sortDirection);
      }}
      sortedColumn={sortedColumn}
      sortDirection={sortDirection}
      connected={active}
    />
  );
};

export default Pool;
